import React from 'react';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import { crmUrls, nodeDriveUrls } from '@config/routes';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { personHistoryRowsMap } from '@config/handleRows/crm';
import { PersonHistoryForm } from '@config/forms/crm';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { filesUrls } = nodeDriveUrls;
const { personHistoryUrls } = crmUrls;

export default function PersonNotesAccordion({ editMode, labels, recordId }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [notesCount, setNotesCount] = React.useState(1);

  return (
    <DetailAccordionView
      microservice={microservices.CRM.name}
      model={microservices.CRM.models.personHistory}
      columnKey="personHistoryCRM"
      label="Notes"
      editMode={editMode}
      labels={labels}
      prefix="0d0"
      autoHeight={`${notesCount * 36 + 130}px`}
      getListData={(data) => {
        if (Array.isArray(data?.results)) {
          setNotesCount(data?.results?.length);
        }
      }}
      urls={personHistoryUrls}
      addNewForm={{
        getFields: ({ values, errors }) =>
          PersonHistoryForm({ values, errors }),
        handleSubmit: async (
          values,
          setSubmitting,
          resetForm,
          setAddNewForm,
          refetch,
          setError
        ) => {
          try {
            let file = values.history;
            if (file) {
              const formData = new FormData();
              formData.append('anonymous_can_see_it', true);
              formData.append('file', file);

              const { data: fileData } = await createRecord({
                values: formData,
                url: filesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              file = fileData.fileUrl;
            }
            const response = await createRecord({
              values: {
                history: file,
                person: recordId,
                notes: values.notes,
              },
              url: personHistoryUrls.list(),
              token: user.token,
              actAs: user?.actAs,
            });

            resetForm();
            setAddNewForm(false);
            refetch();
            return response?.data?.id;
          } catch (err) {
            handleCreateFormErrors({ err, setError, notify, values });
          } finally {
            setSubmitting(false);
          }
        },
      }}
      handleRowMap={personHistoryRowsMap}
      urlParams={`&person=${recordId}`}
    />
  );
}
