import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { truncateContent } from '@config/functions/chatFunctions';
import { nodeDriveHost } from '@config/msHosts';
import Lightbox from 'react-awesome-lightbox';

const IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg'];

export default function ChatFileDisplayBox({
  fileName,
  fileSize,
  padding,
  transparentBackground,
  fileExtension,
  fileId,
  onDeselectFile,
  disableLightBox,
}) {
  const [showImage, setShowImage] = React.useState(false);

  const isImage = IMAGE_EXTENSIONS.includes(fileExtension);
  const fileUrl = `${nodeDriveHost}/file/${fileId}${fileExtension}`;

  React.useEffect(() => {
    const handleButtonClick = () => {
      setShowImage(false);
    };

    const closeButton = document.querySelector(
      '.lb-button.lb-icon-close.close'
    );

    if (closeButton) {
      closeButton.addEventListener('click', handleButtonClick);
    }

    return () => {
      if (closeButton) {
        closeButton.removeEventListener('click', handleButtonClick);
      }
    };
  }, [showImage]);

  return (
    <Box
      sx={{
        width: '100%',
        background: transparentBackground ? 'transparent' : '#f0f2f5',
        padding: isImage ? 0 : padding ? '10px 8px' : '10px 24px',
        borderRadius: '6px',
        cursor: 'pointer',
        overflow: 'hidden',
      }}
    >
      {isImage ? (
        <Box
          sx={{
            width: { xs: '239px', sm: '339px' },
            height: { xs: '239px', sm: '339px' },
            overflow: 'hidden',
          }}
        >
          <img
            src={fileUrl}
            alt={fileName}
            onClick={() => setShowImage(true)}
            style={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              objectFit: 'cover',
            }}
          />

          {showImage && !disableLightBox ? (
            <Lightbox image={fileUrl} title={fileName} />
          ) : null}
        </Box>
      ) : (
        <Stack direction='row' alignItems='center' spacing={1}>
          <FileDetails fileName={fileName} fileSize={fileSize} />
          {onDeselectFile && <ClearButton onDeselectFile={onDeselectFile} />}
        </Stack>
      )}
    </Box>
  );
}

function FileDetails({ fileName, fileSize }) {
  return (
    <>
      <InsertDriveFileIcon sx={{ color: '#555', fontSize: '46px' }} />
      <Stack>
        <Typography sx={{ userSelect: 'none', fontSize: '14px' }}>
          {truncateContent(fileName, 44)}
        </Typography>
        <Typography sx={{ userSelect: 'none', fontSize: '14px' }}>
          {(fileSize / (1024 * 1024)).toFixed(2)} MB
        </Typography>
      </Stack>
    </>
  );
}

function ClearButton({ onDeselectFile }) {
  return (
    <IconButton aria-label='clear' onClick={onDeselectFile} size='small'>
      <ClearIcon fontSize='small' />
    </IconButton>
  );
}
