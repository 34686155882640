import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const getNotificationAudio = () => {
  return typeof Audio !== 'undefined' ? new Audio('/notification.ogg') : null;
};

export const truncateContent = (text, maxLength = 150) => {
  return text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const showNotification = (title, options) => {
  if (Notification.permission === 'granted') {
    try {
      new Notification(title, options);
    } catch (error) {
      console.error('Failed to show notification:', error);
    }
  }
};

export const requestNotificationPermission = async () => {
  if (!('Notification' in window)) {
    console.error('This browser does not support desktop notification');
    return;
  }

  if (Notification.permission === 'granted') {
    return true;
  }

  if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  }

  return false;
};

export const ensureSocketConnected = (socket) => {
  if (!socket?.connected) {
    socket.connect();
  }
};

/**
 * Joins a chat room using its ID.
 * @param {string} chatRoomId ID of the chat room to join
 */
export const joinChatRoom = (socket, chatRoomId) => {
  console.log('Joining chat room:', chatRoomId);
  ensureSocketConnected(socket);
  socket.emit('joinRoom', { roomId: chatRoomId });
};

export const getPersonNameAndInitials = (person) => {
  const isValidString = (value) =>
    typeof value === 'string' && value.trim() !== '';

  const first_name = isValidString(person?.first_name) ? person.first_name : '';
  const last_name = isValidString(person?.last_name) ? person.last_name : '';

  const fullName = `${first_name} ${last_name}`.trim();
  const initials = `${first_name.charAt(0)}${last_name.charAt(0)}`.trim();

  return { fullName, initials };
};

/**
 * Formats a UTC datetime string for display in a chat application.
 *
 * @param {string} utcDateString - The UTC datetime string to format.
 * @return {string} - The formatted date string.
 */
export const formatChatMessageDate = (utcDateString, long = false) => {
  const messageTime = moment(utcDateString);
  const now = moment();
  let formatString;

  // Determine the appropriate format based on how old the message is
  if (messageTime.isSame(now, 'day')) {
    formatString = 'h:mm a'; // Time only for same day
  } else {
    formatString = `DD MMM ${long ? 'h:mm a' : ''}`; // Full date for different year
  }

  // Convert UTC to local time and format
  return messageTime.local().format(formatString);
};

export const getDefaultRooms = ({
  courseMentors,
  isUserCourseMentor,
  studentMentors,
  userPersonId,
}) => {
  if (isUserCourseMentor) {
    const mentorPersonIds = courseMentors.map((m) => m?.person?.id);

    return studentMentors
      .filter((sm) => {
        const mentorPersonId = sm?.mentor?.personId;
        /**
         * Exclude user from the students list if a mentor
         */
        const studentPersonId = sm?.student?.personId;
        const notInStudents = !mentorPersonIds.includes(studentPersonId);
        return mentorPersonId === userPersonId && notInStudents;
      })
      .map((sm) => {
        const { fullName, initials } = getPersonNameAndInitials(
          sm?.person ?? {}
        );
        const roomExists = !!sm?.chatRoomId;

        return {
          id: sm?.id,
          initials,
          name: fullName,
          personId: sm?.person?.id,
          chatRoomId: roomExists ? sm?.chatRoomId : uuidv4(),
          isPendingCreation: !roomExists,
          roomMessages: sm?.room?.roomMessages,
        };
      });
  }

  return courseMentors.map((mentor) => {
    const { fullName, initials } = getPersonNameAndInitials(
      mentor?.person ?? {}
    );

    const existingRoom = studentMentors.find((studentMentor) => {
      const isSameMentor = studentMentor?.mentorId === mentor?.mentorId;
      const isSameStudent = studentMentor?.person?.id === userPersonId;
      return isSameMentor && isSameStudent;
    });
    const roomExists = !!existingRoom?.chatRoomId;

    return {
      id: mentor?.mentorId,
      initials,
      name: fullName,
      personId: mentor?.person?.id,
      chatRoomId: roomExists ? existingRoom?.chatRoomId : uuidv4(),
      isPendingCreation: !roomExists,
      roomMessages: existingRoom?.room?.roomMessages,
    };
  });
};

export const sortMessages = (a, b) => {
  return new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime();
};

export function getFetcherConfig({ token, clientId }) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (clientId) {
    config.headers.ActAs = clientId;
  }

  return config;
}

export const detectLink = (text) => {
  // regex to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = text.match(urlRegex);

  if (!urls) {
    return text;
  }

  // wrap each URL with a Link component
  return text.split(urlRegex).map((part, index) => {
    if (urls.includes(part)) {
      return (
        <a
          key={index}
          style={{
            fontSize: '14.2px',
            lineHeight: '19px',
            color: '#111b21',
            textDecoration: 'underline',
          }}
          href={part}
          target='_blank'
          rel='noopener noreferrer'
        >
          {part}
        </a>
      );
    }

    return part;
  });
};

const getTime = (date) => new Date(date).getTime();

export const compareRoomsByLatestMessageOrCreationTime = (a, b) => {
  // Retrieve the latest messages from both rooms
  const lastMessageA = a?.roomMessages?.[0]?.createdAt;
  const lastMessageB = b?.roomMessages?.[0]?.createdAt;

  // Get the timestamps of the latest messages or use Infinity if there are no messages
  const aLatestMessageTime = lastMessageA ? getTime(lastMessageA) : Infinity;
  const bLatestMessageTime = lastMessageB ? getTime(lastMessageB) : Infinity;

  // Get the room creation times
  const aCreatedAtTime = getTime(a.createdAt);
  const bCreatedAtTime = getTime(b.createdAt);

  // Case 1: If both rooms have no messages, sort by the createdAt date of the rooms
  if (aLatestMessageTime === Infinity && bLatestMessageTime === Infinity) {
    return bCreatedAtTime - aCreatedAtTime;
  }

  // Case 2: If room 'a' has no messages
  if (aLatestMessageTime === Infinity) {
    // Compare room 'a' createdAt with the latest message time of room 'b'
    return aCreatedAtTime > bLatestMessageTime ? -1 : 1;
  }

  // Case 3: If room 'b' has no messages
  if (bLatestMessageTime === Infinity) {
    // Compare room 'b' createdAt with the latest message time of room 'a'
    return bCreatedAtTime > aLatestMessageTime ? 1 : -1;
  }

  // Case 4: If both rooms have messages, sort by the latest message's createdAt time
  return bLatestMessageTime - aLatestMessageTime;
};
