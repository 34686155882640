import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import ChatAccordion from '@components/Chat/ChatAccordion/ChatAccordion';
import ChatMessageBubble from '@components/Chat/ChatMessageBubble/ChatMessageBubble';
import { useSelector } from 'react-redux';
import { selectChat } from '@redux/chatSlice';
import { getPersonNameAndInitials } from '@config/functions/chatFunctions';

function TabPanel({ children, value, index, ...otherProps }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...otherProps}
    >
      {value === index && <Box sx={{ py: 3, px: 1 }}>{children}</Box>}
    </div>
  );
}

function tabProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-panel-${index}`,
  };
}

export default function ChatSharedMediaAccordion() {
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { activeRoom, messages, activeRoomMembersMap, userMemberId } =
    useSelector(selectChat);

  const sortedMessages = React.useMemo(() => {
    const filterAndSortMessages = (condition) => {
      return messages
        ?.filter((message) => {
          return (
            condition(message) &&
            message?.roomId === activeRoom?.id &&
            !message?.deleted
          );
        })
        .sort(
          (a, b) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        );
    };

    const linkCondition = (message) => message?.containsLink;
    const mediaCondition = (message) =>
      message?.attachmentId && message?.details?.file?.id;

    return {
      links: filterAndSortMessages(linkCondition),
      media: filterAndSortMessages(mediaCondition),
    };
  }, [messages, activeRoom]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderMessageBubble = (message) => {
    const isAuthorCurrentUser = message?.authorId === userMemberId;
    const { fullName } = getPersonNameAndInitials(
      activeRoomMembersMap[message?.authorId]
    );

    return (
      <ChatMessageBubble
        isGroup
        inSharedMedia
        key={message?.id}
        message={message}
        roomId={activeRoom?.id}
        membersMap={activeRoomMembersMap}
        userMemberId={userMemberId}
        name={fullName}
        type={isAuthorCurrentUser ? 'outgoing' : 'incoming'}
        onEditMessage={(newValue) => null}
        onDeleteMessage={() => null}
        scrollToMessage={() => null}
      />
    );
  };

  return (
    <Box>
      <ChatAccordion
        title='Shared Media'
        open={isAccordionOpen}
        setOpen={setIsAccordionOpen}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label='shared media tabs'
          >
            <Tab label='Links' {...tabProps(0)} />
            <Tab label='Media' {...tabProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          {sortedMessages.links.map(renderMessageBubble)}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {sortedMessages.media.map(renderMessageBubble)}
        </TabPanel>
      </ChatAccordion>
    </Box>
  );
}
