import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import ChatWindowHeaderContextMenu from '@components/Chat/ChatWindowHeaderContextMenu/ChatWindowHeaderContextMenu';
// import ChatSearch from '@components/Chat/ChatSearch/ChatSearch';

export default function ChatHeader() {
  return (
    <Box
      sx={{
        background: '#fff',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 16px',
        borderBottom: '1px solid rgba(11, 20, 26, .13)',
      }}
    >
      <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
        Chats
      </Typography>

      <Stack direction='row'>
        {/* <IconButton aria-label='search'>
          <SearchIcon fontSize='inherit' />
        </IconButton> */}

        <ChatWindowHeaderContextMenu />
      </Stack>

      {/* <ChatSearch placeholder='Search Chats...' setSearch={() => null} /> */}
    </Box>
  );
}
