import React from 'react';
import { useQueryClient } from 'react-query';
import {
  mxGraph,
  mxEvent,
  mxToolbar,
  mxUtils,
  mxGeometry,
  mxCell,
  mxRectangle,
  mxPoint,
  mxConstants,
  mxActor,
  mxCellRenderer,
  mxRubberband,
  mxGraphHandler,
  mxConnectionHandler,
  mxEdgeHandler,
  mxGuide,
  mxUndoManager,
} from 'mxgraph-js';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import {
  createRecord,
  updateRecord,
  deleteRecord,
} from '@config/functions/requests';
import { getBPMCellType } from '@config/functions/helperFunctions';
import { bpaUrls } from '@config/routes';
import { setShapeCells } from '@redux/bpaSlice';

const {
  shapesUrls,
  shapeTransitionsUrls,
  payloadSectionsUrls,
  bulkCreateOrUpdateShapesUrls,
} = bpaUrls;

// var cells = graph.getSelectionCells();
// var edges = cells.filter(function(cell) {
//     return cell.isEdge();
// });
// graph.removeCells(edges);

export default function usePSGraph({
  containerRef: container,
  toolbarRef,
  workflowId,
  draftStage,
  painted,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const graphRef = React.useRef(null);
  const undoManagerRef = React.useRef(null);
  const [activeCell, setActiveCell] = React.useState(null);
  const [refDone, setRefDone] = React.useState(false);
  const [undoRefDone, setUndoRefDone] = React.useState(false);
  const [docsPopupOpen, setDocsPopupOpen] = React.useState({
    open: false,
    shapeId: null,
  });
  const user = useSelector(selectProfile);

  React.useEffect(() => {
    if (!container || !toolbarRef) return;

    let graph = graphRef.current || new mxGraph(container.current);

    if (!graphRef.current) {
      graphRef.current = graph;
      setRefDone(true);
    }

    // Disables the built-in context menu
    mxEvent.disableContextMenu(container.current);
    // Set panning to true
    graph.setPanning(true);
    // Other options
    graph.setConnectable(true);
    graph.setAllowDanglingEdges(false);
    graph.setHtmlLabels(true);

    /* ======== Custom Shapes Start ================== */
    // DELAY SHAPE START
    function DelayShape() {
      mxActor.call(this);
    }
    mxUtils.extend(DelayShape, mxActor);

    DelayShape.prototype.redrawPath = function (c, x, y, w, h) {
      let dx = Math.min(w, h / 2);
      c.moveTo(0, 0);
      c.lineTo(w - dx, 0);
      c.quadTo(w, 0, w, h / 2);
      c.quadTo(w, h, w - dx, h);
      c.lineTo(0, h);
      c.close();
      c.end();
    };

    mxCellRenderer.registerShape('delay', DelayShape);
    // DELAY SHAPE END

    // LOOP LIMIT SHAPE START
    function LoopLimitShape() {
      mxActor.call(this);
    }

    mxUtils.extend(LoopLimitShape, mxActor);

    LoopLimitShape.prototype.size = 20;

    LoopLimitShape.prototype.isRoundable = function () {
      return true;
    };

    LoopLimitShape.prototype.redrawPath = function (c, x, y, w, h) {
      var s = Math.min(
        w / 2,
        Math.min(h, parseFloat(mxUtils.getValue(this.style, 'size', this.size)))
      );
      var arcSize =
        mxUtils.getValue(
          this.style,
          mxConstants.STYLE_ARCSIZE,
          mxConstants.LINE_ARCSIZE
        ) / 2;
      this.addPoints(
        c,
        [
          new mxPoint(s, 0),
          new mxPoint(w - s, 0),
          new mxPoint(w, s * 0.8),
          new mxPoint(w, h),
          new mxPoint(0, h),
          new mxPoint(0, s * 0.8),
        ],
        this.isRounded,
        arcSize,
        true
      );
      c.end();
    };

    mxCellRenderer.registerShape('loopLimit', LoopLimitShape);
    // LOOP LIMIT SHAPE END

    /* ======== Custom Shapes End ================== */

    /* ======== Toolbar Start ================== */
    const prevToolbar = document.querySelector('.ps-bpm-toolbar');

    if (prevToolbar) prevToolbar.innerHTML = '';

    const toolbar = new mxToolbar(toolbarRef.current);

    const addToolbarItem = (graph, toolbar, prototype, image) => {
      // executed when the image is dropped on
      // the graph. The cell argument points to the cell under
      // the mousepointer if there is one.
      const handler = (graph, e, cell) => {
        graph.stopEditing(false);
        let pt = graph.getPointForEvent(e);
        let vertex = graph.getModel().cloneCell(prototype);
        vertex.geometry.x = pt.x;
        vertex.geometry.y = pt.y;
        graph.setSelectionCells(graph.importCells([vertex], 0, 0, cell));
      };

      // Creates the image which is used as the drag icon (preview)
      let img = toolbar.addMode(null, image, handler);
      mxUtils.makeDraggable(img, graph, handler);
    };

    const addVertex = (icon, w, h, style) => {
      let vertex = new mxCell(null, new mxGeometry(0, 0, w, h), style);
      vertex.setVertex(true);
      addToolbarItem(graph, toolbar, vertex, icon);
    };

    addVertex(
      '/img/bpm/terminator.png',
      120,
      80,
      'shape=ellipse;strokeColor=black;fillColor=white;fontColor=black'
    );
    addVertex(
      '/img/bpm/process.png',
      120,
      60,
      'strokeColor=black;fillColor=white;fontColor=black'
    );
    addVertex(
      '/img/bpm/diamond.png',
      120,
      80,
      'shape=rhombus;strokeColor=black;fillColor=white;fontColor=black'
    );
    addVertex(
      '/img/bpm/loopLimit.png',
      120,
      60,
      'shape=loopLimit;whiteSpace=wrap;html=1;strokeColor=black;fillColor=white;fontColor=black'
    );
    // addVertex(
    //   '/img/bpm/subroutine-min.png',
    //   120,
    //   60,
    //   'shape=subroutine;strokeColor=black;fillColor=white;fontColor=black'
    // );
    addVertex(
      '/img/bpm/delay-min.png',
      120,
      60,
      'shape=delay;whiteSpace=wrap;html=1;strokeColor=black;fillColor=white;fontColor=black'
    );

    /* ======== Toolbar END ================== */

    /* ======== SCROLLING START ================== */
    graph.scrollTileSize = new mxRectangle(0, 0, 400, 400);

    graph.getPagePadding = function () {
      return new mxPoint(
        Math.max(0, Math.round(graph.container.offsetWidth - 34)),
        Math.max(0, Math.round(graph.container.offsetHeight - 34))
      );
    };

    graph.getPageSize = function () {
      return this.pageVisible
        ? new mxRectangle(
          0,
          0,
          this.pageFormat.width * this.pageScale,
          this.pageFormat.height * this.pageScale
        )
        : this.scrollTileSize;
    };

    graph.getPageLayout = function () {
      let size = this.pageVisible ? this.getPageSize() : this.scrollTileSize;
      let bounds = this.getGraphBounds();

      if (bounds.width === 0 || bounds.height === 0) {
        return new mxRectangle(0, 0, 1, 1);
      } else {
        // Computes untransformed graph bounds
        let x = Math.ceil(bounds.x / this.view.scale - this.view.translate.x);
        let y = Math.ceil(bounds.y / this.view.scale - this.view.translate.y);
        let w = Math.floor(bounds.width / this.view.scale);
        let h = Math.floor(bounds.height / this.view.scale);

        let x0 = Math.floor(x / size.width);
        let y0 = Math.floor(y / size.height);
        let w0 = Math.ceil((x + w) / size.width) - x0;
        let h0 = Math.ceil((y + h) / size.height) - y0;

        return new mxRectangle(x0, y0, w0, h0);
      }
    };

    graph.view.getBackgroundPageBounds = function () {
      let layout = this.graph.getPageLayout();
      let page = this.graph.getPageSize();

      return new mxRectangle(
        this.scale * (this.translate.x + layout.x * page.width),
        this.scale * (this.translate.y + layout.y * page.height),
        this.scale * layout.width * page.width,
        this.scale * layout.height * page.height
      );
    };

    graph.getPreferredPageSize = function (bounds, width, height) {
      let pages = this.getPageLayout();
      let size = this.getPageSize();

      return new mxRectangle(
        0,
        0,
        pages.width * size.width,
        pages.height * size.height
      );
    };

    /**
     * Guesses autoTranslate to avoid another repaint (see below).
     * Works if only the scale of the graph changes or if pages
     * are visible and the visible pages do not change.
     */
    let graphViewValidate = graph.view.validate;
    graph.view.validate = function () {
      if (
        this.graph.container != null &&
        mxUtils.hasScrollbars(this.graph.container)
      ) {
        let pad = this.graph.getPagePadding();
        let size = this.graph.getPageSize();

        // Updating scrollbars here causes flickering in quirks and is not needed
        // if zoom method is always used to set the current scale on the graph.
        // let tx = this.translate.x;
        // let ty = this.translate.y;
        this.translate.x = pad.x / this.scale - (this.x0 || 0) * size.width;
        this.translate.y = pad.y / this.scale - (this.y0 || 0) * size.height;
      }

      graphViewValidate.apply(this, arguments);
    };

    let graphSizeDidChange = graph.sizeDidChange;
    graph.sizeDidChange = function () {
      if (this.container != null && mxUtils.hasScrollbars(this.container)) {
        let pages = this.getPageLayout();
        let pad = this.getPagePadding();
        let size = this.getPageSize();

        // Updates the minimum graph size
        let minw = Math.ceil(
          (2 * pad.x) / this.view.scale + pages.width * size.width
        );
        let minh = Math.ceil(
          (2 * pad.y) / this.view.scale + pages.height * size.height
        );

        let min = graph.minimumGraphSize;

        // LATER: Fix flicker of scrollbar size in IE quirks mode
        // after delayed call in window.resize event handler
        if (min == null || min.width !== minw || min.height !== minh) {
          graph.minimumGraphSize = new mxRectangle(0, 0, minw, minh);
        }

        // Updates auto-translate to include padding and graph size
        let dx = pad.x / this.view.scale - pages.x * size.width;
        let dy = pad.y / this.view.scale - pages.y * size.height;

        if (
          !this.autoTranslate &&
          (this.view.translate.x !== dx || this.view.translate.y !== dy)
        ) {
          this.autoTranslate = true;
          this.view.x0 = pages.x;
          this.view.y0 = pages.y;
          let tx = graph.view.translate.x;
          let ty = graph.view.translate.y;
          graph.view.setTranslate(dx, dy);
          graph.container.scrollLeft += (dx - tx) * graph.view.scale;
          graph.container.scrollTop += (dy - ty) * graph.view.scale;
          this.autoTranslate = false;
          return;
        }

        graphSizeDidChange.apply(this, arguments);
      }
    };
    /* ====== SCROLLING END ================== */

    /* ====== SELECTION EVENTS START ================== */
    // Enables rubberband selection
    new mxRubberband(graph);
    mxRubberband.prototype.fadeOut = true;
    mxGraphHandler.prototype.guidesEnabled = true;
    mxGuide.prototype.isEnabledForEvent = function (evt) {
      return !mxEvent.isAltDown(evt);
    };
    mxEdgeHandler.prototype.snapToTerminals = true;

    mxEvent.disableContextMenu(container.current);
    // const cnktImg = 'img/bpm/connector.gif';
    // mxConnectionHandler.prototype.connectImage = new mxImage(cnktImg, 16, 16);
    mxConnectionHandler.prototype.movePreviewAway = false;
    mxEdgeHandler.prototype.removeEnabled = true;
    /* ====== SELECTION EVENTS END ================== */

    /* ====== STYLES START ================== */
    mxConstants.HANDLE_FILLCOLOR = '#99ccff';
    mxConstants.HANDLE_STROKECOLOR = '#0088cf';
    mxConstants.VERTEX_SELECTION_COLOR = '#00a8ff';
    mxConstants.STYLE_FONTCOLOR = '#000';
    mxConstants.DEFAULT_FONTSIZE = '14px';

    const stylesheet = graph.getStylesheet();

    stylesheet.getDefaultEdgeStyle()['edgeStyle'] = 'orthogonalEdgeStyle';
    stylesheet.getDefaultEdgeStyle()['strokeColor'] = 'black';
    stylesheet.getDefaultEdgeStyle()['fontSize'] = '14';
    stylesheet.getDefaultEdgeStyle()['fontColor'] = '#000';
    stylesheet.getDefaultEdgeStyle()['labelBackgroundColor'] = '#fff';

    let baseStyle = stylesheet.getDefaultVertexStyle();
    let edgeStyle = stylesheet.getDefaultEdgeStyle();
    let style = mxUtils.clone(baseStyle);
    style[mxConstants.STYLE_EDITABLE] = 0;
    style[mxConstants.STYLE_FILLCOLOR] = '#ffffff';
    style[mxConstants.STYLE_STROKECOLOR] = '#d4d4d4';
    style[mxConstants.STYLE_STROKEWIDTH] = 1;
    style[mxConstants.STYLE_ROUNDED] = 1;
    style[mxConstants.STYLE_ARCSIZE] = 10;
    style[mxConstants.STYLE_RESIZABLE] = 0;
    style[mxConstants.STYLE_MARGIN] = 50;
    style[mxConstants.STYLE_FONTFAMILY] = 'Inter, sans-serif';
    style[mxConstants.STYLE_FONTCOLOR] = '#000';
    style[mxConstants.STYLE_FONTSIZE] = '16';
    stylesheet.putCellStyle('style', style);
    edgeStyle[mxConstants.LABEL_HANDLE_SIZE] = 50;
    edgeStyle[mxConstants.HANDLE_FILLCOLOR] = '#fff';
    /* ====== STYLES END ================== */

    /* ======== UNDO ACTIONS START ================== */
    if (!undoManagerRef.current) {
      let undoManager = new mxUndoManager();
      undoManagerRef.current = undoManager;
      setUndoRefDone(true);
      function listener(sender, e) {
        undoManager.undoableEditHappened(e.getProperty('edit'));
      }
      graph.getModel().addListener(mxEvent.UNDO, listener);
      graph.getView().addListener(mxEvent.UNDO, listener);
    } else {
      let undoManager = undoManagerRef.current;
      function listener(sender, e) {
        undoManager.undoableEditHappened(e.getProperty('edit'));
      }
      graph.getModel().addListener(mxEvent.UNDO, listener);
      graph.getView().addListener(mxEvent.UNDO, listener);
    }
    /* ======== UNDO ACTIONS END ================== */

    /* ======== EVENTS START ================== */
    graph.getModel().addListener(mxEvent.CHANGE, async (_, evt) => {
      try {
        const changes = evt.getProperty('edit').changes;

        for (let i = 0; i < changes.length; i++) {
          // Manage any mxGeometryChange
          if (changes[i].constructor.name === 'mxGeometryChange') {
            if (changes[i].cell && changes[i].cell.edge) {
              const cell = changes[i].cell;
              const bendPoints = cell.geometry.points.map(({ x, y }) => ({
                x,
                y,
              }));

              await updateRecord({
                url: shapeTransitionsUrls.detail(cell.id),
                values: {
                  bend_points: JSON.stringify(bendPoints),
                },
                token: user.token,
                actAs: user?.actAs,
              });
              queryClient.invalidateQueries([`${workflowId}-shapes`]);
              queryClient.invalidateQueries([`${workflowId}-transitions`]);
            }
          }
        }
      } catch (err) {
        console.log('mxGeometryChange #107', err.response || err.message);
      }
    });

    // Move shapes
    graph.addListener(mxEvent.CELLS_MOVED, async (_, e) => {
      try {
        const cells = Array.from(e.properties.cells)
          .filter((cell) => !!cell.vertex)
          .map((cell) => ({
            id: cell.id,
            positionX: cell.geometry.x,
            positionY: cell.geometry.y,
          }));

        await createRecord({
          url: bulkCreateOrUpdateShapesUrls.list(),
          values: { shapes: cells },
          token: user.token,
          actAs: user?.actAs,
        });
      } catch (err) {
        console.log('CELLS_MOVED #65', err.response || err.message);
      }
    });

    // Update text
    graph.addListener(mxEvent.LABEL_CHANGED, async (_, e) => {
      try {
        const { cell, value } = e.properties;
        const updateOptions = {
          values: {
            name: value,
          },
          token: user.token,
          actAs: user?.actAs,
        };

        if (cell.edge) {
          updateOptions.url = shapeTransitionsUrls.detail(cell.id);
          await updateRecord(updateOptions);
        } else {
          updateOptions.url = shapesUrls.detail(cell.id);
          await updateRecord(updateOptions);

          const payloadOptions = {
            values: {
              source: 'ShapeDefn',
              source_value: cell?.id,
              name: value || '',
              workflow_payload: workflowId,
            },
            url: payloadSectionsUrls.detail(cell?.id),
            token: user.token,
            actAs: user?.actAs,
          };

          await updateRecord(payloadOptions);
        }
      } catch (err) {
        console.log('LABEL_CHANGED #67', err.response || err.message);
      }
    });

    // Resize shape
    graph.addListener(mxEvent.CELLS_RESIZED, async (_, e) => {
      try {
        const [cell] = e.properties.cells;
        if (cell && cell.vertex) {
          await updateRecord({
            url: shapesUrls.detail(cell.id),
            values: {
              positionX: cell.geometry.x,
              positionY: cell.geometry.y,
              width: cell.geometry.width,
              height: cell.geometry.height,
            },
            token: user.token,
            actAs: user?.actAs,
          });
        }
      } catch (err) {
        console.log('CELLS_RESIZED #166', err.response || err.message);
      }
    });

    graph.addListener(mxEvent.CLICK, (_, e) => {
      const cell = e.getProperty('cell');

      if (cell) {
        if (cell.edge) {
          window.addEventListener('keyup', (e) => {
            if (e.code === 'Delete') {
              graph.removeCells([cell]);
            }
          });
        }

        setActiveCell({
          id: cell.id,
          vertex: cell.vertex,
          value: cell.value,
          style: cell.style,
        });
      } else {
        setActiveCell(null);
      }
    });

    // Add shape
    graph.addListener(mxEvent.CELLS_ADDED, async (_, e) => {
      try {
        if (!painted) return;

        const [cell] = e.properties.cells;
        const id = uuidv4();

        cell.setId(id);

        if (!cell.edge) {
          cell.setValue(`${draftStage?.order} ${draftStage?.name}`);
        }

        dispatch(setShapeCells({ id, value: cell }));

        if (cell.edge) {
          await createRecord({
            values: {
              id,
              name: cell.value ?? '',
              style: cell.style,
              shape: cell.source.id,
              successor: cell.target.id,
              positionX: cell.geometry.x,
              positionY: cell.geometry.y,
              width: cell.geometry.width,
              height: cell.geometry.height,
            },
            url: shapeTransitionsUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          });
        } else {
          const type = getBPMCellType(cell);

          await createRecord({
            values: {
              id,
              name: cell.value ?? '',
              style: cell.style,
              workflow: workflowId,
              positionX: cell.geometry.x,
              positionY: cell.geometry.y,
              width: cell.geometry.width,
              height: cell.geometry.height,
              type,
            },
            url: shapesUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          });

          await createRecord({
            values: {
              id,
              source: 'ShapeDefn',
              source_value: id,
              name: cell?.value ?? '0 Draft',
              workflow_payload: workflowId,
            },
            url: payloadSectionsUrls.list(),
            token: user.token,
            actAs: user?.actAs,
          });
        }
      } catch (err) {
        console.log('CELLS_ADDED #90', err.response?.data ?? err.message);
      } finally {
        queryClient.invalidateQueries([`${workflowId}-shapes`]);
        queryClient.invalidateQueries([`${workflowId}-transitions`]);
      }
    });

    // Remove shape
    graph.addListener(mxEvent.CELLS_REMOVED, async (_, e) => {
      try {
        const [cell] = e.properties.cells;

        console.log('cell removed', cell);

        if (!cell) return;

        const deleteOptions = {
          url: cell.edge
            ? shapeTransitionsUrls.detail(cell.id)
            : shapesUrls.detail(cell.id),
          token: user.token,
          actAs: user?.actAs,
        };

        await deleteRecord(deleteOptions);

        if (!cell.edge) {
          const payloadOptions = {
            url: payloadSectionsUrls.detail(cell?.id),
            token: user.token,
            actAs: user?.actAs,
          };

          await deleteRecord(payloadOptions);
        }

        queryClient.invalidateQueries([`${workflowId}-shapes`]);
        queryClient.invalidateQueries([`${workflowId}-transitions`]);
      } catch (err) {
        console.log('CELLS_REMOVED #109', err.response || err.message);
      }
    });

    // ZOOM IN AND OUT WITH MOUSE WHEEL
    // mxEvent.addMouseWheelListener(function (evt, up) {
    //   if (!mxEvent.isConsumed(evt)) {
    //     if (up) {
    //       graph.zoomIn();
    //     } else {
    //       graph.zoomOut();
    //     }
    //     mxEvent.consume(evt);
    //   }
    // });
    /* ======== EVENTS END ================== */

    /* ======== CONTEXT ICONS START ================== */
    function mxIconSet(state) {
      this.images = [];
      let graph = state.view.graph;

      // Delete
      let img = mxUtils.createImage('/img/bpm/close.png');
      img.setAttribute('title', 'Delete');
      img.style.position = 'absolute';
      img.style.cursor = 'pointer';
      img.style.width = '16px';
      img.style.height = '16px';
      img.style.left = state.x + state.width + 'px';
      img.style.top = state.y - 16 + 'px';

      mxEvent.addGestureListeners(
        img,
        mxUtils.bind(this, function (evt) {
          // Disables dragging the image
          mxEvent.consume(evt);
        })
      );

      mxEvent.addListener(
        img,
        'click',
        mxUtils.bind(this, function (evt) {
          graph.removeCells([state.cell]);
          mxEvent.consume(evt);
          this.destroy();
        })
      );

      state.view.graph.container.appendChild(img);
      this.images.push(img);

      // Documents
      let docsImg = mxUtils.createImage('/img/bpm/docs.png');
      docsImg.setAttribute('title', 'View Assets');
      docsImg.style.position = 'absolute';
      docsImg.style.cursor = 'pointer';
      docsImg.style.width = '19px';
      docsImg.style.height = '19px';
      docsImg.style.left = state.x + state.width + -22 + 'px';
      docsImg.style.top = state.y - 19 + 'px';

      mxEvent.addGestureListeners(
        docsImg,
        mxUtils.bind(this, function (evt) {
          // Disables dragging the image
          mxEvent.consume(evt);
        })
      );

      mxEvent.addListener(
        docsImg,
        'click',
        mxUtils.bind(this, function (evt) {
          setDocsPopupOpen({ open: true, shapeId: state.cell.id });
          mxEvent.consume(evt);
          this.destroy();
        })
      );

      state.view.graph.container.appendChild(docsImg);
      this.images.push(docsImg);
    }

    mxIconSet.prototype.destroy = function () {
      if (this.images != null) {
        for (let i = 0; i < this.images.length; i++) {
          let img = this.images[i];
          img.parentNode.removeChild(img);
        }
      }

      this.images = null;
    };

    // Defines the tolerance before removing the icons
    const iconTolerance = 10;

    // Shows icons if the mouse is over a cell
    graph.addMouseListener({
      currentState: null,
      currentIconSet: null,
      mouseDown: function (sender, me) {
        // Hides icons on mouse down
        if (this.currentState != null) {
          this.dragLeave(me.getEvent(), this.currentState);
          this.currentState = null;
        }
      },
      mouseMove: function (sender, me) {
        if (
          this.currentState != null &&
          (me.getState() === this.currentState || me.getState() == null)
        ) {
          let tol = iconTolerance;
          let tmp = new mxRectangle(
            me.getGraphX() - tol,
            me.getGraphY() - tol,
            2 * tol,
            2 * tol
          );

          if (mxUtils.intersects(tmp, this.currentState)) {
            return;
          }
        }

        let tmp2 = graph.view.getState(me.getCell());

        // Ignores everything but vertices
        if (
          graph.isMouseDown ||
          (tmp2 != null && !graph.getModel().isVertex(tmp2.cell))
        ) {
          tmp2 = null;
        }

        if (tmp2 !== this.currentState) {
          if (this.currentState != null) {
            this.dragLeave(me.getEvent(), this.currentState);
          }

          this.currentState = tmp2;

          if (this.currentState != null) {
            this.dragEnter(me.getEvent(), this.currentState);
          }
        }
      },
      mouseUp: function (sender, me) { },
      dragEnter: function (evt, state) {
        if (this.currentIconSet == null) {
          this.currentIconSet = new mxIconSet(state);
        }
      },
      dragLeave: function (evt, state) {
        if (this.currentIconSet != null) {
          this.currentIconSet.destroy();
          this.currentIconSet = null;
        }
      },
    });

    /* ======== CONTEXT ICONS END ================== */
  }, [
    user,
    painted,
    workflowId,
    container,
    toolbarRef,
    setRefDone,
    setUndoRefDone,
    setDocsPopupOpen,
  ]);

  return {
    refDone,
    activeCell,
    undoRefDone,
    graph: graphRef.current,
    undoManager: undoManagerRef.current,
    docsPopupOpen,
    setDocsPopupOpen,
  };
}
