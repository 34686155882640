import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { systemUrls } from '@config/routes';
import { clientMeta } from '@config/meta/system';
import { ClientRelationForm } from '@config/forms/system';
import { clientRelationsRowsMap } from '@config/handleRows/system';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';
import DetailAccordionView from '@components/DetailAccordionView';
import { microservices, pullstreamClientId } from '@config/constants';
import { createRecord } from '@config/functions/requests';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { clientRelationsUrls, clientsUrls } = systemUrls;

export default function ClientDetail({ labels, editMode }) {
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const pullstreamCompanyAdministrator =
    user?.details?.roles_names.includes('Company Administrator') &&
    user?.details?.client === pullstreamClientId &&
    (!user?.actAs?.id || user?.actAs?.id === pullstreamClientId);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback((data) => clientMeta({ data }), []);

  return (
    <>
      <Box>
        {recordData ? (
          <Box>
            <DetailPageHeader
              items={[
                {
                  to: `/admin-clients`,
                  text: 'Clients',
                },
                { text: recordData?.name },
              ]}
            />
          </Box>
        ) : null}

        <DetailCard
          urls={clientsUrls}
          recordId={recordId}
          getSchema={getSchema}
          setData={setRecordData}
        />
        <Box sx={{ mt: 5 }}>
          <DetailAccordionView
            microservice={microservices.System.name}
            model={microservices.System.models.clientRelations}
            columnKey='clientRelationsSystem'
            label='Client Relations'
            editMode={editMode}
            labels={labels}
            prefix='oqq'
            urls={clientRelationsUrls}
            addNewForm={
              pullstreamCompanyAdministrator
                ? {
                    getFields: ({ setFieldValue, values }) =>
                      ClientRelationForm({
                        values,
                        setFieldValue,
                      }),
                    handleSubmit: async (
                      values,
                      setSubmitting,
                      resetForm,
                      setAddNewForm,
                      refetch,
                      setError
                    ) => {
                      try {
                        const { child_client, relation, ...rest } = values;

                        const response = await createRecord({
                          values: {
                            parent_client: recordId,
                            child_client: child_client?.id,
                            relation: relation?.id,
                            ...rest,
                          },
                          url: clientRelationsUrls.list(),
                          token: user.token,
                          actAs: user?.actAs,
                        });

                        refetch();
                        resetForm();
                        setAddNewForm(false);
                        return response?.data?.id;
                      } catch (err) {
                        handleCreateFormErrors({
                          err,
                          setError,
                          notify,
                          values,
                        });
                      } finally {
                        setSubmitting(false);
                      }
                    },
                  }
                : null
            }
            handleRowMap={clientRelationsRowsMap}
            urlParams={`&parent_client=${recordId}`}
            showBulkDeleteButton={pullstreamCompanyAdministrator}
            hideActions={!pullstreamCompanyAdministrator}
          />
        </Box>
      </Box>
    </>
  );
}
