import * as Yup from 'yup';
import { fieldType, timeZones, microservices } from '@config/constants';
import { appHost } from '@config/msHosts';
import { calendarUrls, crmUrls, hrUrls, communicationUrls } from '@config/routes';
import {
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import {
  DAILY_RRULE,
  progressType,
} from '../../constants';

const { actionsUrls, slotsUrls } = calendarUrls;
const { personsUrls } = crmUrls;
const { employeesUrls } = hrUrls;
const { mailTemplatesUrls } = communicationUrls;

export const doWhatMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.TEXT,
    width: 180,
    editable: true,
    value: data && data.name,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 750,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.description,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().max(2500),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];


export const inasMeta = ({ data, dataPanel = false } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    to: '/inas/',
    linkInNewTab: dataPanel,
    width: 180,
    validate: Yup.string(),
    value: data && data.name,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    value: data && data.ina_notes,
  },
  {
    field: 'reminder_start_datetime',
    headerName: 'Reminder Time',
    width: 180,
    value: data?.details
      ? formatToUTCDateTime(new Date(data?.details?.event?.start_date_time))
      : null,
    onAccordionForm: true,
    editable: true,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'do_what',
    headerName: 'Do What',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: actionsUrls.list,
    requestKey: `${data?.id}-inas-do-what`,
    validate: Yup.mixed(),
    initialValue: data?.do_what
      ? {
        id: data?.do_what,
        label: data?.details?.do_what?.name,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
  },
  {
    width: 180,
    field: 'progress',
    headerName: 'Progress',
    type: fieldType.SELECT,
    editable: true,
    options: progressType,
    initialValue: '',
    value: data ? data.progress : null,
    validate: Yup.string(),
    onAccordionForm: true,
  },

  {
    field: 'responsible',
    headerName: 'Responsible',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: employeesUrls.list,
    requestKey: `${data?.id}-inas-responsible`,
    validate: Yup.mixed(),
    initialValue: data?.responsible
      ? {
        id: data?.responsible,
        label: data?.details?.responsible?.work_email,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.work_email,
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];


export const batchReminderMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    type: fieldType.TEXT,
    to: '/batch-reminders/',
    onForm: true,
    width: 200,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(700).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 250,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'start_date_time',
    headerName: 'Start Date Time',
    onForm: true,
    width: 200,
    editable: true,
    type: fieldType.DATE_TIME,
    value: data?.start_date_time,
    initialValue: new Date(),
    validate: Yup.date().default(() => new Date()).required(),
    showTimezone: true,
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
    onForm: true,
    type: fieldType.SELECT,
    width: 180,
    editable: true,
    options: timeZones,
    value: data?.timezone,
    initialValue: Intl.DateTimeFormat().resolvedOptions().timeZone,
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'rrule',
    headerName: 'Repeat',
    onForm: true,
    type: fieldType.REPEAT,
    width: 180,
    editable: true,
    value: data?.rrule,
    initialValue: DAILY_RRULE,
    showDoesNotRepeat: false,
    showCancel: true,
    validate: Yup.string().required(),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: new Date(data?.created_at),
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const calendarMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    width: 200,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'color',
    headerName: 'color',
    onForm: true,
    hide: true,
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data && data.color,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
    onForm: true,
    type: fieldType.SELECT,
    width: 180,
    editable: true,
    options: timeZones,
    value: data && data.timezone,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'sync',
    type: fieldType.BOOLEAN,
    headerName: 'Sync',
    onForm: true,
    width: 250,
    value: data && data.sync,
    editable: true,
    initialValue: false,
    validate: Yup.boolean().default(false),
  },
];

export const linkMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 350,
    type: fieldType.TEXT,
    editable: true,
    to: '/slot-links/',
    gridStyle: 12,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
    editable: true,
    type: fieldType.MULTILINE,
    value: data && data.description,
    initialValue: '',
    onForm: true,
    validate: Yup.string().max(2500).nullable(),
  },
  {
    field: 'shortened_url',
    headerName: 'Short link',
    onForm: false,
    editable: false,
    value: data?.shortened_url,
    initialValue: '',
    width: 300,
    type: fieldType.TEXT,
    validate: Yup.string().url(),
    linkInNewTab: true,
    getHref: (r) => r.shortened_url || '#',
  },
  {
    field: 'link',
    headerName: 'Link',
    onForm: false,
    width: 500,
    value: data && `${appHost}/slots/${data.id}`,
    getHref: (r) => `/slots/${r.id}`,
    type: fieldType.LINK,
    editable: false,
    onDetailCard: false,
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const linkSlotMeta = ({ data } = {}) => [
  {
    field: 'slot',
    headerName: 'Slot',
    width: 170,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: slotsUrls.list,
    requestKey: `${data?.id}-calendar-slot-link`,
    validate: Yup.mixed().required('Please select slot'),
    initialValue: data?.slot
      ? {
        id: data.slot,
        label: `${data.slot?.title}`,
      }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.title}`,
    }),
    urlParams: `&parent_event__isnull=True&event_type=SlotEvent`
  },
  {
    field: 'start_date_time',
    headerName: 'Start Date Time',
    width: 280,
    editable: false,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
  {
    field: 'end_date_time',
    headerName: 'End Date Time',
    width: 280,
    editable: false,
    type: fieldType.DATE_TIME,
    validate: Yup.string().required(),
  },
];


export const linkRecipientMeta = ({ data, queryClient } = {}) => [
  {
    field: 'first_name',
    headerName: 'First name',
    onAccordionForm: true,
    width: 250,
    editable: false,
    value: data ? data.first_name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'last_name',
    headerName: 'Last name',
    onAccordionForm: true,
    width: 250,
    editable: false,
    value: data ? data.last_name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'email',
    headerName: 'Email',
    onAccordionForm: true,
    editable: false,
    value: data ? data.email : null,
    initialValue: '',
    width: 200,
    validate: Yup.string().email().required(),
  },
  {
    field: 'mail_template',
    headerName: 'Mail Template',
    width: 170,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: mailTemplatesUrls.list,
    requestKey: `${data?.id}-mail-templates`,
    validate: Yup.mixed().required('Please select a mail template.'),
    initialValue: data?.mail_template
      ? {
        id: data?.mail_template,
        label: `${data?.mail_template?.name}`,
        contextFields: data?.mail_template?.details?.context_fields,
      }
      : null,
    renderRow: (row) => ({
      id: row?.mail_template,
      label: `${row?.mail_template?.name}`,
      contextFields: row?.mail_template?.details?.context_fields,
    }),
  },
  {
    field: 'person',
    headerName: 'Person',
    width: 300,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-recipients-person`,
    validate: Yup.mixed(),
    initialValue: data?.person
      ? {
        id: data?.person,
        label: data?.details?.person?.email,
      }
      : null,
    renderRow: (row) => ({
      id: row.id,
      label: `${row?.first_name || 'Unamed'} ${row?.last_name || ''} (${row?.email || ''
        })`,
    }),
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-recipients-person`]);
      },
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'created_at',
    headerName: 'Date created',
    width: 180,
    value: data?.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
