import React from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ChatWindowHeader from '@components/Chat/ChatWindowHeader/ChatWindowHeader';
import ChatWindowInputBox from '@components/Chat/ChatWindowInputBox/ChatWindowInputBox';
import ChatWindowMessageList from '@components/Chat/ChatWindowMessageList/ChatWindowMessageList';
import {
  RoomStatus,
  selectChat,
  setGroupChatSettingsOpen,
  setPrivateChatSettingsOpen,
} from '@redux/chatSlice';
import GroupChatSettings from '@components/Chat/GroupChatSettings/GroupChatSettings';
import PrivateChatSettings from '@components/Chat/PrivateChatSettings/PrivateChatSettings';
import ChatLoader from '@components/Chat/ChatLoader/ChatLoader';

export default function ChatWindow({
  socket,
  onSendMessage,
  onDeleteMessage,
  onEditMessage,
  onMessageRead,
}) {
  const dispatch = useDispatch();

  const {
    activeRoom,
    activeRoomState,
    replyTo,
    userMemberId,
    activeRoomMembersMap,
    groupChatSettingsOpen,
    privateChatSettingsOpen,
  } = useSelector(selectChat);

  const CONTAINER_STYLES = React.useMemo(() => {
    const isLoading = activeRoomState?.status === RoomStatus.Loading;

    return {
      height: '100%',
      width: {
        xs: activeRoom?.id ? '100%' : undefined,
        sm: 'calc(100% - 320px)',
      },
      background: activeRoom?.id ? '#EFEAE2' : '#F1F4F6',
      display: 'flex',
      flexDirection: 'column',
      alignItems: isLoading ? 'center' : undefined,
      justifyContent: isLoading ? 'center' : 'space-between',
      overflow: 'hidden',
      position: 'relative',
      flex: {
        xs: activeRoom?.id || isLoading ? 1 : undefined,
        sm: 'initial',
      },
    };
  }, [activeRoom, activeRoomState]);

  const handleSettingsClick = () => {
    if (activeRoom?.isGroup) {
      dispatch(setGroupChatSettingsOpen(true));
    } else {
      dispatch(setPrivateChatSettingsOpen(true));
    }
  };

  return (
    <Box sx={CONTAINER_STYLES}>
      {activeRoomState?.status === RoomStatus.Loading ? (
        <ChatLoader />
      ) : activeRoom?.id ? (
        <>
          <ChatWindowHeader
            activeRoom={activeRoom}
            onChatSettingsClick={handleSettingsClick}
          />
          <ChatWindowMessageList
            onDeleteMessage={onDeleteMessage}
            onEditMessage={onEditMessage}
            membersMap={activeRoomMembersMap}
            onMessageRead={onMessageRead}
          />
          <ChatWindowInputBox
            socket={socket}
            replyTo={replyTo}
            membersMap={activeRoomMembersMap}
            activeRoom={activeRoom}
            userMemberId={userMemberId}
            onSendMessage={onSendMessage}
          />

          {privateChatSettingsOpen ? (
            <PrivateChatSettings
              activeRoom={activeRoom}
              setOpen={(value) => dispatch(setPrivateChatSettingsOpen(value))}
            />
          ) : null}

          {groupChatSettingsOpen ? (
            <GroupChatSettings
              activeRoom={activeRoom}
              setOpen={(value) => dispatch(setGroupChatSettingsOpen(value))}
            />
          ) : null}
        </>
      ) : null}
    </Box>
  );
}
