import * as React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatMessageDeletePopup from '@components/Chat/ChatMessageDeletePopup/ChatMessageDeletePopup';
import ChatMessageEditPopup from '@components/Chat/ChatMessageEditPopup/ChatMessageEditPopup';
import { useDispatch } from 'react-redux';
import { setReplyTo } from '@redux/chatSlice';
import axios from 'axios';
import { nodeDriveUrls } from '@config/routes';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { getAuthHeader } from '@config/functions/helperFunctions';

const { fileDownloadUrls } = nodeDriveUrls;

export default function ChatMessageContextMenu({
  type,
  message,
  onDeleteMessage,
  onEditMessage,
  inSharedMedia,
}) {
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const [deleteMessageOpen, setDeleteMessageOpen] = React.useState(false);
  const [editMessageOpen, setEditMessageOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isOutgoing = type === 'outgoing';
  const hasAttachment = !!(message?.attachmentId && message?.details?.file?.id);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReply = () => {
    dispatch(setReplyTo(message));
    setAnchorEl(null);
  };

  const handleDownload = async () => {
    setAnchorEl(null);

    try {
      const file = message?.details?.file;

      const response = await axios.get(fileDownloadUrls.detail(file?.id), {
        ...getAuthHeader(user?.token, user?.actAs),
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement('a');

      a.style.display = 'none';

      a.href = url;

      a.download = file?.name;

      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err?.response?.data ?? err.message);
    }
  };

  const outgoingActions = [
    {
      onClick: () => {
        setEditMessageOpen(true);
        setAnchorEl(null);
      },
      label: 'Edit',
    },
    {
      onClick: () => {
        setDeleteMessageOpen(true);
        setAnchorEl(null);
      },
      label: 'Delete',
    },
  ];

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'message-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label='message-context'
        size='small'
      >
        <MoreVertIcon fontSize='inherit' />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        elevation={2}
        sx={{ boxShadow: '0 1px .5px rgba(11, 20, 26, .13)' }}
      >
        {!inSharedMedia ? (
          <MenuItem onClick={handleReply}>Reply</MenuItem>
        ) : null}

        {hasAttachment ? (
          <MenuItem onClick={handleDownload}>Download</MenuItem>
        ) : null}

        {isOutgoing &&
          outgoingActions.map(({ onClick, label }) => (
            <MenuItem key={label} onClick={onClick}>
              {label}
            </MenuItem>
          ))}
      </Menu>

      {deleteMessageOpen ? (
        <ChatMessageDeletePopup
          open={deleteMessageOpen}
          setOpen={setDeleteMessageOpen}
          onDeleteMessage={onDeleteMessage}
        />
      ) : null}

      {editMessageOpen ? (
        <ChatMessageEditPopup
          open={editMessageOpen}
          setOpen={setEditMessageOpen}
          onEditMessage={onEditMessage}
          initialMessage={message?.content}
        />
      ) : null}
    </Box>
  );
}
