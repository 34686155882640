import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { accountUrls, chatUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';
import {
  FormControl,
  Checkbox,
  FormGroup,
  Stack,
  TextField,
  FormControlLabel,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { useQueryClient } from 'react-query';

const { chatUsersUrls } = accountUrls;
const { roomsBulkUrls } = chatUrls;

export default function ChatNewGroupPopup({
  open,
  setOpen,
  initialMessage,
  onEditMessage,
}) {
  const queryClient = useQueryClient();
  const user = useSelector(selectProfile);
  const [formError, setFormError] = React.useState('');
  const [groupName, setGroupName] = React.useState('');
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [selectedMembers, setSelectedMembers] = React.useState([]);

  const { data, isError, isLoading } = useRQuery({
    key: [`group-members`, user.token, user?.actAs],
    url: chatUsersUrls.list('?person__not_null=true'),
    config: getAuthHeader(user.token, user?.actAs),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (memberId) => {
    let clonedSelectedMembers = [...selectedMembers];

    if (!clonedSelectedMembers.includes(memberId)) {
      clonedSelectedMembers.push(memberId);
    } else if (clonedSelectedMembers.includes(memberId)) {
      clonedSelectedMembers = clonedSelectedMembers.filter(
        (id) => id !== memberId
      );
    }

    setSelectedMembers(clonedSelectedMembers);
  };

  const handleSave = async () => {
    try {
      setSubmitting(true);
      setFormError('');

      if (!groupName?.length) {
        throw new Error('Please enter a group name');
      }

      if (!selectedMembers?.length) {
        throw new Error('Please select group members');
      }

      const userPersonId = user?.details?.details?.person?.id;

      if (!userPersonId) {
        throw new Error('Your CRM person record does not seem to be created');
      }

      const { data } = await createRecord({
        values: {
          rooms: [
            {
              status: 'Open',
              type: 'CompanyChat',
              name: groupName,
              isGroup: true,
              members: [user?.details?.details?.person?.id, ...selectedMembers],
            },
          ],
        },
        url: roomsBulkUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      if (data?.errors?.length) {
        const [error] = data?.errors;
        throw new Error(error?.error);
      }

      setGroupName('');
      setSelectedMembers([]);
      queryClient.invalidateQueries(['company-chat-rooms']);
      handleClose();
    } catch (error) {
      setFormError(error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Create a new group</DialogTitle>
      <DialogContent>
        <Stack sx={{ pt: 1 }} spacing={2}>
          {formError ? (
            <Alert severity="error" sx={{ fontWeight: '600' }}>
              {formError}
            </Alert>
          ) : null}

          <TextField
            label="Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            size="small"
          />

          <Typography sx={{ fontWeight: '500' }}>Choose members</Typography>
          {isError ? (
            <Typography>Failed to fetch members</Typography>
          ) : isLoading || !Array.isArray(data) ? (
            <Typography>loading members...</Typography>
          ) : (
            <Box
              sx={{
                maxHeight: '390px',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {data?.map((user) => (
                    <FormControlLabel
                      key={user?.person}
                      id={user?.person}
                      control={
                        <Checkbox
                          size="small"
                          name={user?.person}
                          checked={selectedMembers.includes(user?.person)}
                          onChange={() => handleChange(user?.person)}
                        />
                      }
                      label={user?.details?.profile?.user?.email}
                      sx={{ userSelect: 'none' }}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={isSubmitting}>
          {isSubmitting ? 'Saving' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
