import React from 'react';
import { mxPoint } from 'mxgraph-js';
import { getBPMCellType, parseJSON } from '@config/functions/helperFunctions';
import { v4 as uuidv4 } from 'uuid';

export default function Graph({
  containerRef,
  painted,
  setPainted,
  graph,
  refDone,
  shapes,
  transitions,
  cwfShapes,
}) {
  React.useEffect(() => {
    if (!graph || !refDone || painted || !shapes || !transitions) return;

    let parent = graph.getDefaultParent();

    // Adds cells to the model in a single step
    graph.getModel().beginUpdate();
    try {
      graph.insertVertex(parent, null, '', 570, 5700, 1, 1);
    } finally {
      // Updates the display
      graph.getModel().endUpdate();
    }

    graph.getModel().beginUpdate();

    try {
      const parent = graph.getDefaultParent();
      const cells = [];
      const subroutines = shapes.filter((shape) => shape.type === 'subroutine');
      const childTransitions = [];

      // Insert current workflow shapes to the grid
      shapes.map((shape) => {
        const stage = shape?.details?.stage;
        const label = `${shape?.stage_full_order ?? ''}${stage?.order} ${
          stage?.name
        }`;

        const insert = () => {
          const cell = graph.insertVertex(
            parent,
            shape.id,
            label,
            shape.positionX,
            shape.positionY,
            shape.width,
            shape.height,
            shape.style
          );
          cells.push(cell);
        };

        const transition = transitions.find((t) => t.successor === shape.id);
        const prevShape = shapes.find(
          (c) => c.id === (transition && transition.shape)
        );

        if (!transition || (prevShape && prevShape.type !== 'subroutine')) {
          insert();
        }
      });

      subroutines.map((shape) => {
        const sId = shape.id;
        const transition = transitions.find((t) => t.shape === sId);
        if (!transition) return;
        const nextShape = shapes.find((c) => c.id === transition.successor);
        if (!nextShape) return;

        const childs = cwfShapes.find((c) => c.child_workflow_shape === sId);
        if (!childs) return;

        let initPositionX;
        let initPositionY;

        childs.shapes.map((k, index) => {
          let positionX;
          let positionY;

          if (index === 0) {
            positionX = nextShape.positionX;
            positionY = nextShape.positionY;
            initPositionX = nextShape.positionX;
            initPositionY = nextShape.positionY;
            childTransitions.push({
              id: uuidv4(),
              shape_id: sId,
              successor_id: k.id,
              name: '',
            });
          } else {
            const defaultPos = {
              positionX: 0,
              positionY: 0,
            };
            const prevCWFCell = childs.shapes[index - 1] || defaultPos;
            const diffX = k.positionX - prevCWFCell.positionX;
            const diffY = k.positionY - prevCWFCell.positionY;
            positionX = initPositionX + diffX;
            positionY = initPositionY + diffY;
          }

          const style = k.style.replace(
            'strokeColor=black;fillColor=white;',
            ''
          );

          const cell = graph.insertVertex(
            parent,
            k.id,
            k.name,
            positionX,
            positionY,
            k.width,
            k.height,
            style
          );

          cells.push(cell);

          if (index === childs.shapes.length - 1) {
            const diffX = nextShape.positionX - shape.positionX;
            const diffY = nextShape.positionY - shape.positionY;
            const cell = graph.insertVertex(
              parent,
              nextShape.id,
              nextShape.name,
              positionX + diffX,
              positionY + diffY,
              nextShape.width,
              nextShape.height,
              nextShape.style
            );
            childTransitions.push({
              id: uuidv4(),
              shape_id: k.id,
              successor_id: nextShape.id,
              name: '',
            });
            cells.push(cell);
          }
        });

        const transitionsMerged = [
          ...childs.shape_transitions,
          ...childTransitions,
        ];

        transitionsMerged.map((r) => {
          const src = cells.find((k) => k.id === r.shape_id);
          const target = cells.find((k) => k.id === r.successor_id);

          const edge = graph.insertEdge(
            parent,
            r.id,
            r.name,
            src,
            target,
            'edgeStyle=orthogonalEdgeStyle;fontSize=14;fontColor=black;labelBackgroundColor=#fff;strokeColor=black'
          );

          const { data: bendPoints } = parseJSON(r.bend_points);

          if (bendPoints) {
            edge.geometry.points = bendPoints.map(
              ({ x, y }) => new mxPoint(x, y)
            );
          }
        });
      });

      transitions.map((r) => {
        const src = cells.find((k) => k.id === r.shape);
        const target = cells.find((k) => k.id === r.successor);
        const srcType = getBPMCellType(src);

        if (srcType === 'subroutine') return;

        const edge = graph.insertEdge(
          parent,
          r.id,
          r.name,
          src,
          target,
          'edgeStyle=orthogonalEdgeStyle;fontSize=14;fontColor=black;labelBackgroundColor=#fff;strokeColor=black'
        );

        const { data: bendPoints } = parseJSON(r.bend_points);

        if (bendPoints) {
          edge.geometry.points = bendPoints.map(
            ({ x, y }) => new mxPoint(x, y)
          );
        }
      });
    } catch (err) {
      console.log(err.message);
    } finally {
      graph.getModel().endUpdate();
    }

    setPainted(true);
  }, [graph, painted, refDone, transitions, shapes, setPainted, cwfShapes]);

  return (
    <div
      className='ps-bpm-grid'
      ref={containerRef}
      style={{
        position: 'relative',
        width: '940px',
        height: '75vh',
        overflow: 'auto',
        backgroundColor: '#fff',
        backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=')`,
        boxShadow: 'rgb(221 221 221) 0px 0px 0.5px 0.5px',
      }}
    />
  );
}
