import React from 'react';
import { Box, Typography, IconButton, Stack, Avatar } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { deepPurple } from '@mui/material/colors';
import GroupIcon from '@mui/icons-material/Group';

export default function ChatWindowHeader({
  activeRoom,
  memberDetails,
  onChatSettingsClick,
}) {
  return (
    <Box
      sx={{
        background: '#F0F2F5',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 16px',
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1.5}>
        <Avatar sx={{ bgcolor: deepPurple[300], fontSize: '18px' }}>
          {activeRoom?.isGroup ? <GroupIcon /> : activeRoom?.initials}
        </Avatar>
        <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
          {activeRoom?.name}
        </Typography>
      </Stack>

      <Stack direction='row'>
        <IconButton aria-label='search'>
          <SearchIcon fontSize='inherit' />
        </IconButton>

        <IconButton aria-label='chat-options' onClick={onChatSettingsClick}>
          <MoreVertIcon fontSize='inherit' />
        </IconButton>
      </Stack>
    </Box>
  );
}
