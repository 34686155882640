import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { chatUrls } from '@config/routes';
import { deleteRecord } from '@config/functions/requests';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import useToast from '@hooks/useToast';

const { roomsUrls } = chatUrls;

export default function ChatDeleteGroupPopup({ open, roomId, onCancelClick }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [isDeleting, setDeleting] = React.useState(false);

  const onGroupDelete = async () => {
    try {
      setDeleting(true);

      await deleteRecord({
        url: roomsUrls.detail(roomId),
        token: user.token,
        actAs: user?.actAs,
      });

      onCancelClick();
      notify('Group deleted.', {
        type: 'SUCCESS',
      });
    } catch (error) {
      notify('There was an error, please retry later', {
        type: 'ERROR',
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth='sm'
      aria-labelledby='delete-group'
    >
      <DialogTitle id='delete-dialog-title'>Delete Group?</DialogTitle>

      <DialogContent>
        <DialogContentText
          id='delete-dialog-description'
          sx={{
            mb: '10px',
          }}
        >
          Are you sure you want to delete this group?
        </DialogContentText>

        <DialogActions>
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button color='error' onClick={onGroupDelete} disabled={isDeleting}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
