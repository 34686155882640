import React from 'react';
import { Box } from '@mui/material';
import ChatMessageItem from '@components/Chat/ChatMessageItem/ChatMessageItem';
import ChatHeader from '@components/Chat/ChatHeader/ChatHeader';
import { RoomStatus, selectChat } from '@redux/chatSlice';
import { useSelector } from 'react-redux';
import { compareRoomsByLatestMessageOrCreationTime } from '@config/functions/chatFunctions';

export default function ChatSidebar({ onMessageItemClick, userPersonId }) {
  const {
    activeRoom,
    rooms,
    typingStatus,
    userMemberId,
    activeRoomState,
    // prettier xxx
  } = useSelector(selectChat);

  const isRoomLoading = activeRoomState?.status === RoomStatus.Loading;

  console.log('typingStatus', typingStatus);

  const sortedRooms = React.useMemo(() => {
    return [...rooms].sort(compareRoomsByLatestMessageOrCreationTime);
  }, [rooms]);

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '320px' },
        height: '100%',
        borderRight: '1px solid rgba(11, 20, 26, .13)',
        overflow: 'hidden',
        position: 'relative',
        display: {
          xs: activeRoom?.id || isRoomLoading ? 'none' : undefined,
          sm: 'initial',
        },
      }}
    >
      <ChatHeader />

      <Box
        sx={{
          height: 'calc(100% - 60px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        {sortedRooms.map((room) => {
          const roomTypingStatus = typingStatus[room?.id] ?? {};
          const isSameUser = roomTypingStatus?.authorId === userMemberId;
          const isTyping = roomTypingStatus?.isTyping && !isSameUser;
          const isMessagesArray = Array.isArray(room?.roomMessages);
          const [latestMessage] = isMessagesArray ? room.roomMessages : [];

          return (
            <ChatMessageItem
              key={room?.id}
              isGroup={room?.isGroup}
              initials={room?.initials}
              name={room?.name}
              latestMessage={latestMessage}
              isTyping={isTyping}
              active={activeRoom?.id === room?.id}
              unreadCount={room?.unreadCount}
              userPersonId={userPersonId}
              onClick={() => onMessageItemClick(room)}
            />
          );
        })}
      </Box>
    </Box>
  );
}
