import React from 'react';
import { Box, Stack, Typography, Avatar, Badge } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  truncateContent,
  formatChatMessageDate,
} from '@config/functions/chatFunctions';
import GroupIcon from '@mui/icons-material/Group';

export default function ChatMessageItem({
  avatarColor,
  initials,
  name,
  active,
  onClick,
  isTyping,
  userPersonId,
  latestMessage,
  isGroup,
  unreadCount,
}) {
  const notTypingNoMessage = !isTyping && !latestMessage;

  const displayMessage = () => {
    if (isTyping) return 'Typing...';

    if (!latestMessage) return null;

    if (latestMessage?.deleted) return 'This message was deleted.';

    // Determine the message content based on the presence of an attachment or text content
    const messageContent = latestMessage?.attachmentId
      ? latestMessage?.content
        ? `[Attachment] ${latestMessage.content}` // Both attachment and content are present
        : '[Attachment]' // Only attachment is present
      : latestMessage?.content; // Only content is present or no message

    // Set the body to the determined message content or default to '[File]'
    const content = messageContent || '[Attachment]';

    return truncateContent(content, 20);
  };

  return (
    <Stack
      direction='row'
      spacing={1.3}
      alignItems='center'
      onClick={() => onClick()}
      sx={{
        userSelect: 'none',
        padding: '9px',
        width: '100%',
        backgroundColor: active ? '#f0f2f5' : '#fff',
        borderBottom: '1px solid rgba(11, 20, 26, .09)',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f0f2f5',
        },
      }}
    >
      <Avatar sx={{ bgcolor: avatarColor, fontSize: '18px' }}>
        {isGroup ? <GroupIcon /> : initials}
      </Avatar>

      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontWeight: 'bold', m: 0 }}>
          {truncateContent(name, 18)}
        </Typography>

        <Stack
          sx={{ minHeight: '20px' }}
          direction='row'
          alignItems='center'
          spacing={0.5}
        >
          {latestMessage?.author?.memberId === userPersonId ? (
            // sx={{ color: blue[500] }}
            <DoneAllIcon fontSize='small' />
          ) : null}

          <Typography
            variant='caption'
            color={isTyping ? 'primary' : ''}
            sx={{
              fontWeight: isTyping ? '600' : '',
              opacity: notTypingNoMessage ? '0' : '100',
              transition: 'opacity 1s ease',
              m: 0,
            }}
          >
            {displayMessage()}
          </Typography>
        </Stack>
      </Box>

      {isTyping ? null : (
        <Stack alignItems='center' spacing={0.4}>
          {unreadCount > 0 && (
            <Box sx={{ flexShrink: 0 }}>
              <Badge badgeContent={unreadCount} color='primary' />
            </Box>
          )}

          {latestMessage?.createdAt ? (
            <Typography
              sx={{
                color: 'rgb(102, 119, 129)',
                fontSize: '11px',
                flexShrink: 0,
              }}
            >
              {formatChatMessageDate(latestMessage?.createdAt)}
            </Typography>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
}
