import React from 'react';
import { Box, Button } from '@mui/material';
import ChatSidebar from '@components/Chat/ChatSidebar/ChatSidebar';
import ChatWindow from '@components/Chat/ChatWindow/ChatWindow';
import ChatLoader from '@components/Chat/ChatLoader/ChatLoader';
import { selectChat, setActiveRoom } from '@redux/chatSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function MainChat({
  socket,
  loadingRooms,
  userPersonId,
  onMessageItemClick,
  onSendMessage,
  onDeleteMessage,
  onEditMessage,
  onMessageRead,
}) {
  const dispatch = useDispatch();
  const { activeRoom } = useSelector(selectChat);

  return (
    <>
      {activeRoom?.id ? (
        <Button
          sx={{ display: { sm: 'none' } }}
          onClick={() => {
            dispatch(setActiveRoom(null));
          }}
          variant='text'
        >
          {'<'} Back to all chats
        </Button>
      ) : null}

      <Box
        sx={{
          background: '#fff',
          height: '85vh',
          boxShadow: 'rgba(11, 20, 26, 0.05) 8px 6px 18px 8px',
          border: '1px solid rgba(11, 20, 26, .13)',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loadingRooms ? (
            <ChatLoader />
          ) : (
            <>
              <ChatSidebar
                loadingRooms={loadingRooms}
                userPersonId={userPersonId}
                onMessageItemClick={onMessageItemClick}
              />
              <ChatWindow
                socket={socket}
                onSendMessage={onSendMessage}
                onDeleteMessage={onDeleteMessage}
                onEditMessage={onEditMessage}
                onMessageRead={onMessageRead}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
