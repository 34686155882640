import React from 'react';
import { Box } from '@mui/material';
import ChatMessageBubble from '@components/Chat/ChatMessageBubble/ChatMessageBubble';
import { selectChat, setShouldScroll } from '@redux/chatSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonNameAndInitials } from '@config/functions/chatFunctions';

export default function ChatWindowMessageList({
  onDeleteMessage,
  onEditMessage,
  membersMap,
  onMessageRead,
}) {
  const dispatch = useDispatch();
  const {
    activeRoom,
    shouldScroll,
    messages,
    userMemberId,
    // prettier xxx
  } = useSelector(selectChat);

  const messagesListRef = React.useRef(null);

  const scrollToMessage = (messageId) => {
    const messageElement = document.getElementById(messageId);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      messageElement.classList.add('highlight');
      setTimeout(() => {
        messageElement.classList.remove('highlight');
      }, 3000); // Remove highlight after 3 seconds
    }
  };

  React.useEffect(() => {
    if (shouldScroll && messagesListRef.current) {
      messagesListRef.current.scrollTop = 9999999999;
      dispatch(setShouldScroll(false)); // Reset scroll state after attempting to scroll
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, shouldScroll]); // React on changes to messages or shouldScroll

  // React.useEffect(() => {
  //   if (!activeRoom) return;

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (!entry.isIntersecting) return;

  //       const alreadyRead = activeRoom.roomMessages.some(({ messageReadBy }) =>
  //         messageReadBy?.some(({ readById }) => readById === userMemberId)
  //       );

  //       if (!alreadyRead) {
  //         onMessageRead(entry.target.id);
  //       }
  //     });
  //   });

  //   const messageElements = document.querySelectorAll(
  //     `.r-${activeRoom.id}-message`
  //   );
  //   messageElements.forEach((elem) => observer.observe(elem));

  //   return () => observer.disconnect();
  // }, [activeRoom, userMemberId]);

  return (
    <Box
      ref={messagesListRef}
      sx={{
        flex: 1,
        overflowY: 'auto',
        padding: '10px 16px',
      }}
    >
      {messages
        .filter((m) => m?.roomId === activeRoom?.id)
        .map((m) => {
          const isAuthorCurrent = m?.authorId === userMemberId;
          const { fullName } = getPersonNameAndInitials(
            membersMap[m?.authorId]
          );
          const isRead = false;
          // const isRead = m?.messageReadBy.some(
          //   ({ readById }) => readById === userMemberId
          // );

          return (
            <ChatMessageBubble
              key={m?.id}
              message={m}
              roomId={activeRoom?.id}
              isRead={isRead}
              membersMap={membersMap}
              isGroup={activeRoom?.isGroup}
              userMemberId={userMemberId}
              name={isAuthorCurrent ? null : fullName}
              type={isAuthorCurrent ? 'outgoing' : 'incoming'}
              onEditMessage={(newValue) => onEditMessage(newValue, m?.id)}
              onDeleteMessage={() => onDeleteMessage(m?.id)}
              scrollToMessage={scrollToMessage}
            />
          );
        })}
    </Box>
  );
}
