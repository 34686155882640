import * as React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatNewGroupPopup from '@components/Chat/ChatNewGroupPopup/ChatNewGroupPopup';

export default function ChatWindowHeaderContextMenu({ onNewGroup }) {
  const [newGroupOpen, setNewGroupOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewGroup = () => {
    setNewGroupOpen(true);
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'chat-window-header-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label='message-context'
      >
        <MoreVertIcon fontSize='inherit' />
      </IconButton>
      <Menu
        id='chat-window-header-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        elevation={2}
        sx={{ boxShadow: '0 1px .5px rgba(11, 20, 26, .13)' }}
      >
        <MenuItem onClick={handleNewGroup}>New Group</MenuItem>
      </Menu>

      {newGroupOpen ? (
        <ChatNewGroupPopup
          open={newGroupOpen}
          setOpen={setNewGroupOpen}
          onNewGroup={onNewGroup}
        />
      ) : null}
    </Box>
  );
}
