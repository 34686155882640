import { formsHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  forms: 'forms',
  cloneForm: 'forms/clone',
  formAccessLevel: 'forms/get-access-level',
  questions: 'questions',
  questionOptions: 'question-options',
  questionOptionGroups: 'question-option-groups',
  responses: 'responses',
  responseWithAnswers: 'responses/with-answers',
  import: 'import',
  export: 'export',
  groups: 'groups',
  cloneGroup: 'groups/clone',
  branchingConditions: 'branching-conditions',
  questionGroups: 'question-groups',
  cloneSystemTemplates: 'clone-system-templates',
  recipients: 'recipients',
  generatePersonalityReport: 'generate-personality-report',
  questionGroupScoreRanges: 'question-option-group-score-range',
};

const generatePersonalityReportUrls = getModelUrls(
  formsHost,
  models.generatePersonalityReport,
  false,
  'v1'
);
const cloneSystemTemplatesUrls = getModelUrls(
  formsHost,
  models.cloneSystemTemplates,
  false,
  'v1'
);
const responseWithAnswersUrls = getModelUrls(
  formsHost,
  models.responseWithAnswers,
  false,
  'v1'
);
const recipientsUrls = getModelUrls(formsHost, models.recipients, false, 'v1');
const formAccessLevelUrls = getModelUrls(
  formsHost,
  models.formAccessLevel,
  false,
  'v1'
);
const cloneFormUrls = getModelUrls(formsHost, models.cloneForm, false, 'v1');
const cloneGroupUrls = getModelUrls(formsHost, models.cloneGroup, false, 'v1');
const formsListUrls = getModelUrls(formsHost, models.forms, false, 'v1');
const questionsUrls = getModelUrls(formsHost, models.questions, false, 'v1');
const questionOptionGroupsUrls = getModelUrls(
  formsHost,
  models.questionOptionGroups,
  false,
  'v1'
);
const questionOptionsUrls = getModelUrls(
  formsHost,
  models.questionOptions,
  false,
  'v1'
);
const responsesUrls = getModelUrls(formsHost, models.responses, false, 'v1');
const importUrls = getModelUrls(formsHost, models.import, false, 'v1');
const exportUrls = getModelUrls(formsHost, models.export, false, 'v1');
const groupsUrls = getModelUrls(formsHost, models.groups, false, 'v1');
const branchingConditionsUrls = getModelUrls(
  formsHost,
  models.branchingConditions,
  false,
  'v1'
);
const questionGroupsUrls = getModelUrls(
  formsHost,
  models.questionGroups,
  false,
  'v1'
);
const questionGroupScoreRangesUrls = getModelUrls(
  formsHost,
  models.questionGroupScoreRanges,
  false,
  'v1'
);

const urls = {
  cloneSystemTemplatesUrls,
  formsListUrls,
  cloneFormUrls,
  questionsUrls,
  questionOptionsUrls,
  questionOptionGroupsUrls,
  responsesUrls,
  responseWithAnswersUrls,
  importUrls,
  exportUrls,
  groupsUrls,
  branchingConditionsUrls,
  cloneGroupUrls,
  questionGroupsUrls,
  recipientsUrls,
  formAccessLevelUrls,
  questionGroupScoreRangesUrls,
  generatePersonalityReportUrls,
};

export default urls;
