import React from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ChatMessageQuote from '@components/Chat/ChatMessageQuote/ChatMessageQuote';
import { getPersonNameAndInitials } from '@config/functions/chatFunctions';
import ChatFileDisplayBox from '@components/Chat/ChatFileDisplayBox/ChatFileDisplayBox';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';
import { nodeDriveUrls } from '@config/routes';
import { createRecord } from '@config/functions/requests';

const { filesUrls } = nodeDriveUrls;

export default function ChatWindowInputBox({
  onSendMessage,
  socket,
  activeRoom,
  userMemberId,
  replyTo,
  membersMap,
}) {
  const [notify] = useToast();
  const fileInputRef = React.useRef(null);
  const user = useSelector(selectProfile);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [newMessage, setNewMessage] = React.useState('');

  const handleButtonClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDeselectFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  const replyToAuthorId = replyTo?.authorId;
  const replyToAuthor = membersMap[replyToAuthorId];
  const replyToAuthorName =
    replyToAuthorId === userMemberId
      ? 'You'
      : getPersonNameAndInitials(replyToAuthor)?.fullName;

  let typingTimeout;

  const sendMessage = () => {
    const trimmedMessage = newMessage.trim();

    if (trimmedMessage || selectedFile) {
      setSubmitting(true);

      const clearForm = () => {
        setNewMessage('');
        handleDeselectFile();
      };

      if (!selectedFile) {
        onSendMessage({ message: trimmedMessage });
        setSubmitting(false);
        clearForm();
        return;
      }

      const formData = new FormData();
      formData.append('anonymous_can_see_it', true);
      formData.append('file', selectedFile);

      createRecord({
        values: formData,
        url: filesUrls.list(),
        token: user?.token,
        actAs: user?.actAs,
      })
        .then(({ data }) => {
          onSendMessage({
            message: trimmedMessage,
            attachmentId: data?.id,
          });
          clearForm();
        })
        .catch((error) => {
          notify('Failed to send attachment. Please retry later.', {
            type: 'ERROR',
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleKeyInput = () => {
    clearTimeout(typingTimeout);

    const mixin = { roomId: activeRoom?.id, authorId: userMemberId };

    // Notify the server that this user is typing
    socket.emit('typing', { ...mixin, isTyping: true });

    // Set a timeout to stop typing
    typingTimeout = setTimeout(() => {
      socket.emit('typing', { ...mixin, isTyping: false });
    }, 2600);
  };

  return (
    <Box>
      {replyTo?.id ? (
        <Box
          sx={{
            width: '100%',
            background: '#f0f2f5',
            paddingTop: '10px',
            paddingInline: '24px',
          }}
        >
          <ChatMessageQuote
            file={replyTo?.details?.file}
            content={replyTo?.content}
            author={replyToAuthorName}
            showCancelButton
          />
        </Box>
      ) : null}

      {selectedFile && (
        <ChatFileDisplayBox
          fileName={selectedFile?.name}
          fileSize={selectedFile?.size}
          onDeselectFile={handleDeselectFile}
        />
      )}

      <Box
        sx={{
          background: '#F0F2F5',
          minHeight: '62px',
          maxHeight: '176px',
          display: 'flex',
          alignItems: 'end',
          padding: '10px 16px',
        }}
      >
        <IconButton aria-label='emojis'>
          <SentimentSatisfiedAltIcon fontSize='inherit' />
        </IconButton>

        <input
          type='file'
          ref={fileInputRef}
          onChange={(e) => {
            if (e.target.files?.length) {
              const [file] = e.target.files;
              setSelectedFile(file);
            }
          }}
          style={{ display: 'none' }}
        />

        <IconButton
          aria-label='upload'
          disabled={isSubmitting}
          onClick={handleButtonClick}
        >
          <AddIcon fontSize='inherit' />
        </IconButton>

        <TextField
          fullWidth
          variant='outlined'
          placeholder='Type a message'
          sx={{ mx: '10px', background: '#fff' }}
          multiline
          maxRows={4}
          size='small'
          value={newMessage}
          onInput={handleKeyInput}
          onKeyDown={handleKeyDown}
          disabled={isSubmitting}
          onChange={(e) => setNewMessage(e.target.value)}
        />

        <IconButton
          aria-label='send'
          disabled={isSubmitting}
          onClick={sendMessage}
        >
          <SendIcon fontSize='inherit' />
        </IconButton>
      </Box>
    </Box>
  );
}
