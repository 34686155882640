import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';
import { updateRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { chatUrls } from '@config/routes';
import useToast from '@hooks/useToast';

const { roomsUrls } = chatUrls;

export default function ChatMessageEditPopup({
  open,
  setOpen,
  roomId,
  initialName,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);
  const [isSaving, setSaving] = React.useState(false);
  const [newName, setNewName] = React.useState(initialName ?? '');

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setSaving(true);

      await updateRecord({
        values: {
          name: newName,
        },
        url: roomsUrls.detail(roomId),
        token: user.token,
        actAs: user?.actAs,
      });

      handleClose();
      notify('Name updated', {
        type: 'SUCCESS',
      });
    } catch (error) {
      notify('There was an error, please retry later', {
        type: 'ERROR',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Edit group name</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <TextField
            fullWidth
            variant='outlined'
            placeholder='Enter group name'
            sx={{ background: '#fff' }}
            multiline
            maxRows={4}
            size='small'
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cancel
        </Button>
        <Button disabled={isSaving} onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
