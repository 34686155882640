import React from 'react';
import { io } from 'socket.io-client';
import {
  showNotification,
  truncateContent,
  getNotificationAudio,
  sortMessages,
  getPersonNameAndInitials,
} from '@config/functions/chatFunctions';
import { useSelector, useDispatch } from 'react-redux';
import {
  addRooms,
  updateRoom,
  RoomStatus,
  addMessage,
  selectChat,
  setActiveRoom,
  setActiveRoomState,
  setMessages,
  removeRoom,
  setRoomTypingStatus,
  setShouldScroll,
  setUserMemberId,
  updateLatestRoomMessage,
  updateMessage,
  setActiveRoomMembersMap,
  setGroupChatSettingsOpen,
  setPrivateChatSettingsOpen,
} from '@redux/chatSlice';

const CHAT_HOST = process.env.REACT_APP_CHAT_HOST ?? '';

export default function useChatSocket({ accessToken, userPersonId } = {}) {
  const dispatch = useDispatch();
  const [audio] = React.useState(getNotificationAudio());
  const {
    activeRoom,
    userMemberId,
    rooms,
    // prettier xxx
  } = useSelector(selectChat);

  const socket = React.useMemo(() => {
    return io(CHAT_HOST, {
      secure: true,
      path: '/api/v1/ws/chat/',
      withCredentials: true,
      transports: ['websocket'],
      auth: {
        token: `Bearer ${accessToken}`,
      },
    });
  }, [accessToken]);

  React.useEffect(() => {
    const onConnect = () => {
      console.log('Connected to the server.');
    };

    const onRoomRemove = ({ roomId }) => {
      dispatch(removeRoom({ roomId }));
    };

    const onRoomUpdate = ({ room }) => {
      dispatch(updateRoom({ roomId: room?.id, updates: room }));

      if (room?.id === activeRoom?.id) {
        dispatch(setActiveRoom(room));
      }
    };

    const onNewRoom = ({ rooms }) => {
      const filteredRooms = rooms.filter((room) =>
        room?.roomMembers?.some(({ memberId }) => memberId === userPersonId)
      );

      dispatch(addRooms(filteredRooms));
    };

    const onMessageReadInRoom = ({ room }) => {
      dispatch(updateRoom({ roomId: room?.id, updates: room }));

      if (room?.id === activeRoom?.id) {
        dispatch(setActiveRoom(room));
      }
    };

    const onRoomMembersCreate = ({ room }) => {
      // Check if the user is a member of the room
      const isMember = room?.roomMembers?.some(
        ({ memberId }) => memberId === userPersonId
      );

      // If the user is not a member of the room, exit the function
      if (!isMember) {
        return;
      }

      // Find if the room already exists in the rooms list
      const found = rooms.find((r) => r.id === room?.id);

      if (found) {
        // If the room is found, update the room's information
        dispatch(updateRoom({ roomId: room?.id, updates: room }));

        // If the updated room is the currently active room, set it as active
        if (room?.id === activeRoom?.id) {
          dispatch(setActiveRoom(room));
        }
      } else {
        // If the room is not found, add it to the rooms list
        dispatch(addRooms([room]));
      }
    };

    const onRoomMemberRemoved = ({ roomMember }) => {
      // Find the room object corresponding to the member's roomId
      const room = rooms.find((r) => r.id === roomMember?.roomId);

      // Check if the removed member is the current user
      const isCurrentUser = roomMember?.memberId === userPersonId;

      // If the room exists
      if (room) {
        // Filter out the removed member from the room's members array
        const updatedRoomMembers = room.roomMembers.filter(
          (member) => member.id !== roomMember.id
        );
        // Create a new room object with the updated members array
        const updatedRoom = { ...room, roomMembers: updatedRoomMembers };

        // Check if the updated room is the currently active room
        if (room.id === activeRoom?.id) {
          if (isCurrentUser) {
            // If the removed member is the current user, set the active room to null
            dispatch(setActiveRoom(null));
          } else {
            // Dispatch an action to update the active room in the state
            dispatch(setActiveRoom(updatedRoom));
          }
        }

        if (isCurrentUser) {
          // If the removed member is the current user, remove the room from the state
          dispatch(removeRoom({ roomId: room.id }));
        } else {
          // Dispatch an action to update the room in the state with the new members
          dispatch(updateRoom({ roomId: room.id, updates: updatedRoom }));
        }
      }
    };

    const onRoomHistoryEvent = ({ room }) => {
      dispatch(setActiveRoom(room));
      dispatch(setGroupChatSettingsOpen(false));
      dispatch(setPrivateChatSettingsOpen(false));
      dispatch(
        setActiveRoomState({ status: RoomStatus.Loaded, roomId: room?.id })
      );

      const membersMap = room.roomMembers.reduce((acc, member) => {
        if (member?.id) {
          acc[member.id] = member.details?.person;
        }
        return acc;
      }, {});

      dispatch(setActiveRoomMembersMap(membersMap));

      // Create a shallow copy of roomMessages before sorting to avoid mutating the original array
      const sortedMessages = Array.isArray(room?.roomMessages)
        ? [...room.roomMessages].sort(sortMessages)
        : [];

      dispatch(setMessages(sortedMessages));

      // Attempt to find the user member from room members
      const userMember = room?.roomMembers?.find(
        (member) => member.memberId === userPersonId
      );

      // Update user member ID state if a valid member is found
      if (userMember?.id) {
        dispatch(setUserMemberId(userMember.id));
      }

      dispatch(setShouldScroll(true));
    };

    const onReceiveMessage = ({ message }) => {
      dispatch(addMessage(message));

      dispatch(updateLatestRoomMessage(message));

      if (message?.roomId === activeRoom?.id) {
        dispatch(setShouldScroll(true));
      }
    };

    const onMessageEdited = ({ message }) => {
      dispatch(
        updateMessage({ messageId: message?.id, updates: { ...message } })
      );
      dispatch(setShouldScroll(false));
    };

    const onMessageDeleted = ({ messageId }) => {
      dispatch(updateMessage({ messageId, updates: { deleted: true } }));
      dispatch(setShouldScroll(false));
    };

    const onUserTyping = ({ isTyping, roomId, authorId }) => {
      dispatch(setRoomTypingStatus({ roomId, isTyping, authorId }));
    };

    const onMessageNotification = ({ message }) => {
      // Check if the message object exists
      if (!message) return;

      // Determine if the message is from the current user
      const isCurrentUser = message.author?.memberId === userPersonId;

      // If the message is from the current user, no notification is needed
      if (isCurrentUser) {
        return;
      }

      // Find the room associated with the message
      const foundRoom = rooms.find((room) => room?.id === message?.roomId);

      // If the room is not found, no notification is needed
      if (!foundRoom) {
        return;
      }

      // Check if the current user is a member of the room
      const isMember = foundRoom?.roomMembers?.some(
        ({ memberId }) => memberId === userPersonId
      );

      // If the user is not a member of the room, no notification is needed
      if (!isMember) {
        return;
      }

      // If there is an audio object, attempt to play the notification sound
      if (audio) {
        audio.play().catch((error) => {
          console.error('Failed to play the audio:', error);
        });
      }

      // Check if the message is from the active room
      const isSameRoom = message.roomId === activeRoom?.id;

      // If the message is from the active room, no notification is needed
      if (isSameRoom) {
        return;
      }

      // Determine if the room is a group
      const isGroup = message.room?.isGroup;
      // Get the group name if it is a group, otherwise an empty string
      const groupName = isGroup ? message.room.name : '';
      // Extract the author's full name for the notification
      const { fullName: authorName } = getPersonNameAndInitials(
        message.author?.details?.person
      );

      // Create the notification title
      const notificationTitle = `${authorName} ${
        groupName ? `in ${groupName}` : ''
      }`;

      // Determine the message content based on the presence of an attachment or text content
      const messageContent = message?.attachmentId
        ? message?.content
          ? `[Attachment] ${message.content}` // Both attachment and content are present
          : '[Attachment]' // Only attachment is present
        : message?.content; // Only content is present or no message

      // Set the body to the determined message content or default to '[File]'
      const body = messageContent || '[Attachment]';

      // Display the notification with the title and truncated message content
      showNotification(notificationTitle, {
        body: truncateContent(body),
      });
    };

    const onDisconnect = () => {
      console.log('Disconnected from the server.');
    };

    const onConnectError = (error) => {
      if (!socket.active) {
        console.log('Connection error:', error?.message);
      }
    };

    const onError = (error) => {
      console.log('Server Error:', error);
    };

    // Registering socket event listeners
    socket.on('connect', onConnect);
    socket.on('roomRemoved', onRoomRemove);
    socket.on('messageReadInRoom', onMessageReadInRoom);
    socket.on('roomsCreated', onNewRoom);
    socket.on('roomModified', onRoomUpdate);
    socket.on('roomHistory', onRoomHistoryEvent);
    socket.on('roomMembersCreated', onRoomMembersCreate);
    socket.on('roomMemberRemoved', onRoomMemberRemoved);
    socket.on('receiveMessage', onReceiveMessage);
    socket.on('messageEdited', onMessageEdited);
    socket.on('messageDeleted', onMessageDeleted);
    socket.on('userTyping', onUserTyping);
    socket.on('messageNotification', onMessageNotification);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onConnectError);
    socket.on('error', onError);

    // Cleanup function to remove event listeners
    return () => {
      socket.off('connect', onConnect);
      socket.off('roomRemoved', onRoomRemove);
      socket.off('messageReadInRoom', onMessageReadInRoom);
      socket.off('roomsCreated', onNewRoom);
      socket.off('roomModified', onRoomUpdate);
      socket.off('roomHistory', onRoomHistoryEvent);
      socket.off('roomMembersCreated', onRoomMembersCreate);
      socket.off('roomMemberRemoved', onRoomMemberRemoved);
      socket.off('receiveMessage', onReceiveMessage);
      socket.off('messageEdited', onMessageEdited);
      socket.off('messageDeleted', onMessageDeleted);
      socket.off('userTyping', onUserTyping);
      socket.off('messageNotification', onMessageNotification);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);
      socket.off('error', onError);
    };
  }, [socket, activeRoom, userPersonId, audio, userMemberId, rooms, dispatch]);

  return {
    socket,
  };
}
