import * as Yup from 'yup';
import {
  fieldType,
  lmsHomeworkTypes,
  microservices,
  lmsCourseAccessLevel,
} from '@config/constants';
import { lmsUrls, wikiUrls, crmUrls, formsUrls } from '@config/routes';
import getDropdownForm from '@dropdown-forms/getDropdownForm';

const { booksUrls, sectionsUrls, chaptersUrls } = wikiUrls;
const { personsUrls } = crmUrls;
const { formsListUrls } = formsUrls;
const {
  homeworksUrls,
  coursesUrls,
  instructorsUrls,
  modulesUrls,
  studentsUrls,
  lessonsUrls,
  coachesUrls,
  mentorsUrls,
  teamsUrls,
  categoriesUrls,
} = lmsUrls;

export const universityMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/universities/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const facultyMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    width: 250,
    type: fieldType.LINK,
    to: '/faculties/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onAccordionForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const facultyCourseMeta = ({ data } = {}) => [
  {
    field: 'courseId',
    headerName: 'Course',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: coursesUrls.list,
    requestKey: `${data?.id}-faculty-course`,
    validate: Yup.mixed(),
    initialValue: data?.course
      ? {
          id: data.courseId,
          label: `${data.course?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    getHref: () => `/courses/`,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const facultyInstructorMeta = ({ data } = {}) => [
  {
    field: 'instructorId',
    headerName: 'Instructor',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: instructorsUrls.list,
    requestKey: `${data?.id}-faculty-instructor`,
    validate: Yup.mixed(),
    initialValue: data?.instructor
      ? {
          id: data.instructorId,
          label: `${data.instructor?.alias}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.alias}`,
    }),
    getHref: () => `/instructors/`,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const homeworkMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Homework Title',
    to: '/homeworks/',
    onForm: true,
    onDetailCard: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    width: 180,
    field: 'type',
    headerName: 'Type',
    type: fieldType.SELECT,
    editable: true,
    options: lmsHomeworkTypes,
    initialValue: lmsHomeworkTypes[0]?.value,
    value: data ? data.type : null,
    validate: Yup.string().required(),
    onForm: true,
    onDetailCard: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    onDetailCard: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'url',
    headerName: 'URL',
    type: fieldType.Text,
    width: 180,
    editable: true,
    value: data?.url,
    initialValue: '',
    onForm: true,
    validate: Yup.string().url().nullable(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const teamMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 180,
    type: fieldType.TEXT,
    to: '/teams/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const courseMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.LINK,
    to: '/courses/',
    width: 290,
    editable: true,
    value: data?.name,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    type: fieldType.TEXT,
    width: 390,
    editable: true,
    value: data?.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'homeworkId',
    headerName: 'Homework',
    width: 180,
    editable: true,
    onDetailCard: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: homeworksUrls.list,
    requestKey: `${data?.id}-course-homework`,
    validate: Yup.mixed(),
    initialValue: data?.homeworkId
      ? {
          id: data?.homeworkId,
          label: `${data?.homework?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    onForm: true,
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-course-homework`]),
      Form: getDropdownForm({
        microservice: microservices.LMS.name,
        model: microservices.LMS.models.homework,
      }),
    }),
  },
  {
    field: 'wikiBookId',
    headerName: 'Wiki Book',
    width: 280,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: booksUrls.list,
    requestKey: `${data?.id}-course-wiki-book`,
    validate: Yup.mixed(),
    initialValue: data?.details?.wikiBookId
      ? {
          id: data.wikiBookId,
          label: `${data?.details?.wikiBookId?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-course-wiki-book`]),
      Form: getDropdownForm({
        microservice: microservices.WIKI.name,
        model: microservices.WIKI.models.books,
      }),
    }),
  },
  {
    field: 'categoryId',
    headerName: 'Category',
    width: 180,
    editable: true,
    onDetailCard: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: categoriesUrls.list,
    requestKey: `${data?.id}-course-category`,
    validate: Yup.mixed(),
    initialValue: data?.category
      ? {
          id: data?.categoryId,
          label: data?.category?.name ?? '',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    onForm: true,
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-course-category`]),
      Form: getDropdownForm({
        microservice: microservices.LMS.name,
        model: microservices.LMS.models.category,
      }),
    }),
  },
  {
    width: 180,
    field: 'accessLevel',
    headerName: 'Access level',
    type: fieldType.SELECT,
    editable: true,
    options: lmsCourseAccessLevel,
    initialValue: lmsCourseAccessLevel[0]?.value,
    value: data ? data.accessLevel : null,
    validate: Yup.string(),
    onForm: true,
  },
  {
    field: 'isPublished',
    headerName: 'Published',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isPublished : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'thumbnail',
    headerName: 'Thumbnail',
    width: 390,
    editable: true,
    value: data?.thumbnail ?? '',
    initialValue: '',
    validate: Yup.mixed(),
    onForm: true,
    getHref: (r) => r.thumbnail,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
    required: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const lessonMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.LINK,
    to: '/lessons/',
    width: 280,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(150).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    type: fieldType.TEXT,
    width: 380,
    editable: true,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'homeworkId',
    headerName: 'Homework',
    width: 280,
    editable: true,
    onForm: true,
    onDetailCard: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: homeworksUrls.list,
    requestKey: `${data?.id}-lesson-homework`,
    validate: Yup.mixed(),
    initialValue: data?.homework
      ? {
          id: data?.homework,
          label: `${data?.homework?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-lesson-homework`]),
      Form: getDropdownForm({
        microservice: microservices.LMS.name,
        model: microservices.LMS.models.homework,
      }),
    }),
  },
  {
    field: 'thumbnail',
    headerName: 'Thumbnail',
    width: 280,
    editable: true,
    value: data?.thumbnail ?? '',
    initialValue: '',
    validate: Yup.mixed(),
    getHref: (r) => r.thumbnail,
    linkInNewTab: true,
    onForm: true,
    type: fieldType.UPLOAD,
  },
  {
    field: 'presentation',
    headerName: 'Presentation',
    width: 280,
    editable: true,
    value: data?.presentation ?? '',
    initialValue: '',
    validate: Yup.mixed(),
    onForm: true,
    getHref: (r) => r.presentation,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
    required: true,
  },
  {
    field: 'video',
    headerName: 'Video',
    width: 280,
    editable: true,
    value: data?.video ?? '',
    initialValue: '',
    validate: Yup.mixed(),
    onForm: true,
    getHref: (r) => r.video,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
    required: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const moduleMeta = ({ data, queryClient } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    type: fieldType.LINK,
    to: '/modules/',
    width: 280,
    editable: true,
    value: data && data.name,
    initialValue: '',
    validate: Yup.string().max(200).required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    type: fieldType.TEXT,
    width: 380,
    editable: true,
    value: data && data.description,
    initialValue: '',
    validate: Yup.string().max(2500),
  },
  {
    field: 'homeworkId',
    headerName: 'Homework',
    width: 280,
    editable: true,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: homeworksUrls.list,
    requestKey: `${data?.id}-module-homework`,
    validate: Yup.mixed(),
    initialValue: data?.homework
      ? {
          id: data?.homeworkId,
          label: `${data?.homework?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-course-homework`]),
      Form: getDropdownForm({
        microservice: microservices.LMS.name,
        model: microservices.LMS.models.homework,
      }),
    }),
  },
  {
    field: 'thumbnail',
    headerName: 'Thumbnail',
    width: 390,
    editable: true,
    value: data ? data.thumbnail : null,
    initialValue: '',
    validate: Yup.mixed(),
    onForm: true,
    getHref: (r) => r.thumbnail,
    linkInNewTab: true,
    type: fieldType.UPLOAD,
  },
  {
    field: 'wikiChapterId',
    headerName: 'Wiki Chapter',
    width: 280,
    editable: false,
    onForm: false,
    hideColumn: true,
    onDetailCard: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: chaptersUrls.list,
    requestKey: `${data?.id}-module-wiki-chapter`,
    validate: Yup.mixed(),
    initialValue: data?.details?.wikiChapterId
      ? {
          id: data.wikiChapterId,
          label: `${data.details.wikiChapterId?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const skillMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    onForm: true,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    width: 200,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const studentMeta = ({ data, queryClient } = {}) => [
  {
    field: 'alias',
    headerName: 'Alias',
    onForm: true,
    width: 180,
    type: fieldType.TEXT,
    editable: false,
    to: '/students/',
    value: data ? data.alias : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'personId',
    headerName: 'Person',
    width: 390,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-student-person`,
    validate: Yup.mixed().required('person is a required field'),
    initialValue: data?.details?.personId
      ? {
          id: data.person,
          label: `${data.details.personId?.email}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.email}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-student-person`]),
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const instructorMeta = ({ data, queryClient } = {}) => [
  {
    field: 'alias',
    headerName: 'Alias',
    onForm: true,
    width: 180,
    type: fieldType.TEXT,
    editable: false,
    value: data ? data.alias : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'personId',
    headerName: 'Person',
    width: 390,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-instructor-person`,
    validate: Yup.mixed().required('person is a required field'),
    initialValue: data?.details?.personId
      ? {
          id: data.person,
          label: `${data.details.personId?.email}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.email}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-instructor-person`]),
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const coachMeta = ({ data, queryClient } = {}) => [
  {
    field: 'personId',
    headerName: 'Person',
    width: 390,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-coach-person`,
    validate: Yup.mixed(),
    initialValue: data?.details?.personId
      ? {
          id: data.person,
          label: `${data?.details?.personId?.email}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.email}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-coach-person`]),
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const mentorMeta = ({ data, queryClient } = {}) => [
  {
    field: 'personId',
    headerName: 'Person',
    width: 390,
    editable: false,
    onForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: personsUrls.list,
    requestKey: `${data?.id}-mentor-persons`,
    validate: Yup.mixed(),
    initialValue: data?.details?.personId
      ? {
          id: data.person,
          label: data.details.personId?.email ?? '...',
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.email ?? '...',
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-mentor-person`]),
      Form: getDropdownForm({
        microservice: microservices.CRM.name,
        model: microservices.CRM.models.persons,
      }),
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const courseModuleMeta = ({
  data,
  nextOrder,
  queryClient,
  wikiBook,
} = {}) => [
  {
    field: 'sequence',
    headerName: 'Sequence',
    onAccordionForm: true,
    width: 140,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.sequence : null,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
  },
  {
    field: 'moduleId',
    headerName: 'Module',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: modulesUrls.list,
    requestKey: `${data?.id}-course-module`,
    validate: Yup.mixed().required('module is a required field'),
    to: '/course-module/',
    initialValue: data?.module
      ? {
          id: data.moduleId,
          label: `${data.module?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
  },
  {
    field: 'wikiChapterId',
    headerName: 'Wiki Chapter',
    width: 280,
    editable: true,
    onForm: true,
    onDetailCard: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: chaptersUrls.list,
    requestKey: `${data?.id}-module-wiki-chapter`,
    validate: Yup.mixed(),
    urlParams: wikiBook
      ? `&book=${wikiBook}`
      : `&book=${data?.course?.wikiBookId}`,
    initialValue: data?.details?.wikiChapterId
      ? {
          id: data.wikiChapterId,
          label: `${data.details.wikiChapterId?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    getDropdownForm: () => ({
      refetch: () =>
        queryClient.invalidateQueries([`${data?.id}-module-chapter`]),
      Form: getDropdownForm({
        microservice: microservices.WIKI.name,
        model: microservices.WIKI.models.chapter,
      }),
    }),
  },
  {
    field: 'isRequired',
    headerName: 'Required',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isRequired : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const courseTeamMeta = ({ data } = {}) => [
  {
    field: 'teamId',
    headerName: 'Team',
    getHref: (r) => `/teams/${r.teamId?.id}`,
    width: 390,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: teamsUrls.list,
    requestKey: `${data?.id}-course-team`,
    validate: Yup.mixed(),
    initialValue: data?.team
      ? {
          id: data.teamId,
          label: `${data.team?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const moduleLessonMeta = ({ data, nextOrder, wikiChapterId } = {}) => [
  {
    field: 'sequence',
    headerName: 'Sequence',
    onAccordionForm: true,
    width: 180,
    type: fieldType.TEXT,
    editable: true,
    value: data ? data.sequence : null,
    initialValue: nextOrder + 1,
    validate: Yup.number().required(),
  },
  {
    field: 'lessonId',
    headerName: 'Lesson',
    width: 390,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: lessonsUrls.list,
    requestKey: `${data?.id}-module-lesson`,
    validate: Yup.mixed().required('lesson is a required field'),
    initialValue: data?.lesson
      ? {
          id: data.lessonId,
          label: `${data.lesson?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
  },
  {
    field: 'wikiSectionId',
    headerName: 'Wiki Section',
    width: 290,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: sectionsUrls.list,
    requestKey: `${data?.id}-lesson-progress-section`,
    validate: Yup.mixed(),
    urlParams: wikiChapterId
      ? `&chapter=${wikiChapterId}`
      : `&book=${data?.course?.wikiBookId}`,
    initialValue: data?.wikiSectionId
      ? {
          id: data.wikiSectionId,
          label: `${data?.details?.wikiSectionId?.title}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.title}`,
    }),
  },
  {
    field: 'isRequired',
    headerName: 'Required',
    type: fieldType.BOOLEAN,
    onAccordionForm: true,
    width: 140,
    editable: true,
    value: data ? data.isRequired : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const lessonProgressMeta = ({ data } = {}) => [
  {
    field: 'studentId',
    headerName: 'Student',
    width: 290,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: studentsUrls.list,
    requestKey: `${data?.id}-lesson-progress-student`,
    validate: Yup.mixed().required('student is a required field'),
    initialValue: data?.student
      ? {
          id: data?.student,
          label: `${data.student?.alias}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.alias}`,
    }),
  },
  {
    field: 'wikiSectionId',
    headerName: 'Wiki Section',
    width: 290,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: sectionsUrls.list,
    requestKey: `${data?.id}-lesson-progress-section`,
    validate: Yup.mixed(),
    initialValue: data?.wikiSectionId
      ? {
          id: data.wikiSectionId,
          label: `${data?.details?.wikiSectionId?.title}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.title}`,
    }),
  },
  {
    field: 'presentationProgress',
    headerName: 'Presentation Progress',
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.presentationProgress : null,
    initialValue: '',
    validate: Yup.number().required(),
  },
  {
    field: 'videoProgress',
    headerName: 'Video Progress',
    onAccordionForm: true,
    width: 180,
    editable: true,
    value: data ? data.videoProgress : null,
    initialValue: '',
    validate: Yup.number().required(),
    type: fieldType.UPLOAD,
  },

  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const lessonInstructorMeta = ({ data } = {}) => [
  {
    field: 'instructorId',
    headerName: 'Instructor',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: instructorsUrls.list,
    requestKey: `${data?.id}-lesson-instructor`,
    validate: Yup.mixed().required('instructor is a required field'),
    initialValue: data?.instructor
      ? {
          id: data.instructorId,
          label: `${data.instructor?.alias}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.alias}`,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const lessonCoachMeta = ({ data } = {}) => [
  {
    field: 'coachId',
    headerName: 'Coach',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: coachesUrls.list,
    requestKey: `${data?.id}-lesson-coach`,
    validate: Yup.mixed(),
    initialValue: data?.coachId
      ? {
          id: data.coachId,
          label: data?.coach?.details?.personId?.email,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.details?.personId?.email,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const homeworkProgressMeta = ({ data, queryClient } = {}) => [
  {
    field: 'homeworkProgress',
    headerName: 'Homework Progress',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: formsListUrls.list,
    requestKey: `${data?.id}-homework-progress-form`,
    validate: Yup.mixed(),
    initialValue: data?.details?.homeworkProgress
      ? {
          id: data.homeworkProgress,
          label: `${data.details.homeworkProgress?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
  },
  {
    field: 'studentId',
    headerName: 'Student',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: studentsUrls.list,
    requestKey: `${data?.id}-homework-progress-student`,
    validate: Yup.mixed(),
    initialValue: data?.details?.student
      ? {
          id: data.student,
          label: `${data.details.student?.alias}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.alias}`,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const courseMentorMeta = ({ data } = {}) => [
  {
    field: 'mentorId',
    headerName: 'Mentor',
    width: 390,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: mentorsUrls.list,
    requestKey: `${data?.id}-course-mentor`,
    validate: Yup.mixed(),
    initialValue: data?.mentorId
      ? {
          id: data.mentorId,
          label: `${data.mentor?.details?.personId?.email}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.details?.personId?.email,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

// export const studentMentorMeta = ({ data } = {}) => [
//   {
//     field: 'mentorId',
//     headerName: 'Mentor',
//     width: 390,
//     editable: true,
//     onAccordionForm: true,
//     type: fieldType.AUTOCOMPLETE_V2,
//     fetchUrl: mentorsUrls.list,
//     requestKey: `${data?.id}-lesson-mentor`,
//     validate: Yup.mixed(),
//     initialValue: data?.mentorId
//       ? {
//           id: data.mentorId,
//           label: `${data.mentor?.details?.personId?.email}`,
//         }
//       : null,
//     renderRow: (row) => ({
//       id: row?.id,
//       label: row?.details?.personId?.email,
//     }),
//   },
//   {
//     field: 'createdAt',
//     headerName: 'Date created',
//     width: 180,
//     value: data?.createdAt ? new Date(data.createdAt) : null,
//     onForm: false,
//     editable: false,
//     type: fieldType.DATE_TIME,
//   },
// ];

export const teamMemberMeta = ({ data } = {}) => [
  {
    field: 'studentId',
    headerName: 'Student',
    width: 390,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: studentsUrls.list,
    requestKey: `${data?.id}-team-member-student`,
    validate: Yup.mixed(),
    initialValue: data?.student
      ? {
          id: data.studentId,
          label: `${data.student?.alias}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.alias}`,
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
export const lessonReferencesMeta = ({ data } = {}) => [
  {
    field: 'notes',
    headerName: 'Notes',
    onAccordionForm: true,
    editable: true,
    initialValue: '',
    width: 390,
    value: data && data.notes,
    to: '/lesson-references/',
    type: fieldType.MULTILINE,
    validate: Yup.string().required(),
  },
  {
    field: 'attachmentURL',
    headerName: 'Attachment',
    width: 1075,
    editable: true,
    value: data && data.attachmentURL,
    initialValue: '',
    validate: Yup.string().url(),
    getHref: (r) => r.attachmentURL,
    type: fieldType.UPLOAD,
    linkInNewTab: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const categoryMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 250,
    type: fieldType.TEXT,
    to: '/categories/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
