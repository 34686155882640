import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { sortMessages } from '@config/functions/chatFunctions';

export const RoomStatus = {
  Idle: 'idle',
  Loading: 'loading',
  Loaded: 'loaded',
  Error: 'error',
};

const initialState = {
  activeRoom: null,
  replyTo: null,
  activeRoomMembersMap: {},
  activeRoomState: { status: RoomStatus.Idle, roomId: null },
  userMemberId: null,
  shouldScroll: false,
  rooms: [],
  messages: [],
  typingStatus: {},
  groupChatSettingsOpen: false,
  privateChatSettingsOpen: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveRoom: (state, action) => {
      state.activeRoom = action.payload;
    },
    setGroupChatSettingsOpen: (state, action) => {
      state.groupChatSettingsOpen = action.payload;
    },
    setPrivateChatSettingsOpen: (state, action) => {
      state.privateChatSettingsOpen = action.payload;
    },
    setReplyTo: (state, action) => {
      state.replyTo = action.payload;
    },
    setActiveRoomMembersMap: (state, action) => {
      state.activeRoomMembersMap = action.payload;
    },
    setActiveRoomState: (state, action) => {
      state.activeRoomState = action.payload;
    },
    setUserMemberId: (state, action) => {
      state.userMemberId = action.payload;
    },
    setShouldScroll: (state, action) => {
      state.shouldScroll = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    addRooms: (state, action) => {
      state.rooms = [...state.rooms, ...action.payload];
    },
    updateRoom: (state, action) => {
      const clonedRooms = _.cloneDeep(state.rooms);

      state.rooms = [
        ...clonedRooms.map((room) => {
          if (room?.id === action.payload?.roomId) {
            return { ...room, ...action.payload?.updates };
          }
          return room;
        }),
      ];
    },
    removeRoom: (state, action) => {
      const roomId = action.payload.roomId;
      const clonedRooms = _.cloneDeep(state.rooms);
      const updatedRooms = [...clonedRooms.filter(({ id }) => id !== roomId)];

      state.rooms = updatedRooms;

      if (state?.activeRoom?.id === roomId) {
        state.activeRoom = null;
      }
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    updateMessage: (state, action) => {
      state.messages = state.messages
        .map((message) => {
          if (message?.id === action.payload?.messageId) {
            return { ...message, ...action.payload?.updates };
          }
          return message;
        })
        .sort(sortMessages);

      state.rooms = state.rooms.map((room) => {
        return {
          ...room,
          roomMessages: room.roomMessages.map((message: any) => {
            if (message?.id === action.payload?.messageId) {
              return { ...message, ...action.payload?.updates };
            }
            return message;
          }),
        };
      });
    },
    updateLatestRoomMessage: (state, action) => {
      const clonedRooms = _.cloneDeep(state.rooms);

      const index = clonedRooms.findIndex(
        (room) => room.id === action.payload.roomId
      );

      if (index !== -1) {
        clonedRooms[index].roomMessages = [action.payload];
      }

      state.rooms = clonedRooms;
    },
    setTypingStatus: (state, action) => {
      state.typingStatus = action.payload;
    },
    setRoomTypingStatus: (state, action) => {
      const { roomId, isTyping, authorId } = action.payload;
      state.typingStatus[roomId] = { isTyping, authorId };
    },
  },
});

export const {
  updateRoom,
  setActiveRoom,
  setReplyTo,
  setActiveRoomState,
  setUserMemberId,
  setShouldScroll,
  setRooms,
  addRooms,
  removeRoom,
  setMessages,
  addMessage,
  updateMessage,
  setTypingStatus,
  setRoomTypingStatus,
  updateLatestRoomMessage,
  setActiveRoomMembersMap,
  setGroupChatSettingsOpen,
  setPrivateChatSettingsOpen,
} = chatSlice.actions;

export const selectChat = (state) => state.chat;

export default chatSlice.reducer;
