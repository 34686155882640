import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { crmUrls } from '@config/routes';
import * as Yup from 'yup';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { AutocompleteFieldV2 } from '@components/Inputs';
import RenderUiPrefix from '@components/core/RenderUiPrefix';

const { pipelinesUrls, createBulkOpportunitiesUrls } = crmUrls;

export default function CreateCompanyOpportunityPopup({
  open,
  setOpen,
  selectedRows,
  setSelectedRows,
  labels,
  editMode,
  prefix,
}) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const handleClose = () => setOpen(false);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {prefix ? (
          <RenderUiPrefix
            editMode={editMode}
            labels={labels}
            code={prefix}
            defaultValue="Create an opportunity"
          >
            {(text) => {
              return (
                <DialogTitle sx={{ pointerEvents: 'none' }}>{text}</DialogTitle>
              );
            }}
          </RenderUiPrefix>
        ) : (
          <DialogTitle sx={{ pointerEvents: 'none' }}>
            Create an opportunity
          </DialogTitle>
        )}

        <IconButton onClick={handleClose} sx={{ pr: 3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            pipeline: null,
          }}
          validationSchema={Yup.object({
            pipeline: Yup.object().required(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              await createRecord({
                values: {
                  pipeline: values.pipeline?.id,
                  companies: selectedRows,
                },
                url: createBulkOpportunitiesUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              setOpen(false);
              setSelectedRows([]);
              resetForm();
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (error) {
              notify('There was an error, please try again', {
                type: 'ERROR',
              });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete="off" style={{ paddingTop: '6px' }}>
              <Grid sx={{ paddingBottom: '22px' }} container spacing={3}>
                <Grid item xs={12}>
                  <AutocompleteFieldV2
                    name="pipeline"
                    label="Pipeline"
                    requestKey="company-opportunities-bulk"
                    fetchUrl={pipelinesUrls.list}
                    required
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
