import React from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';

export default function ChatLoader() {
  return (
    <Stack spacing={2} alignItems='center'>
      <CircularProgress />
      <Typography>Loading chats...</Typography>
    </Stack>
  );
}
