import { isValid, format, formatISO, formatDistanceToNow } from 'date-fns';
import {
  crmUrls,
  inventoryUrls,
  systemUrls,
  nodeDriveUrls,
  cmsUrls,
  urlShortenerUrls,
  formsUrls,
  computeUrls,
  pmUrls,
} from '@config/routes';
import { createRecord } from './requests';
import moment from 'moment';

/**
 * Update the date component of date1 to match date2 while keeping the time component from date1.
 *
 * @param {string} date1Str - The original date-time string (with time and timezone).
 * @param {string} date2Str - The target date string (without time).
 * @returns {string} - The updated date-time string with the new date and original time.
 */
export function updateDateWithTime(date1Str, date2Str) {
  // Parse date1 using moment
  const date1 = moment(date1Str);

  // Extract the time component from date1
  const timeComponent = date1.format('HH:mm:ss.SSS');

  // Extract the timezone offset from date1
  const timezoneOffset = date1Str.slice(23); // This captures the +00:00 part

  // Combine date2 and time component, and reapply the original timezone offset
  const updatedDateStr = date2Str + 'T' + timeComponent + timezoneOffset;

  // Parse the combined date string with moment to ensure proper formatting
  const updatedDate = moment(updatedDateStr);

  // Return the updated date-time string in ISO format
  return updatedDate.toISOString();
}

export const isSuperUserOrAutomataAdmin = (user) => {
  if (user?.details?.is_superuser) {
    return true;
  }

  if (
    Array.isArray(user?.details?.roles_names) &&
    user.details.roles_names.includes('Automata Admin')
  ) {
    return true;
  }

  return false;
};

export const getLastSelectedDropdownOption = (label, fallback) => {
  const { data } = parseJSON(localStorage.getItem(label));
  if ((data?.id && data?.label) || (!data?.id && data?.label === 'All')) {
    return data;
  }

  if (fallback) return fallback();

  return null;
};

export const validateEmail = (email) => {
  const reValidEmail =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  return reValidEmail.test(email);
};

export const addYearsToDate = (date, years) =>
  new Date(date.setFullYear(date.getFullYear() + years));

export const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const truncateText = (text) => {
  return typeof text === 'string' && text.length > 38
    ? `${text.substring(0, 38)}...`
    : text;
};

export function formatBytes(bytes, decimalPlaces = 2) {
  if (!Number.isFinite(bytes) || bytes < 0) {
    return '...';
  }

  const k = 1024;
  const decimalPlacesToShow = decimalPlaces < 0 ? 0 : decimalPlaces;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log2(bytes) / 10);

  return `${parseFloat(
    (bytes / Math.pow(k, i)).toFixed(decimalPlacesToShow)
  )} ${sizes[i]}`;
}

export function parseRRule(rruleString) {
  const parts = rruleString.split(';');
  const parsedRRule = {};
  for (const part of parts) {
    const [key, value] = part.split('=');
    if (key === 'BYWEEKDAY') {
      parsedRRule[key] = value.split(',');
    } else {
      parsedRRule[key] = value;
    }
  }
  return parsedRRule;
}

export const formatMultipleAutocompleteValue = (value) => {
  const member_only_these_roles_can_see_it = {};

  if (value) {
    value.map(
      (role) => (member_only_these_roles_can_see_it[role.id] = role.label)
    );
  }

  return member_only_these_roles_can_see_it;
};

export const getEnabled = (user, pathname, routeValidation = true) => {
  const encryptionPaths = [
    // notification ms
    '/email-templates',
    '/email-templates/[id]',
    '/email-templates/html-editor/[id]',
    '/imap-accounts',
    '/imap-accounts/[id]',
    '/inbox-emails/[id]',
    '/inbox-emails/detail/[id]',
    '/sent-emails/[id]',
    '/sent-emails/detail/[id]',
    '/signature/[id]',
    '/all-emails/detail/[id]',
    '/all-emails',
    '/all-emails/[id]',
  ];

  if (
    user.token &&
    user.details &&
    user.details.encryption_public_key &&
    user.encryptionToken &&
    !routeValidation
  ) {
    return true;
  } else if (
    user.token &&
    user.details &&
    user.details.encryption_public_key &&
    user.encryptionToken &&
    encryptionPaths.includes(pathname)
  ) {
    return true;
  } else if (
    user.token &&
    user.details &&
    user.details.encryption_public_key &&
    !encryptionPaths.includes(pathname)
  ) {
    return true;
  } else if (
    user.token &&
    user.details &&
    !user.details.encryption_public_key
  ) {
    return true;
  }

  return false;
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return '';
};

export const generateRandomCharacters = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getParamsFromHash = (urlFragment) => {
  // removing the '#' character
  const hash = urlFragment.substr(1);

  // separate params in a array
  const hashes = hash.split('&');

  const params = {};

  // iterate over hashes
  hashes.forEach((h) => {
    // split the value and the key
    const currentHash = h.split('=');
    const [key, value] = currentHash;

    // add a key value pair
    params[key] = value;
  });

  // return all the params
  return params;
};

export const getAuthHeader = (token, actAs, notfToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (notfToken) {
    config.headers['Application-Authorization'] = notfToken;
  }

  if (actAs && actAs?.id) {
    config.headers.ActAs = actAs?.id;
  }

  return config;
};

export const parseJSON = (payload) => {
  try {
    const data = JSON.parse(payload);
    return { error: false, data };
  } catch (err) {
    return { error: err.message || true, data: null };
  }
};

export const getClientOptions = (res) => {
  const findOption = (key) => res.find((r) => r.key === key);
  const adminLogo = findOption('adminLogo');
  const favicon = findOption('favicon');
  const primaryColor = findOption('primaryColor');

  return {
    adminLogo: adminLogo ? adminLogo.value : null,
    favicon: favicon ? favicon.value : null,
    colors: {
      primary: primaryColor ? primaryColor.value : null,
    },
  };
};

export const getTimeFromNow = (date) => {
  const parsedDate = new Date(date);
  if (!isValid(parsedDate)) {
    return `Error`;
  }
  return formatDistanceToNow(parsedDate, { addSuffix: true });
};

export const formatDate = (date) => {
  const parsedDate = new Date(date);
  if (!isValid(parsedDate)) {
    return `Error`;
  }

  return format(parsedDate, 'd/M/yy');
};

export const formatDateTime = (date) => {
  const parsedDate = new Date(date);
  if (!isValid(parsedDate)) {
    return `Error`;
  }

  return format(parsedDate, 'd/M/yy HH:mm');
};

export const formatToUTCDate = (date) => {
  const parsedDate = new Date(date);
  if (!isValid(parsedDate)) {
    return `Error`;
  }

  return format(parsedDate, 'yyyy-MM-dd');
};

export const formatToUTCDateTime = (date) => {
  const parsedDate = new Date(date);
  if (!isValid(parsedDate)) {
    return `Error`;
  }

  return formatISO(parsedDate);
};

export const getActiveAccordObj = (name, url) => ({
  name,
  url,
});

export const getBPMCellType = (cell) => {
  if (!cell || !cell.style) return;

  if (cell.style.includes('shape=rhombus;')) {
    return 'decision';
  }

  if (cell.style.includes('shape=ellipse;')) {
    return 'terminator';
  }

  if (cell.style.includes('shape=subroutine;')) {
    return 'subroutine';
  }

  if (cell.style.includes('shape=delay;')) {
    return 'delay';
  }

  if (cell.style.includes('shape=loopLimit;')) {
    return 'loop_limit';
  }

  return 'process';
};

export const handleDragEnd = async (result, lists, setLists = () => {}) => {
  if (!result.destination) {
    return;
  }

  const { source, destination } = result;

  if (source.droppableId === destination.droppableId) {
    return;
  }

  const sourceColumn = lists.find((r) => r.id === source.droppableId);
  const destColumn = lists.find((r) => r.id === destination.droppableId);

  const sourceItems = [...sourceColumn.items];
  const destItems = [...destColumn.items];

  const [removedItem] = sourceItems.splice(source.index, 1);

  destItems.splice(destination.index, 0, removedItem);

  const newList = lists.map((list) => {
    if (list.id === source.droppableId) {
      return { ...list, items: sourceItems };
    }

    if (list.id === destination.droppableId) {
      return { ...list, items: destItems };
    }

    return list;
  });

  setLists({
    newList,
    removedItem,
    destId: destination.droppableId,
  });
};

export const handleErrorsWithNotify = ({ err, notify }) => {
  const errorMessage = 'There was an error, please try again';

  try {
    if (!err || !err.response) {
      notify(errorMessage, { type: 'ERROR' });
      return;
    }

    const { status, data } = err.response;

    if (status === 400 && data) {
      const key = Object.keys(data)[0];

      if (!key) {
        notify(errorMessage, { type: 'ERROR' });
      } else {
        notify(data[key], { type: 'ERROR' });
      }
    } else if (status === 502) {
      notify('Under Maintenance, please retry in a few minutes', {
        type: 'ERROR',
      });
    } else {
      notify(errorMessage, { type: 'ERROR' });
    }
  } catch (error) {
    notify(errorMessage, { type: 'ERROR' });
  }
};

export const getImportExportUrl = (ms, model) => {
  if (ms === 'CRM') {
    return {
      import: crmUrls.importUrls.detail(model),
      export: crmUrls.exportUrls.detail(model),
    };
  } else if (ms === 'Inventory') {
    return {
      import: inventoryUrls.importUrls.detail(model),
      export: inventoryUrls.exportUrls.detail(model),
    };
  } else if (ms === 'System') {
    return {
      import: systemUrls.importUrls.detail(model),
      export: systemUrls.exportUrls.detail(model),
    };
  } else if (ms === 'CMS') {
    return {
      import: cmsUrls.importUrls.detail(model),
      export: cmsUrls.exportUrls.detail(model),
    };
  } else if (ms === 'UrlShortner') {
    return {
      import: urlShortenerUrls.importLinksUrls.list(),
      export: urlShortenerUrls.exportLinksUrls.list(),
    };
  } else if (ms === 'Forms') {
    return {
      import: formsUrls.importUrls.detail(model),
      export: formsUrls.exportUrls.detail(model),
    };
  } else if (ms === 'Compute') {
    return {
      import: computeUrls.importUrls.detail(model),
      export: computeUrls.exportUrls.detail(model),
    };
  } else if (ms === 'PM') {
    return {
      import: pmUrls.importUrls.detail(model),
      export: pmUrls.exportUrls.detail(model),
    };
  } else {
    return {
      import: '',
      export: '',
    };
  }
};

export const stripHTML = (html, truncate = true) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  let text = tmp.textContent || tmp.innerText || '';
  // remove spaces and new lines
  text = text.replace(/(\r\n|\n|\r)/gm, '');
  // truncate
  text = truncate && text.length > 38 ? text.substring(0, 38) + '...' : text;
  return text;
};

export const handleCreateFormErrors = ({
  err,
  setError,
  values,
  notify,
} = {}) => {
  const errorMessage = 'There was an error, please try again';
  try {
    if (!err || !err.response) {
      notify(errorMessage, { type: 'ERROR' });
      return;
    }

    const { status, data } = err.response;

    if (status === 400 && data) {
      const key = Object.keys(data)[0];

      if (!key) {
        notify(errorMessage, { type: 'ERROR' });
      } else if (key in values) {
        setError(data);
      } else {
        notify(data[key], { type: 'ERROR' });
      }
    } else if (status === 502) {
      notify('Under Maintenance, please retry in a few minutes', {
        type: 'ERROR',
      });
    } else {
      notify(errorMessage, { type: 'ERROR' });
    }
  } catch (error) {
    notify(errorMessage, { type: 'ERROR' });
  }
};

export const uploadFileToDrive = async ({ files, filename, userToken }) => {
  const [file] = files;

  if (!file) {
    return { error: 'No file provided' };
  }

  const { filesUrls } = nodeDriveUrls;
  const formData = new FormData();
  formData.append('anonymous_can_see_it', true);
  formData.append('file', file, filename);

  try {
    const response = await createRecord({
      values: formData,
      url: filesUrls.list(),
      token: userToken,
    });

    return { url: response?.data?.fileUrl };
  } catch (err) {
    const { response } = err;
    return { error: response ? response.data : err.message };
  }
};

export const uploadFilesToDrive = async ({
  files,
  userToken,
  company,
  encryptionToken,
  fileName,
  client,
  createdBy,
}) => {
  const { filesUrls } = nodeDriveUrls;
  const fileUploadPromises = files.map(async (file) => {
    const formData = new FormData();
    formData.append('anonymous_can_see_it', true);
    formData.append('client', client);
    formData.append('created_by', createdBy);
    formData.append('file', file, fileName || undefined);

    try {
      const response = await createRecord({
        values: formData,
        url: filesUrls.list(),
        token: userToken,
        company,
        encryptionToken,
      });

      return { name: file.name, url: response?.data?.fileUrl };
    } catch (err) {
      const { response } = err;
      return { error: response ? response.data : err.message };
    }
  });

  const fileUploadResults = await Promise.all(fileUploadPromises);
  const urls = fileUploadResults.filter((result) => !result.error);
  return { urls };
};

export const notificationsSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

export function detectLink(text) {
  // regex to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = text.match(urlRegex);
  if (!urls) {
    return text;
  }
  // wrap each URL with a Link component
  return text.split(urlRegex).map((part, index) => {
    if (urls.includes(part)) {
      return (
        <a href={part} target='_blank' rel='noopener noreferrer'>
          {part}
        </a>
      );
    }
    return part;
  });
}

export function convertTo12HourFormat(timeStr) {
  const date = new Date(`1970-01-01T${timeStr}Z`);

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  }).format(date);

  return formattedTime;
}

export const openBrowserPopup = (url) => {
  window.open(url, 'ApplicationWindow', 'width=900,height=900');
};
