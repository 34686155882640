import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import Button from '@ui/Button';
import { Grid, Typography } from '@mui/material';
import VisibilityPromptModel from '@components/Visibility/VisibilityPromptModel';
import * as Yup from 'yup';
import getSchemaFromColumnsForForm from '@config/functions/getSchemaFromColumnsForForm';
import getAddFormInitialValues from '@config/functions/getAddFormInitialValues';
import ImportExportBtn from '@ui/ImportExportBtn';
import { msWithExport } from '@config/constants';
import { getImportExportUrl } from '@config/functions/helperFunctions';
import RenderUiPrefix from '@components/RenderUiPrefix';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchField from '@ui/Inputs/SearchField';
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityPopupNode from '@components/Visibility/VisibilityPopupNode';
import { getColumns } from '@config/metaMappings';

export default function PageHeader({
  label,
  pageHeaderFormHeaderText,
  pageHeaderFormButtonSuccessText,
  pageHeaderbuttonText,
  addNewForm,
  customInitialValues = {},
  headerContent,
  getCustomHeaderContent = () => null,
  customSubmitBtn = null,
  search,
  refetch = () => null,
  cancelBtnHandleFunction = () => null,
  customCancelButton = () => null,
  includeClient = false,
  clients,
  setClientTerm,
  fetchingClients,
  roles,
  setRoleTerm,
  rolesIsFetching,
  users,
  setUserTerm,
  usersIsFetching,
  user,
  microservice,
  model,
  view,
  showVisibilityInPageHeader,
  importExport,
  prefix,
  editMode,
  labels,
  showCreateForm,
  createLabel,
  onFormCancel,
  customOptionsOnRight = () => null,
  importExportFields,
  hideSearch,
  nodeAPI,
  columnKey,
  columnOptions,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showAddNewForm, setAddNewForm] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const columnsMemoised = React.useMemo(
    () => getColumns(columnKey, columnOptions),
    [columnKey, columnOptions]
  );

  const ensureArray = (value) => (Array.isArray(value) ? value : []);

  // Helper function to transform roles or users array
  const transform = (arr) => arr.map(({ id, label }) => `${id}|${label}`);

  const reverseTransform = (arr) =>
    arr.map((item) => {
      const [id, label] = item.split('|');
      return { id, label };
    });

  const handleVisibilitySave = (values, setFieldValue) => {
    const {
      everyone_can_see_it,
      anonymous_can_see_it,
      everyone_in_object_company_can_see_it,
      only_these_roles_can_see_it,
      only_these_users_can_see_it,
    } = values;

    setFieldValue('everyone_can_see_it', !!everyone_can_see_it);
    setFieldValue('anonymous_can_see_it', !!anonymous_can_see_it);
    setFieldValue(
      'everyone_in_object_company_can_see_it',
      !!everyone_in_object_company_can_see_it
    );

    if (Array.isArray(only_these_roles_can_see_it)) {
      setFieldValue(
        'only_these_roles_can_see_it',
        transform(only_these_roles_can_see_it)
      );
    }

    if (Array.isArray(only_these_users_can_see_it)) {
      setFieldValue(
        'only_these_users_can_see_it',
        transform(only_these_users_can_see_it)
      );
    }
  };

  const cancelButtonClick = () => {
    setAddNewForm(false);
    if (onFormCancel) onFormCancel();

    if (location.pathname === '/bugs') {
      navigate('/bugs');
    }

    customCancelButton(showAddNewForm, setAddNewForm);
    const timeoutId = setTimeout(() => {
      cancelBtnHandleFunction();
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  React.useEffect(() => {
    if (showCreateForm) setAddNewForm(true);
  }, [showCreateForm]);

  return (
    <Box component="div">
      {showAddNewForm ? (
        <Formik
          initialValues={
            addNewForm?.initialValues ?? {
              everyone_can_see_it: false,
              anonymous_can_see_it: false,
              everyone_in_object_company_can_see_it: true,
              only_these_roles_can_see_it: nodeAPI ? [] : {},
              only_these_users_can_see_it: nodeAPI ? [] : {},
              only_these_clients_can_see_it: {},
              ...getAddFormInitialValues(columnsMemoised, view),
              ...customInitialValues,
            }
          }
          validationSchema={
            addNewForm.validationSchema ??
            Yup.object({
              ...getSchemaFromColumnsForForm(columnsMemoised, view),
              everyone_can_see_it: Yup.bool().nullable(),
              anonymous_can_see_it: Yup.bool().nullable(),
              everyone_in_object_company_can_see_it: Yup.bool().nullable(),
              only_these_roles_can_see_it: nodeAPI
                ? Yup.object().default([]).nullable()
                : Yup.object().nullable(),
              only_these_users_can_see_it: nodeAPI
                ? Yup.object().default([]).nullable()
                : Yup.object().nullable(),
              only_these_clients_can_see_it: Yup.object().nullable(),
            })
          }
          validator={(...args) => console.log(args)}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldError }
          ) => {
            try {
              const setError = (error) => {
                Object.entries(error).map((err) =>
                  setFieldError(err[0], err[1])
                );
              };

              await addNewForm.handleSubmit(
                values,
                setSubmitting,
                resetForm,
                setAddNewForm,
                refetch,
                setError,
                setFieldError
              );
            } catch (err) {
              console.log(err.message);
            }
          }}
        >
          {({
            isSubmitting,
            errors,
            values,
            setValues,
            touched,
            setFieldValue,
            setFieldTouched,
            submitForm,
          }) => {
            const {
              everyone_can_see_it,
              anonymous_can_see_it,
              everyone_in_object_company_can_see_it,
              only_these_roles_can_see_it,
              only_these_users_can_see_it,
            } = values;

            return (
              <Form noValidate autoComplete="off">
                <Box sx={{ fontSize: 20, fontWeight: 'bold', mb: 2 }}>
                  {view === 'listView'
                    ? pageHeaderFormHeaderText
                      ? pageHeaderFormHeaderText
                      : `Add ${createLabel || label}`
                    : pageHeaderFormHeaderText
                    ? pageHeaderFormHeaderText
                    : `Add New`}
                </Box>

                <Grid
                  container
                  spacing={{ xs: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 2 }}
                >
                  {addNewForm.getFields({
                    setFieldValue,
                    errors,
                    touched,
                    values,
                    setValues,
                    setFieldTouched,
                  })}

                  <Grid key="page-header-grid-item" item xs={6}>
                    {showVisibilityInPageHeader ? (
                      <Button
                        key="page-header-visiblity-button"
                        type="button"
                        variant="contained"
                        label="Visibility"
                        onClick={() => setShowVisibilityModal(true)}
                        sx={{ ml: '5px' }}
                      />
                    ) : null}

                    <Button
                      type="button"
                      variant="outlined"
                      label="Cancel"
                      onClick={() => cancelButtonClick()}
                      sx={{ ml: '5px' }}
                    />
                    {customSubmitBtn ? (
                      customSubmitBtn()
                    ) : (
                      <LoadingButton
                        onClick={async () => {
                          await submitForm();
                        }}
                        sx={{ ml: '5px' }}
                        loading={isSubmitting}
                        loadingPosition="start"
                        startIcon={
                          <span
                            style={{ width: isSubmitting ? '20px' : '0px' }}
                          />
                        }
                        variant="contained"
                      >
                        <span>
                          {pageHeaderFormButtonSuccessText
                            ? pageHeaderFormButtonSuccessText
                            : 'Save'}
                        </span>
                      </LoadingButton>
                    )}
                  </Grid>
                </Grid>

                {nodeAPI && showVisibilityModal ? (
                  <VisibilityPopupNode
                    user={user}
                    prefix="0i3"
                    editMode={editMode}
                    labels={labels}
                    open={showVisibilityModal}
                    setOpen={setShowVisibilityModal}
                    initialValues={{
                      everyone_can_see_it,
                      anonymous_can_see_it,
                      everyone_in_object_company_can_see_it,
                      only_these_roles_can_see_it: reverseTransform(
                        ensureArray(only_these_roles_can_see_it)
                      ),
                      only_these_users_can_see_it: reverseTransform(
                        ensureArray(only_these_users_can_see_it)
                      ),
                    }}
                    handleSave={(values) => {
                      handleVisibilitySave(values, setFieldValue);
                    }}
                  />
                ) : null}

                {!nodeAPI && showVisibilityModal ? (
                  <VisibilityPromptModel
                    open={showVisibilityModal}
                    clients={clients}
                    includeClient={includeClient}
                    setClientTerm={setClientTerm}
                    fetchingClients={fetchingClients}
                    onCancelClick={() => setShowVisibilityModal(false)}
                    customInitialValues={customInitialValues}
                    onUpdateClick={() => setShowVisibilityModal(false)}
                    roles={roles}
                    setRoleTerm={setRoleTerm}
                    rolesIsFetching={rolesIsFetching}
                    users={users}
                    setUserTerm={setUserTerm}
                    usersIsFetching={usersIsFetching}
                    setFieldValue={setFieldValue}
                    values={values}
                    editMode={editMode}
                    labels={labels}
                    prefix="0i3"
                  />
                ) : null}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
              {view === 'listView' ? (
                prefix ? (
                  <RenderUiPrefix
                    editMode={editMode}
                    labels={labels}
                    code={prefix}
                    defaultValue={label}
                  >
                    {(text) => (
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{
                          mr: view === 'listView' ? 2 : 0,
                          fontWeight: '500',
                        }}
                      >
                        {text}
                      </Typography>
                    )}
                  </RenderUiPrefix>
                ) : (
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{
                      mr: view === 'listView' ? 2 : 0,
                      fontWeight: '500',
                    }}
                  >
                    {label}
                  </Typography>
                )
              ) : null}

              {addNewForm ? (
                <Box>
                  <Button
                    type="button"
                    variant="outlined"
                    label={
                      pageHeaderbuttonText ? pageHeaderbuttonText : 'Create'
                    }
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setAddNewForm(true);
                      search.setValue('');
                    }}
                  />

                  {headerContent}
                </Box>
              ) : null}

              {!addNewForm && headerContent ? (
                <Box>{headerContent()}</Box>
              ) : null}

              {getCustomHeaderContent ? (
                <Box>{getCustomHeaderContent(addNewForm, setAddNewForm)}</Box>
              ) : null}
            </Box>
          </Grid>

          {search && (
            <Grid
              item
              xs={7}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                mb: 2,
              }}
            >
              {customOptionsOnRight && customOptionsOnRight()}

              {((user.details && user.details.is_superuser) ||
                (user.details &&
                  Array.isArray(user.details.roles_names) &&
                  user.details.roles_names.includes('Can Import/Export'))) &&
              importExport &&
              msWithExport.find((r) => r.name === microservice) ? (
                <Box sx={{ mr: 2 }}>
                  <ImportExportBtn
                    urls={getImportExportUrl(microservice, model)}
                    model={model}
                    refetch={refetch}
                    formFields={importExportFields}
                  />
                </Box>
              ) : null}

              {!hideSearch ? (
                <Box>
                  <SearchField setSearch={(term) => search.setValue(term)} />
                </Box>
              ) : null}
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
}
