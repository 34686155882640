import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';

export default function ChatMessageEditPopup({
  open,
  setOpen,
  initialMessage,
  onEditMessage,
}) {
  const [newMessage, setNewMessage] = React.useState(initialMessage ?? '');

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    handleClose();
    onEditMessage(newMessage);
  };

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Edit message</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <TextField
            fullWidth
            variant='outlined'
            placeholder='Edit message'
            sx={{ background: '#fff' }}
            multiline
            maxRows={4}
            size='small'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cancel
        </Button>
        <Button onClick={handleEdit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
