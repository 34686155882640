import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { truncateContent } from '@config/functions/chatFunctions';
import { useDispatch } from 'react-redux';
import { setReplyTo } from '@redux/chatSlice';
import ChatFileDisplayBox from '@components/Chat/ChatFileDisplayBox/ChatFileDisplayBox';

export default function ChatMessageQuote({
  content,
  author,
  gutter,
  showCancelButton,
  onClick,
  file,
}) {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setReplyTo(null));
  };

  return (
    <Box
      sx={{
        padding: '7px 10px',
        background: 'rgba(11, 20, 26, 0.05)',
        borderRadius: '4px',
        position: 'relative',
        mb: gutter ? '4px' : '',
        width: '100%',
        userSelect: 'none',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Typography
        sx={{ fontSize: '12.8px', lineHeight: '22px', fontWeight: '600' }}
      >
        {author}
      </Typography>

      {file ? (
        <ChatFileDisplayBox
          padding
          transparentBackground
          fileName={file?.name}
          fileSize={file?.size}
        />
      ) : null}

      <Typography
        sx={{
          fontSize: '13.2px',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          color: '#2A3939',
        }}
      >
        {truncateContent(content, 100)}
      </Typography>

      {showCancelButton ? (
        <IconButton
          sx={{
            position: 'absolute',
            top: '3px',
            right: '6px',
          }}
          size='small'
          onClick={onCancel}
          aria-label='cancel-quote'
        >
          <CancelIcon fontSize='small' />
        </IconButton>
      ) : null}
    </Box>
  );
}
