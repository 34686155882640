import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  formatChatMessageDate,
  detectLink,
  getPersonNameAndInitials,
} from '@config/functions/chatFunctions';
import ChatMessageContextMenu from '@components/Chat/ChatMessageContextMenu/ChatMessageContextMenu';
import ChatMessageQuote from '@components/Chat/ChatMessageQuote/ChatMessageQuote';
import ChatFileDisplayBox from '@components/Chat/ChatFileDisplayBox/ChatFileDisplayBox';

export default function ChatMessageBubble({
  type,
  name,
  roomId,
  isRead,
  isGroup,
  onDeleteMessage,
  message,
  membersMap,
  onEditMessage,
  userMemberId,
  scrollToMessage,
  inSharedMedia,
}) {
  const isIncoming = type === 'incoming';
  const isDeleted = !!message?.deleted;

  const isEdited = React.useMemo(() => {
    if (isDeleted) return false;
    return (
      new Date(message?.updatedAt).getTime() >
      new Date(message?.createdAt).getTime()
    );
  }, [isDeleted, message]);

  const messageContextMenuProps = React.useMemo(
    () => ({
      type,
      message,
      onDeleteMessage,
      onEditMessage,
      inSharedMedia,
    }),
    [type, message, onDeleteMessage, onEditMessage]
  );

  const replyToMessage = message?.replyTo;
  const replyToAuthorId = replyToMessage?.authorId;
  const replyToAuthor = membersMap[replyToAuthorId];
  const replyToAuthorName =
    replyToAuthorId === userMemberId
      ? 'You'
      : getPersonNameAndInitials(replyToAuthor)?.fullName;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isIncoming ? 'flex-start' : 'flex-end',
        alignItems: 'center',
        mb: 1.5,
        position: 'relative',
      }}
    >
      {!isIncoming && !isDeleted && (
        <ChatMessageContextMenu {...messageContextMenuProps} />
      )}

      <Box
        id={message?.id}
        sx={{
          maxWidth: inSharedMedia ? '100%' : '75%',
          p: '6px 9px 8px',
          borderRadius: '7.5px',
          bgcolor: isIncoming ? '#fff' : '#F1FDE0',
          boxShadow: '0 1px .5px rgba(11, 20, 26, .13)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          wordBreak: 'break-word',
          overflow: 'hidden',
          transition: 'all',
        }}
        className={`r-${roomId}-message`}
      >
        {isGroup && !!name ? (
          <Typography
            color='primary'
            sx={{
              fontSize: '14.2px',
              fontWeight: '600',
              lineHeight: '19px',
              p: '3px 6px',
              fontStyle: isDeleted ? 'italic' : 'normal',
            }}
          >
            {name}
          </Typography>
        ) : null}

        {replyToMessage?.id && !isDeleted && (
          <ChatMessageQuote
            gutter
            file={replyToMessage?.details?.file}
            content={replyToMessage?.content}
            author={replyToAuthorName}
            onClick={() => scrollToMessage(replyToMessage?.id)}
          />
        )}

        {message?.attachmentId && message?.details?.file?.id && !isDeleted ? (
          <ChatFileDisplayBox
            padding
            disableLightBox={inSharedMedia}
            fileName={message?.details?.file?.name}
            fileSize={message?.details?.file?.size}
            fileExtension={message?.details?.file?.extension}
            fileId={message?.details?.file?.id}
          />
        ) : null}

        <Typography
          sx={{
            mr: 0.8,
            fontSize: '14.2px',
            lineHeight: '19px',
            color: '#111b21',
            width: '100%',
            whiteSpace: 'pre-line',
            p: '3px 6px',
            fontStyle: isDeleted ? 'italic' : 'normal',
          }}
        >
          {isDeleted
            ? 'This message was deleted.'
            : detectLink(message?.content ?? '...')}
        </Typography>

        <Stack
          direction='row'
          alignItems='end'
          sx={{ mt: '-2px', mb: '-5px', minWidth: '42px', ml: 'auto' }}
          spacing={0.5}
        >
          {isEdited && (
            <Typography
              sx={{ color: '#111b21', fontSize: '11px', flexShrink: 0 }}
            >
              Edited
            </Typography>
          )}

          <Typography
            sx={{
              color: isIncoming ? '#667781' : '#111b21',
              fontSize: '11px',
              flexShrink: 0,
            }}
          >
            {formatChatMessageDate(
              isEdited ? message?.updatedAt : message?.createdAt,
              true
            )}
          </Typography>
          {!isIncoming && (
            <DoneAllIcon fontSize='small' color={isRead ? 'primary' : ''} />
          )}
        </Stack>
      </Box>

      {isIncoming && !isDeleted && (
        <ChatMessageContextMenu {...messageContextMenuProps} />
      )}
    </Box>
  );
}
