import React from 'react';
import { mxConstants } from 'mxgraph-js';
import { Box, Stack, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import { updateRecord } from '@config/functions/requests';
import { bpaUrls } from '@config/routes';
import {
  TextField,
  CheckBoxField,
  AutocompleteFieldV2,
} from '@components/Inputs';
import useToast from '@hooks/useToast';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ManualInstructionsPopup from '@components/BPA-V2/Popups/ManualInstructionsPopup';
import { useQueryClient } from 'react-query';
import StageDropdown from '@dropdown-forms/bpa/StageDropdown';
import { selectBPaState } from '@redux/bpaSlice';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const { shapesUrls, getOrderedStageUrls } = bpaUrls;

export default function ShapeDetailsForm({
  graph,
  shapeDetail = {},
  user = {},
  workflowId,
}) {
  const queryClient = useQueryClient();
  const [notify] = useToast();
  const [showModal, setShowModal] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { shapeCells } = useSelector(selectBPaState);

  const {
    id: shapeId,
    type,
    stage: currentStageId,
    manual_transition,
    manual_task_instructions = '',
    details = {},
    run_on_working_hours,
    x_starting_value,
    x_step_value,
  } = shapeDetail;

  const stage = details?.stage;

  React.useEffect(() => {
    console.log('V1-26');
  }, []);

  React.useEffect(() => {
    if (error) {
      notify('There was an error, please refresh the page', {
        type: 'ERROR',
      });
      setError(null);
    }
  }, [error, notify]);

  const handleFormSubmit = async (
    { stage, ...values },
    { setFieldError, setSubmitting }
  ) => {
    try {
      setSubmitting(true);

      const shapeType = shapeDetail.type;
      const isProcess = shapeType === 'process';
      const isDiamond = shapeType === 'decision';
      const isLoopLimit = shapeType === 'loop_limit';

      if (!stage || !stage?.id) {
        setFieldError('stage', 'Stage is a required field.');
        return;
      }

      if (
        isLoopLimit &&
        values?.x_starting_value !== 0 &&
        !values?.x_starting_value
      ) {
        setFieldError('x_starting_value', 'Invalid X Starting Value!');
        return;
      }

      if (isLoopLimit && values?.x_step_value !== 0 && !values?.x_step_value) {
        setFieldError('x_step_value', 'Invalid X Step Value!');
        return;
      }

      const isManual = !!values.manual_transition && (isProcess || isDiamond);

      if (graph) {
        const cell = graph.getModel().getCell(shapeId) ?? shapeCells[shapeId];
        if (cell) {
          // Adds cells to the model in a single step
          graph.getModel().beginUpdate();
          if (stage?.label) {
            graph.getModel().setValue(cell, stage?.label);
          }
          graph.setCellStyles(
            mxConstants.STYLE_FILLCOLOR,
            isManual ? '#FFBF00' : '#FFFFFF',
            [cell]
          );
          graph.getModel().endUpdate();
          graph.refresh();
        } else {
          console.log('cell not found', shapeId, cell);
        }
      } else {
        console.log('graph not found');
      }

      function getStyle(shape, isManual) {
        const fillColor = isManual ? '#FFBF00' : 'white';
        const baseStyle = `strokeColor=black;fillColor=${fillColor};fontColor=black`;

        return shape ? `shape=${shape};${baseStyle}` : baseStyle;
      }

      let style = shapeDetail.style;

      if (isProcess) {
        style = getStyle(null, isManual);
      } else if (isDiamond) {
        style = getStyle('rhombus', isManual);
      }

      if (!style.includes('whiteSpace=wrap')) {
        style = `${style};whiteSpace=wrap;`;
      }

      await updateRecord({
        url: shapesUrls.detail(shapeId),
        values: {
          ...values,
          style,
          name: stage?.label,
          stage: stage?.id,
        },
        token: user?.token,
        actAs: user?.actAs,
      });

      notify('Operation completed', {
        type: 'SUCCESS',
      });
      queryClient.invalidateQueries([`${shapeId}-shape-stages`]);
      queryClient.invalidateQueries([`${shapeId}-shape-and-actions`]);
    } catch (err) {
      console.error(err.response || err.message);
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Formik
        enableReinitialize
        initialValues={{
          stage: currentStageId
            ? {
                id: currentStageId,
                order: stage?.order,
                name: stage?.name,
                description: stage?.description,
                label: `${stage?.stage_full_order ?? ''}${
                  `${stage?.order}` ?? ''
                } ${stage?.name ?? ''}`,
              }
            : null,
          manual_transition: manual_transition,
          run_on_working_hours: run_on_working_hours,
          manual_task_instructions: manual_task_instructions ?? '',
          x_starting_value: x_starting_value ?? 1,
          x_step_value: x_step_value ?? 1,
          stage_rebase: true,
        }}
        validationSchema={Yup.object({
          x_starting_value: Yup.number().positive(),
          x_step_value: Yup.number().positive(),
        })}
        onSubmit={handleFormSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form noValidate>
            <Stack spacing={2}>
              <StageDropdown
                workflow={workflowId}
                initialId={values?.stage?.id}
                initialOrder={values?.stage?.order}
                initialName={values?.stage?.name}
                initialDescription={values?.stage?.description}
                refetch={(newStage) => {
                  const { id, order, name, description, stage_full_order } =
                    newStage;
                  setFieldValue('stage', {
                    id,
                    order,
                    name,
                    description,
                    label: `${stage_full_order ?? ''}${`${order}` ?? ''} ${
                      name ?? ''
                    }`,
                  });
                  queryClient.invalidateQueries([`${shapeId}-shape-stages`]);
                }}
              >
                <AutocompleteFieldV2
                  name="stage"
                  label="Stage"
                  fetchUrl={() => {
                    return getOrderedStageUrls.detail(
                      details?.stage?.workflow ?? '',
                      '?without_shapes=true'
                    );
                  }}
                  requestKeyOptions={[details?.stage?.workflow]}
                  requestKey={`${shapeId}-shape-stages`}
                  renderRow={(row) => ({
                    id: row?.id,
                    order: row?.order,
                    name: row?.name,
                    description: row?.description,
                    label: `${row?.stage_full_order ?? ''}${
                      `${row?.order}` ?? ''
                    } ${row?.name ?? ''}`,
                  })}
                />
              </StageDropdown>

              {type === 'loop_limit' ? (
                <>
                  <TextField
                    label="X Starting Value"
                    name="x_starting_value"
                    type="number"
                  />

                  <TextField
                    label="X Step Value"
                    name="x_step_value"
                    type="number"
                  />
                </>
              ) : null}

              {type === 'process' || type === 'decision' ? (
                <>
                  <CheckBoxField
                    name="manual_transition"
                    label="Manual Transition?"
                  />

                  <Box>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => setShowModal(true)}
                      startIcon={<CreateOutlinedIcon fontSize="small" />}
                    >
                      Manual Instructions
                    </Button>
                  </Box>
                </>
              ) : null}
              <>
                <CheckBoxField
                  name="run_on_working_hours"
                  label="Run on Working Hours"
                />
              </>
            </Stack>

            {showModal ? (
              <ManualInstructionsPopup
                open={showModal}
                setOpen={setShowModal}
                setFieldValue={setFieldValue}
                value={values?.manual_task_instructions}
              />
            ) : null}

            <Box sx={{ mt: 2 }}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                disableElevation
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
