import React from 'react';
import { Box, Stack } from '@mui/material';
import Header from '@components/KanbanBoardHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { selectINAsBoard, setINAs } from '@redux/inasBoardSlice';
import { useDispatch, useSelector } from 'react-redux';
import KanbanBoardList from '@components/KanbanBoardList';
import { selectProfile } from '@redux/profileSlice';
import { hrUrls, calendarUrls } from '@config/routes';
import useToast from '@hooks/useToast';
import { updateRecord } from '@config/functions/requests';
import {
  handleDragEnd,
  getAuthHeader,
  getLastSelectedDropdownOption,
  updateDateWithTime,
} from '@config/functions/helperFunctions';
import Loader from '@components/Loader';
import useRQuery from '@hooks/useRQuery';
import ErrorScreen from '@components/ErrorScreen';
import SearchField from '@ui/Inputs/SearchField';

const { employeesUrls } = hrUrls;
const {
  actionReminderStageUrls,
  actionRemindersUrls,
  actionsUrls,
  // prettier xxx
} = calendarUrls;

export default function INAsBoard({ labels, editMode }) {
  const { inas } = useSelector(selectINAsBoard);
  const user = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [notify, update] = useToast();
  const initialEmployee = getLastSelectedDropdownOption(
    'lastSelectedResponsible',
    () => {
      const employeeDetails = user?.details?.details?.employee;
      return employeeDetails
        ? {
            id: employeeDetails?.id ?? '',
            label: employeeDetails?.work_email ?? '',
          }
        : null;
    }
  );
  const initialDoWhat = getLastSelectedDropdownOption('lastSelectedDoWhat');

  const [employee, setEmployee] = React.useState(initialEmployee);
  const [doWhat, setDoWhat] = React.useState(initialDoWhat);
  const [searchValue, setSearchValue] = React.useState('');
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data, isLoading, isError } = useRQuery({
    key: ['inas-board', employee, doWhat, searchValue, user.token, user?.actAs],
    url: actionReminderStageUrls.list(
      `?p=36&search=${searchValue}&timezone=${userTimezone}${
        employee ? `&responsible=${employee?.id}` : ''
      }${doWhat && doWhat?.id !== 'All' ? `&do_what=${doWhat?.id ?? ''}` : ''}`
    ),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const handleSaveCardDrag = async ({ newList, removedItem, destId }) => {
    const tId = notify('Saving....', { loading: true });
    dispatch(setINAs(newList));

    console.log('removedItem', removedItem);

    try {
      const newDate = updateDateWithTime(removedItem?.datetime, destId);
      await updateRecord({
        url: actionRemindersUrls.detail(removedItem.id),
        values: { reminder_start_datetime: newDate },
        token: user.token,
        actAs: user?.actAs,
      });
      update(tId, 'Your changes were saved');
    } catch (err) {
      console.log(
        err.response && err.response.data ? err.response.data : err.message
      );
      update(tId, 'Failed to save changes', { type: 'ERROR' });
    }
  };

  React.useEffect(() => {
    if (data) dispatch(setINAs(data));
  }, [data, dispatch]);

  if (isError) {
    return (
      <ErrorScreen text="Failed to fetch the resource from server" img="" />
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '85vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header
          title="INAs"
          prefix="0q9"
          labels={labels}
          editMode={editMode}
          filterOptions={[
            {
              label: 'Responsible',
              name: 'employee',
              requestKey: 'ina-kanban-employee',
              fetchUrl: employeesUrls.list,
              handleChange: (newValue) => {
                setEmployee(newValue);
                localStorage.setItem(
                  'lastSelectedResponsible',
                  JSON.stringify(newValue)
                );
              },
              initialValue: initialEmployee,
              renderRow: (r) => ({
                id: r.id,
                label: r.work_email,
              }),
              orderingCol: 'work_email',
            },
            {
              label: 'Do What',
              name: 'doWhat',
              requestKey: 'ina-do-whats',
              fetchUrl: actionsUrls.list,
              handleChange: (newValue) => {
                setDoWhat(newValue);
                localStorage.setItem(
                  'lastSelectedDoWhat',
                  JSON.stringify(newValue)
                );
              },
              orderingCol: 'name',
              initialValue: initialDoWhat,
            },
          ]}
        />
        <Stack direction="row" spacing={2}>
          <SearchField setSearch={(term) => setSearchValue(term)} />
        </Stack>
      </Box>

      {isLoading ? (
        <Box sx={{ py: 16 }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ position: 'relative', flexGrow: '1', marginTop: '24px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'start',
              overflowX: 'auto',
            }}
          >
            <DragDropContext
              onDragEnd={(res) => handleDragEnd(res, inas, handleSaveCardDrag)}
            >
              <KanbanBoardList
                detailLink="inas"
                data={inas}
                labels={labels}
                editMode={editMode}
              />
            </DragDropContext>
          </Box>
        </Box>
      )}
    </Box>
  );
}
