import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import {
  TextField,
  DateTimePickerField,
  SelectField,
  AutocompleteField,
} from '@components/Inputs';
import { Form, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
// import { formatToUTCDateTime } from '@config/functions/helperFunctions';
import { accountUrls, calendarUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { eventTypeChoices, timeZones } from '@config/constants';
import useRQuery from '@hooks/useRQuery';
import useAutocomplete from '@hooks/useAutocomplete';
import RenderUiPrefix from '@components/core/RenderUiPrefix';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CustomRecurranceModalWithDelete from '@components/Calendar/CustomRecurranceModalWithDelete';

const { calendarAccountsUrls, calendarsUrls } = calendarUrls;
const { usersUrls } = accountUrls;

export default function CreateEventModal({
  open,
  setOpen,
  onCreateEventClick,
  data,
  user,
  allCalendars = false,
  calendarAccount = {},
  labels,
  editMode,
  prefix,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const fixedOptions = [{ id: data && data.email, label: data && data.email }];
  const [users, setUsers] = React.useState([]);
  const [userTerm, setUserTerm] = React.useState('');
  const [calendarTerm, setCalendarTerm] = React.useState('');
  const [calendarAccountTerm, setCalendarAccountTerm] = React.useState('');
  const [selectedCalendarAccount, setSelectedCalendarAccount] =
    React.useState(calendarAccount);

  const originalState = {
    open: false,
    idx: null,
    rrule: '',
  };
  const [customModal, setCustomModal] = React.useState(originalState);

  const { data: usersList, isFetching: userIsFetching } = useRQuery({
    key: ['users-data', userTerm, user.token, user?.actAs],
    url: usersUrls.list(`?search=${userTerm}&page_size=100&queryCode=cba2`),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const { data: calendarAccounts, isFetching: fetchingCalendarAccounts } =
    useAutocomplete({
      key: ['calendar-accounts', user.token, user?.actAs],
      getUrl: calendarAccountsUrls.list,
      inputValue: calendarAccountTerm,
      selected: selectedCalendarAccount,
      renderRow: (r) => ({
        id: r.id,
        label: r.email,
      }),
    });

  const { data: calendars, isFetching: fetchingCalendars } = useAutocomplete({
    key: ['calendars', selectedCalendarAccount, user.token, user?.actAs],
    getUrl: calendarsUrls.list,
    query: `&account=${
      (selectedCalendarAccount && selectedCalendarAccount.id) || ''
    }`,
    inputValue: calendarTerm,
    enabled: !!selectedCalendarAccount,
  });

  React.useEffect(() => {
    if (!usersList) return;
    if (!usersList.results) return;

    setUsers(
      usersList.results.map((item) => ({
        id: item.email,
        label: item.email,
      }))
    );
  }, [usersList]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {prefix ? (
        <RenderUiPrefix
          editMode={editMode}
          labels={labels}
          code={prefix}
          defaultValue="Create Event"
        >
          {(text) => {
            return <DialogTitle>{text}</DialogTitle>;
          }}
        </RenderUiPrefix>
      ) : (
        <DialogTitle>Create Event</DialogTitle>
      )}
      <DialogContent>
        <Formik
          key="event-model-formik"
          initialValues={{
            title: '',
            description: '',
            location: '',
            link: '',
            event_date_times: [
              {
                start: data && data.start,
                end: data && data.end,
                rrule: '',
              },
            ],
            end: data && data.end,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            event_type: 'Event',
            slot_duration: 10,
            buffer_between_slots: 5,
            guests: [],
            // calendar: calendars.length ? calendars[0] : {},
          }}
          validationSchema={Yup.object({
            title: Yup.string().max(150).required(),
            location: Yup.string().max(150),
            link: Yup.string().url(),
            description: Yup.string().max(2500),
            event_date_times: Yup.array().of(
              Yup.object().shape({
                start: Yup.date()
                  .default(() => new Date())
                  .required(),
                end: Yup.date()
                  .default(() => new Date())
                  .required(),
                rrule: Yup.string(),
              })
            ),
            timezone: Yup.string().required(),
            event_type: Yup.string().required(),
            slot_duration: Yup.number().min(1),
            buffer_between_slots: Yup.number().min(0),
            calendar:
              allCalendars || Object.keys(calendarAccount).length
                ? Yup.string().required()
                : Yup.string(),
            guests: Yup.array(),
          })}
          onSubmit={async (
            values,
            { resetForm, setFieldError, setSubmitting }
          ) => {
            let hasErrors = false;

            const value = {
              title: values.title,
              description: values.description,
              link: values.link,
              location: values.location,
              event_type: values.event_type,
              event_date_times: values.event_date_times,
              timezone: values.timezone,
              calendar: values.calendar,
            };

            value.event_date_times.map((d, i) => {
              const startDT =
                typeof d?.start === 'string' ? new Date(d?.start) : d?.start;
              const endDT =
                typeof d?.end === 'string' ? new Date(d?.end) : d?.end;

              if (endDT < startDT) {
                setFieldError(
                  `event_date_times.${i}.end`,
                  'End Time should be greater than start time.'
                );
                hasErrors = true;
              }
            });

            if (hasErrors) return;

            if (values.event_type === eventTypeChoices[1].value) {
              value.slot_duration = values.slot_duration;
              value.buffer_between_slots = values.buffer_between_slots;
            }

            if (values.event_type === eventTypeChoices[2].value) {
              value.event_date_times = value.event_date_times.map((e) => ({
                start: e.start,
              }));
            }

            const guests = values.guests.map((r) => r.id);

            onCreateEventClick(value, guests);
            resetForm();
            setSubmitting(false);
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <>
              <Form noValidate autoComplete="off">
                <Grid
                  key="event-grid-container"
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField name="title" label="Title" />
                  </Grid>

                  <Grid item xs={12}>
                    <SelectField
                      label="Event Type"
                      name="event_type"
                      value={values.event_type}
                    >
                      {eventTypeChoices.map((r) => (
                        <MenuItem value={r.value} key={r.value}>
                          {r.label}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="event_date_times">
                      {({ insert, remove, push }) => (
                        <Grid
                          key={`event-dates-grid-container`}
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                          sx={{ mb: 2 }}
                        >
                          {values.event_date_times.map(
                            (event_date_time, index) => (
                              <>
                                <Grid
                                  item
                                  xs={
                                    values.event_type ===
                                    eventTypeChoices[2].value
                                      ? 11
                                      : 5
                                  }
                                >
                                  <DateTimePickerField
                                    name={`event_date_times.${index}.start`}
                                    label="Start Time"
                                    showTime
                                  />
                                </Grid>

                                {values.event_type ===
                                eventTypeChoices[2].value ? null : (
                                  <Grid item xs={5}>
                                    <DateTimePickerField
                                      name={`event_date_times.${index}.end`}
                                      label="End Time"
                                      showTime
                                    />
                                  </Grid>
                                )}

                                {values.event_type ===
                                eventTypeChoices[2].value ? null : (
                                  <Grid item xs={1} sx={{ mt: '5px' }}>
                                    <IconButton
                                      aria-label="repeat"
                                      size="medium"
                                      sx={{ pl: '0px' }}
                                      onClick={() =>
                                        setCustomModal({
                                          open: true,
                                          idx: index,
                                          rrule:
                                            values?.event_date_times[index]
                                              ?.rrule,
                                        })
                                      }
                                    >
                                      {values?.event_date_times[index]?.rrule &&
                                      values?.event_date_times[index]?.rrule
                                        .length ? (
                                        <EventRepeatIcon fontSize="inherit" />
                                      ) : (
                                        <ControlPointDuplicateIcon fontSize="inherit" />
                                      )}
                                    </IconButton>
                                  </Grid>
                                )}

                                <Grid item xs={1} sx={{ mt: '5px' }}>
                                  <IconButton
                                    aria-label="delete"
                                    size="medium"
                                    sx={{ pl: '0px' }}
                                    disabled={
                                      values?.event_date_times.length <= 1
                                        ? true
                                        : false
                                    }
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={6}>
                            <Box>
                              <Button
                                size="small"
                                onClick={() =>
                                  push({
                                    start: values.event_date_times.at(-1).start,
                                    end: values.event_date_times.at(-1).end,
                                  })
                                }
                                variant="contained"
                                disableElevation
                              >
                                Add Date
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </FieldArray>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectField
                      label="Timezone"
                      name="timezone"
                      value={values.timezone}
                    >
                      {timeZones.map((r) => (
                        <MenuItem value={r.value} key={r.value}>
                          {r.label}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>

                  {values.event_type === eventTypeChoices[1].value ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          name="slot_duration"
                          label="Slot Duration In Minutes"
                          required
                          number
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          name="buffer_between_slots"
                          label="Buffer Between Slots In Minutes"
                          required
                          number
                        />
                      </Grid>
                    </>
                  ) : null}

                  <Grid item xs={6}>
                    <TextField name="link" label="Conference Link" />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField name="location" label="Location" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Description"
                      multiline
                    />
                  </Grid>

                  {allCalendars || Object.keys(calendarAccount).length ? (
                    allCalendars ? (
                      <>
                        <Grid item xs={6}>
                          <AutocompleteField
                            label="Calendar Accounts"
                            options={calendarAccounts}
                            name="calendar_account"
                            isLoading={fetchingCalendarAccounts}
                            customSetValue={(value) =>
                              setSelectedCalendarAccount(value)
                            }
                            setSearchTerm={(term) =>
                              setCalendarAccountTerm(term)
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutocompleteField
                            label="Calendars"
                            options={calendars}
                            name="calendar"
                            optionLabel="label"
                            isLoading={fetchingCalendars}
                            setSearchTerm={(term) => setCalendarTerm(term)}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <AutocompleteField
                          label="Calendars"
                          options={calendars}
                          name="calendar"
                          optionLabel="label"
                          isLoading={fetchingCalendars}
                          setSearchTerm={(term) => setCalendarTerm(term)}
                        />
                      </Grid>
                    )
                  ) : null}

                  <Grid item xs={12}>
                    <AutocompleteField
                      key="guests"
                      multiple
                      setSearchTerm={setUserTerm}
                      isLoading={userIsFetching}
                      options={users}
                      label="Add Guests"
                      defaultValue={fixedOptions}
                      name="guests"
                      filterSelectedOptions
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" autoFocus>
                    Save
                  </Button>
                </DialogActions>
              </Form>
              {customModal?.open ? (
                <CustomRecurranceModalWithDelete
                  open={customModal?.open}
                  setOpen={(open) => setCustomModal({ ...originalState, open })}
                  oSave={(rrule) => {
                    setFieldValue(
                      'event_date_times',
                      (values?.event_date_times || []).map((data, index) => ({
                        ...data,
                        rrule: index === customModal?.idx ? rrule : data?.rrule,
                      }))
                    );
                    setCustomModal(originalState);
                  }}
                  showCancel={true}
                  rrule={customModal?.rrule}
                />
              ) : null}
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
