import React from 'react';
import { Box, Stack, Divider, Typography, Avatar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { deepPurple } from '@mui/material/colors';
import ChatSharedMediaAccordion from '@components/Chat/ChatSharedMediaAccordion/ChatSharedMediaAccordion';

export default function PrivateChatSettings({ activeRoom, setOpen }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: '99',
        height: '100%',
        background: '#fff',
        width: { sm: '490px' },
        borderLeft: '1px solid rgba(11, 20, 26, .13)',
      }}
    >
      <Box
        sx={{
          background: '#F0F2F5',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 16px',
        }}
      >
        <Stack direction='row' alignItems='center'>
          <IconButton
            aria-label='back'
            sx={{ mr: 0.5 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontWeight: '600' }}>Settings</Typography>
        </Stack>
      </Box>

      <Stack
        sx={{
          p: 3,
          height: 'calc(100% - 60px)',
          overflowY: 'auto',
          overflowX: 'auto',
        }}
      >
        <Stack alignItems='center' spacing={1.5}>
          <Avatar
            sx={{
              width: '100px',
              height: '100px',
              bgcolor: deepPurple[300],
              fontSize: '39px',
            }}
          >
            {activeRoom?.initials}
          </Avatar>

          <Typography sx={{ fontWeight: '600', fontSize: '22px' }}>
            {activeRoom?.name}
          </Typography>
        </Stack>

        <Divider sx={{ my: 2 }} />

        <ChatSharedMediaAccordion />
      </Stack>
    </Box>
  );
}
