import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Alert, Typography } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import ShieldIcon from '@mui/icons-material/Shield';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import EditableTable from '@components/EditableTableComps/EditableTable';
import {
  getAuthHeader,
  formatToUTCDateTime,
} from '@config/functions/helperFunctions';
import { selectProfile } from '@redux/profileSlice';
import Button from '@ui/Button';
import useToast from '@hooks/useToast';
import DeletePromptModal from '@components/DeletePromptModal';
import DisconnectPromptModal from '@components/Communication/DisconnectPromptModal';
import getStatusRejectedPromises from '@config/functions/getStatusRejectedPromises';
import {
  systemUrls,
  formsUrls,
  accountUrls,
  urlShortenerUrls,
  hrUrls,
} from '@config/routes';
import {
  createRecord,
  updateRecord,
  deleteRecord,
} from '@config/functions/requests';
import PageHeader from './PageHeader';
import { useDispatch } from 'react-redux';
import { setAction } from '@redux/operationsSlice';
import formatColName from '@config/functions/removeUnderScoresFromStr';
import useRQuery from '@hooks/useRQuery';
import FilterMenus from '@components/FilterMenus';
import CompanyTerritoryPopup from '@components/CompanyTerritoryPopup';
import AddFromEmailPopup from '@components/Communication/AddFromEmailPopup';
import PersonMarketingListPopup from '@components/PersonMarketingListPopup';
import PersonRelationshipPopup from '@components/PersonRelationshipPopup';
import ReminderPopup from '@components/ReminderPopup';
import CreateShortLinksPopup from '@components/CreateShortLinksPopup';
import ShortLinksPopup from '@components/ShortLinksPopup';
import CreateCompanyOpportunityPopup from '@components/CreateCompanyOpportunityPopup';
import { useQueryClient } from 'react-query';
import SendEmailDialog from '@components/hr/SendEmailDialog';
import AddToCallListPopup from '@components/AddToCallListPopup';
import TaskToSprintPopup from '@components/PM/TaskToSprintPopup';
import { personsMeta } from '@config/meta/crm/index';

const { cloneFormUrls } = formsUrls;
const { usersUrls, rolesUrls } = accountUrls;
const { bulkCreateOrUpdateLinksUrls } = urlShortenerUrls;
const { duplicateRecruitmentPipelinesUrls } = hrUrls;
const { clientsUrls } = systemUrls;

export default function ListView({
  nodeAPI,
  showCreateForm,
  prefix,
  refetchInterval = 0,
  editMode,
  labels,
  urls,
  customInitialValues,
  label = '',
  headerContent,
  getHeaderContent,
  hideTitle,
  pageHeaderFormHeaderText = '',
  pageHeaderFormButtonSuccessText = 'SAVE',
  pageHeaderbuttonText = 'Create',
  getCustomHeaderContent,
  customSubmitBtn,
  addNewForm,
  handleRowsMap,
  rowActions,
  urlParams = '',
  hideActions,
  hideDelete,
  updateRecordCB = () => null,
  microservice = '',
  model = '',
  refetchFunction = () => null,
  cancelBtnHandleFunction = () => null,
  customCancelButton = () => null,
  getRowClassName,
  addFunctions = {},
  handleFunctions,
  customBulkHeaderSelectedRows = [],
  customBulkHeaderButton = () => null,
  view = 'listView',
  startFetching = true,
  customSortColumnName = nodeAPI ? 'createdAt' : 'created_at',
  sortColumnBy = 'desc',
  getDetailPanelContent,
  noHeader,
  autoHeight,
  includeClient = false,
  showVisibility = true,
  showDeleteBtn = true,
  deleteBtnLabel,
  showBulkDeleteButton = true,
  showVisibilityInPageHeader = true,
  showSearch = true,
  importExport = true,
  getListViewURL = (urls, page, searchTerm, pageSize, urlParams, sortColumn) =>
    urls.list(
      `?page=${page}&search=${encodeURIComponent(searchTerm)}&${
        nodeAPI ? 'pageSize' : 'page_size'
      }=${pageSize}${urlParams}&ordering=${
        sortColumn.length
          ? sortColumn[0].sort === 'asc'
            ? sortColumn[0].field
            : `-${sortColumn[0].field}`
          : ''
      }`
    ),
  noAuth,
  dynamicActions = (params) => [],
  onFormCancel,
  customOptionsOnRight,
  tableBulkOptions = [],
  reminderBulkActionType,
  importExportFields,
  createLabel,
  getCustomUrl,
  getListData = () => null,
  refetchOnWindowFocus,
  hideSearch,
  customValues,
  columnKey,
  columnOptions,
  customPageSize,
  ...rest
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [listData, setListData] = useState({ results: [] });
  const [clients, setClients] = useState([]);
  const [clientTerm, setClientTerm] = useState('');
  const [roles, setRoles] = useState([]);
  const [roleTerm, setRoleTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [userTerm, setUserTerm] = useState('');
  const autoPagination =
    typeof window !== 'undefined'
      ? Math.round((window.innerHeight * 0.7) / 36)
      : null;
  const [pageSize, setPageSize] = useState(
    customPageSize || autoPagination || 10
  );

  const user = useSelector(selectProfile);
  const [modal, setModal] = useState(false);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [notify, update] = useToast();
  const [isUpdating, setUpdating] = useState('idle');
  const [sortColumn, setSortColumn] = useState([
    { field: customSortColumnName, sort: sortColumnBy },
  ]);
  const [errors, setErrors] = React.useState({});
  const [showAddReminder, setShowAddReminder] = React.useState(false);
  const [addFromEmail, setAddFromEmail] = React.useState(false);
  const [territoryModal, setTerritoryModal] = React.useState(false);
  const [shortLinksModal, setShortLinksModal] = React.useState(false);
  const [linksModal, setLinksModal] = React.useState(false);
  const [sendEmailModal, setSendEmailModal] = React.useState(false);
  const [addMarketingList, setAddMarketingList] = React.useState(false);
  const [addCallList, setAddCallList] = React.useState(false);
  const [addTaskToSprint, setAddTaskToSprint] = React.useState(false);
  const [personRelationship, setPersonRelationship] = React.useState(false);
  const [companyOpportunityModal, setCompanyOpportunityModal] =
    React.useState(false);
  const queryClient = useQueryClient();

  const { isError, error, data, refetch, isFetching } = useRQuery({
    key: [
      `${label.replace(' ', '-')}-listData`,
      urlParams,
      page,
      searchTerm,
      pageSize,
      sortColumn[0],
      user.token,
      user?.actAs,
    ],
    url: getListViewURL(
      urls,
      page,
      searchTerm,
      pageSize,
      urlParams,
      sortColumn
    ),
    options: { enabled: !!startFetching, refetchInterval: refetchInterval },
    config: noAuth
      ? {}
      : getAuthHeader(user.token, user?.actAs, user.encryptionToken),
  });

  const filterParams =
    urlParams && urlParams.length
      ? Object.fromEntries(new URLSearchParams(urlParams.substring(1)))
      : {};

  const { data: clientsList, isFetching: fetchingClients } = useRQuery({
    key: ['clients', roleTerm, user.token, user?.actAs],
    url: clientsUrls.list(`?search=${clientTerm}`),
    config: getAuthHeader(user.token, user?.actAs, user.encryptionToken),
  });

  const { data: rolesList, isFetching: fetchingRoles } = useRQuery({
    key: ['roles', roleTerm, user.token, user?.actAs],
    url: rolesUrls.list(`?search=${roleTerm}`),
    config: getAuthHeader(user.token, user?.actAs, user.encryptionToken),
  });

  const { data: usersList, isFetching: fetchingUsers } = useRQuery({
    key: ['users', userTerm, user.token, user?.actAs],
    url: usersUrls.list(`?search=${userTerm}&queryCode=fb0c`),
    config: getAuthHeader(user.token, user?.actAs, user.encryptionToken),
    options: { enabled: !!user.token, refetchOnWindowFocus },
  });

  const handleSetErrors = (errorMessages) => {
    setErrors(errorMessages);
    setTimeout(() => setErrors({}), 3000);
  };

  const setUpdatingToDone = () => {
    setUpdating('done');
    setTimeout(() => {
      setUpdating('idle');
    }, 3000);
  };

  const handleCancelButtonClick = () => {
    setModal(false);
    setSelectedRows([]);
  };

  const handleDeleteButtonClick = async (values) => {
    setModal(false);
    try {
      await deleteRecord({
        url: getCustomUrl ? getCustomUrl(values) : urls.detail(values.id),
        token: user.token,
        actAs: user?.actAs,
        encryptionToken: user.encryptionToken,
      });
      refetch();
      refetchFunction();
    } catch (error) {
      console.log(error.response || error.message);
      if (error?.response?.status === 400) {
        // extract error messages from error response
        const errorMessages = {};
        for (const [key, value] of Object.entries(error?.response?.data)) {
          errorMessages[key] = value;
        }
        handleSetErrors(errorMessages);
      } else {
        notify(`There was an error, please try again.`, {
          type: 'ERROR',
        });
      }
    }
  };

  const handleMultipleDeleteClick = async () => {
    setModal(false);
    setSelectedRows([]);
    const promises = selectedRows.map((rowId) => {
      const found = Array.isArray(listData?.results)
        ? listData?.results.find((r) => r.id === rowId)
        : null;

      return deleteRecord({
        url: getCustomUrl ? getCustomUrl({ row: found }) : urls.detail(rowId),
        token: user.token,
        actAs: user?.actAs,
        encryptionToken: user.encryptionToken,
      });
    });
    const rejectedPromises = getStatusRejectedPromises(
      await Promise.allSettled(promises)
    );

    if (rejectedPromises.length) {
      notify('Failed to delete the record', {
        type: 'ERROR',
      });
      return;
    }
    refetch();
    refetchFunction();
    // update(toastId, `${label} Deleted`, {
    //   type: 'SUCCESS',
    // });
  };

  const actions = {
    handleDelete: (values) => async () => {
      setModal({
        open: true,
        onCancelClick: () => handleCancelButtonClick(),
        onDeleteClick: () => handleDeleteButtonClick(values),
      });
    },

    handleUpdate: async (params) => {
      setErrors({});
      try {
        if (!params) return;

        console.log('params', params);

        setUpdating('busy');

        const url = getCustomUrl
          ? getCustomUrl(params)
          : urls.detail(params.id);
        const updatedData = { [params.field]: params.value };

        const values =
          updateRecordCB({
            fn: 'handleUpdate',
            values: updatedData,
          }) || updatedData;

        await updateRecord({
          url,
          values: { ...values, ...customValues },
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        });

        setUpdatingToDone();
        refetch();
        refetchFunction();
      } catch (error) {
        if (error?.response?.status === 400) {
          // extract error messages from error response
          const errorMessages = {};
          for (const [key, value] of Object.entries(error?.response?.data)) {
            errorMessages[key] = value;
          }
          handleSetErrors(errorMessages);
        }
        setUpdating('failed');
      }
    },

    visibilityUpdate: async (values, row) => {
      setErrors({});
      setUpdating('busy');
      try {
        const url = getCustomUrl ? getCustomUrl(row) : urls.detail(values.id);
        await updateRecord({
          url,
          values: values,
          token: user.token,
          actAs: user?.actAs,
          encryptionToken: user.encryptionToken,
        });
        updateRecordCB({ fn: 'visibilityUpdate', values });
        setUpdatingToDone();
        refetch();
        refetchFunction();
      } catch (error) {
        refetch();
        refetchFunction();
        setUpdating('failed');

        console.log(error.response || error.message);

        // extract error messages from error response
        const errorMessages = {};
        for (const [key, value] of Object.entries(error.response.data)) {
          errorMessages[key] = value;
        }
        handleSetErrors(errorMessages);
      }
    },

    onDeleteBtnClick: async () => {
      setModal({
        open: true,
        onCancelClick: () => handleCancelButtonClick(),
        onDeleteClick: () => handleMultipleDeleteClick(),
      });
    },
    ...addFunctions,
  };

  const handleCreateShortLinks = async ({
    selectedRows,
    data,
    setSelectedRows,
  }) => {
    try {
      setShortLinksModal(true);

      await createRecord({
        values: selectedRows.map((row) => {
          const found = data?.results.find((r) => r.id === row);

          return {
            title: found.position_title,
            destination_url: `${window?.location?.origin}/candidate-form/${row}`,
            domain: process.env.REACT_APP_URL_SHORTENER_HOST,
            everyone_in_object_company_can_see_it: true,
            retire: formatToUTCDateTime(
              new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            ),
          };
        }),
        url: bulkCreateOrUpdateLinksUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      setSelectedRows([]);
      notify('Operation successful', {
        type: 'SUCCESS',
      });
    } catch (err) {
      console.log('err', err.response || err.message);
      notify('Failed to create links', {
        type: 'SUCCESS',
      });
    }
  };

  const handleCloneForm = async ({ selectedRows, setSelectedRows }) => {
    const toastId = notify('Cloning....', { loading: true });
    try {
      await createRecord({
        values: {
          formIds: selectedRows,
        },
        url: cloneFormUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      setSelectedRows([]);
      queryClient.invalidateQueries(['Forms-listData']);
      update(toastId, 'Cloned');
    } catch (err) {
      update(toastId, 'Failed to clone', { type: 'ERROR' });
    }
  };

  // const handleCloneCmsPage = async ({ selectedRows, setSelectedRows }) => {
  //   const toastId = notify('Cloning....', { loading: true });
  //   try {
  //     await createRecord({
  //       values: {
  //         pageIds: selectedRows,
  //       },
  //       url: '',
  //       token: user.token,
  //       actAs: user?.actAs,
  //     });

  //     setSelectedRows([]);
  //     queryClient.invalidateQueries(['Cms-page-listData']);
  //     update(toastId, 'Cloned');
  //   } catch (err) {
  //     update(toastId, 'Failed to clone', { type: 'ERROR' });
  //   }
  // };

  const handleMakeACopy = async ({ selectedRows, setSelectedRows }) => {
    const toastId = notify('Cloning....', { loading: true });
    try {
      console.log(selectedRows);

      await createRecord({
        values: {
          pipeline_ids: selectedRows,
        },
        url: duplicateRecruitmentPipelinesUrls.list(),
        token: user.token,
        actAs: user?.actAs,
      });

      setSelectedRows([]);
      queryClient.invalidateQueries(['Recruitment-Pipelines-listData']);
      update(toastId, 'Cloned');
    } catch (err) {
      update(toastId, 'Failed to clone', { type: 'ERROR' });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchInput]);

  useEffect(() => {
    if (!data) return;

    data.results = [
      ...listData.results.slice(0, (page - 1) * pageSize),
      ...data.results.map((r) => {
        if (nodeAPI) {
          return {
            created_by: r.createdBy,
            everyone_can_see_it: r.everyoneCanSeeIt,
            anonymous_can_see_it: r.anonymousCanSeeIt,
            everyone_in_object_company_can_see_it:
              r.everyoneInObjectCompanyCanSeeIt,
            only_these_clients_can_see_it: r.onlyTheseClientsCanSeeIt || {},
            only_these_roles_can_see_it: r.onlyTheseRolesCanSeeIt || {},
            only_these_users_can_see_it: r.onlyTheseUsersCanSeeIt || {},
            ...handleRowsMap(r),
          };
        }

        return {
          created_by: r.created_by,
          everyone_can_see_it: r.everyone_can_see_it,
          anonymous_can_see_it: r.anonymous_can_see_it,
          everyone_in_object_company_can_see_it:
            r.everyone_in_object_company_can_see_it,
          only_these_clients_can_see_it: r.only_these_clients_can_see_it || {},
          only_these_roles_can_see_it: r.only_these_roles_can_see_it || {},
          only_these_users_can_see_it: r.only_these_users_can_see_it || {},
          ...handleRowsMap(r),
        };
      }),
    ];

    getListData(data);
    setListData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(setAction(isUpdating));
  }, [isUpdating]);

  useEffect(() => {
    if (!clientsList) return;
    const clientList = clientsList.results.map((item) => ({
      id: item.id,
      label: item.name,
    }));

    setClients(clientList);
  }, [clientsList]);

  useEffect(() => {
    if (!usersList) return;
    const userList = usersList.results.map((item) => ({
      id: item.id,
      label: item.email,
    }));

    setUsers(userList);
  }, [usersList]);

  useEffect(() => {
    if (!rolesList) return;
    setRoles(
      rolesList.results.map((item) => ({
        id: item.id,
        label: item.name,
      }))
    );
  }, [rolesList]);

  if (isError) {
    return error?.response?.status === 403 &&
      error?.response?.data?.code === 'token_not_valid' ? (
      <p>loading...</p>
    ) : (
      <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
        Failed to fetch the record
      </Typography>
    );
  }

  return (
    <Box>
      {Object.keys(errors).map((key) => (
        <Alert key={key} severity='error' sx={{ mb: 2 }}>
          {formatColName(key)}: {errors[key]}
        </Alert>
      ))}

      {noHeader ? null : (
        <PageHeader
          nodeAPI={nodeAPI}
          label={label}
          columnKey={columnKey}
          columnOptions={columnOptions}
          createLabel={createLabel}
          customOptionsOnRight={customOptionsOnRight}
          customInitialValues={customInitialValues}
          pageHeaderFormHeaderText={pageHeaderFormHeaderText}
          pageHeaderFormButtonSuccessText={pageHeaderFormButtonSuccessText}
          pageHeaderbuttonText={pageHeaderbuttonText}
          getCustomHeaderContent={getCustomHeaderContent}
          customSubmitBtn={customSubmitBtn}
          search={
            showSearch
              ? {
                  value: searchInput,
                  setValue: setSearchInput,
                }
              : false
          }
          addNewForm={addNewForm}
          importExport={importExport}
          refetch={refetch}
          cancelBtnHandleFunction={cancelBtnHandleFunction}
          customCancelButton={customCancelButton}
          clients={clients}
          includeClient={includeClient}
          setClientTerm={setClientTerm}
          fetchingClients={fetchingClients}
          roles={roles}
          setRoleTerm={setRoleTerm}
          fetchingRoles={fetchingRoles}
          users={users}
          setUserTerm={setUserTerm}
          fetchingUsers={fetchingUsers}
          user={user}
          microservice={microservice}
          model={model}
          view={view}
          showVisibilityInPageHeader={showVisibilityInPageHeader}
          headerContent={
            getHeaderContent
              ? getHeaderContent({ selectedRows })
              : headerContent
          }
          prefix={prefix}
          labels={labels}
          editMode={editMode}
          showCreateForm={showCreateForm}
          onFormCancel={onFormCancel}
          importExportFields={importExportFields}
          hideSearch={hideSearch}
          //hideTitle={hideTitle}
        />
      )}

      <Box>
        {selectedRows.length && actions ? (
          <Box>
            <Box
              key='list-view-action-box'
              sx={{ display: 'flex', justifyContent: 'flex-end', mb: '13px' }}
            >
              {customBulkHeaderButton
                ? customBulkHeaderButton(
                    selectedRows,
                    listData,
                    setSelectedRows,
                    searchTerm
                  )
                : null}

              {!showBulkDeleteButton && !tableBulkOptions?.length ? null : (
                <FilterMenus
                  options={[
                    ...tableBulkOptions,
                    {
                      id:
                        showBulkDeleteButton && actions?.onDeleteBtnClick
                          ? 'deleteRecords'
                          : null,
                      text: 'Move to trash',
                      color: 'red',
                    },
                  ]}
                  handleSelect={async (option) => {
                    if (option === 'fetchURLs') {
                      await actions.fetchURLs({
                        selectedRows,
                        searchTerm,
                        selectAll:
                          selectedRows.length === listData?.results?.length,
                        setSelectedRows,
                      });
                    }
                    if (option === 'buildIntelligence') {
                      await actions.buildIntelligence({
                        selectedRows,
                        searchTerm,
                        selectAll:
                          selectedRows.length === listData?.results?.length,
                        setSelectedRows,
                      });
                    }
                    if (option === 'addTaskToSprint') {
                      setAddTaskToSprint(true);
                    }
                    if (option === 'addToMarketingList') {
                      setAddMarketingList(true);
                    }
                    if (option === 'addToCallList') {
                      setAddCallList(true);
                    }
                    if (option === 'createCompanyOpportunity') {
                      setCompanyOpportunityModal(true);
                    }
                    if (option === 'addToPersonRelationship') {
                      setPersonRelationship(true);
                    }
                    if (option === 'addFromEmail') setAddFromEmail(true);
                    if (option === 'addToTerritory') setTerritoryModal(true);
                    if (option === 'addReminder') setShowAddReminder(true);
                    if (option === 'deleteRecords') actions.onDeleteBtnClick();
                    if (option === 'updateShortLinks') setLinksModal(true);
                    if (option === 'sendEmail') setSendEmailModal(true);
                    if (option === 'makeACopy') {
                      handleMakeACopy({ selectedRows, setSelectedRows });
                    }
                    if (option === 'cloneForm') {
                      handleCloneForm({ selectedRows, setSelectedRows });
                    }
                    if (option === 'createShortLinks') {
                      handleCreateShortLinks({
                        selectedRows,
                        data,
                        setSelectedRows,
                      });
                    }
                    // if (option === 'cloneCmsPage') {
                    //   handleCloneCmsPage({ selectedRows, setSelectedRows });
                    // }
                  }}
                />
              )}

              {personRelationship ? (
                <PersonRelationshipPopup
                  prefix='Jb4'
                  searchTerm={searchTerm}
                  open={personRelationship}
                  setOpen={setPersonRelationship}
                  selectedRows={selectedRows}
                  selectAll={selectedRows?.length === listData?.results?.length}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                />
              ) : null}

              {addCallList ? (
                <AddToCallListPopup
                  open={addCallList}
                  setOpen={setAddCallList}
                  rowsDetail={data}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  labels={labels}
                  editMode={editMode}
                  prefix='04k'
                />
              ) : null}

              {sendEmailModal ? (
                <SendEmailDialog
                  open={sendEmailModal}
                  setOpen={setSendEmailModal}
                  selectedRows={selectedRows}
                  isPerson={reminderBulkActionType === 'person'}
                  meta={personsMeta({
                    data:
                      Array.isArray(data?.results) && data?.results?.length
                        ? data.results[0]
                        : null,
                  })}
                  callback={() => {
                    queryClient.invalidateQueries(['Persons-listData']);
                  }}
                  labels={labels}
                  editMode={editMode}
                  prefix='0lr'
                />
              ) : null}

              {companyOpportunityModal ? (
                <CreateCompanyOpportunityPopup
                  open={companyOpportunityModal}
                  setOpen={setCompanyOpportunityModal}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                  prefix='00w'
                />
              ) : null}

              {linksModal ? (
                <ShortLinksPopup
                  open={linksModal}
                  setOpen={setLinksModal}
                  rowsDetail={data}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                  prefix='0fc'
                />
              ) : null}

              {shortLinksModal ? (
                <CreateShortLinksPopup
                  status='loading'
                  open={shortLinksModal}
                  setOpen={setShortLinksModal}
                  editMode={editMode}
                  labels={labels}
                  prefix='0xi'
                />
              ) : null}

              {addTaskToSprint ? (
                <TaskToSprintPopup
                  open={addTaskToSprint}
                  setOpen={setAddTaskToSprint}
                  selectedRows={selectedRows}
                  selectAll={selectedRows.length === listData?.results?.length}
                  searchTerm={searchTerm}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                  rowsDetail={data}
                  filterParams={filterParams}
                />
              ) : null}

              {addMarketingList ? (
                <PersonMarketingListPopup
                  open={addMarketingList}
                  setOpen={setAddMarketingList}
                  selectedRows={selectedRows}
                  selectAll={selectedRows.length === listData?.results?.length}
                  searchTerm={searchTerm}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                  prefix='04n'
                />
              ) : null}

              {addFromEmail ? (
                <AddFromEmailPopup
                  open={addFromEmail}
                  setOpen={setAddFromEmail}
                  selectedRows={selectedRows}
                  selectAll={selectedRows.length === listData?.results?.length}
                  searchTerm={searchTerm}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                  filterParams={filterParams}
                  prefix='yIS'
                />
              ) : null}

              {territoryModal ? (
                <CompanyTerritoryPopup
                  open={territoryModal}
                  setOpen={setTerritoryModal}
                  selectedRows={selectedRows}
                  selectAll={selectedRows.length === listData?.results?.length}
                  searchTerm={searchTerm}
                  setSelectedRows={setSelectedRows}
                  editMode={editMode}
                  labels={labels}
                  prefix='00t'
                />
              ) : null}

              {showAddReminder ? (
                <ReminderPopup
                  type='INA'
                  microservice={microservice}
                  model={model}
                  open={showAddReminder}
                  setOpen={setShowAddReminder}
                  selectedRows={selectedRows}
                  setSelectedRows={() => null}
                  editMode={editMode}
                  labels={labels}
                  prefix='0bs'
                  link={window.location.href}
                />
              ) : null}

              {handleFunctions && handleFunctions.handleAssignRoles && (
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  label='Assign Roles'
                  onClick={() =>
                    handleFunctions.handleAssignRoles(selectedRows, refetch)
                  }
                  sx={{ ml: 3 }}
                  startIcon={<PersonAddAlt1Icon />}
                />
              )}
              {handleFunctions && handleFunctions.handleUnassignRoles && (
                <Button
                  type='button'
                  variant='outlined'
                  color='error'
                  label='Unassign Roles'
                  onClick={() =>
                    handleFunctions.handleUnassignRoles(selectedRows, refetch)
                  }
                  sx={{ ml: 3 }}
                  startIcon={<PersonRemoveAlt1Icon />}
                />
              )}
              {handleFunctions && handleFunctions.onPermissionBtnClick && (
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  label='Permission'
                  onClick={() =>
                    handleFunctions.onPermissionBtnClick(selectedRows, refetch)
                  }
                  sx={{ ml: 3 }}
                  startIcon={<ShieldIcon />}
                />
              )}
              {handleFunctions && handleFunctions.onEnableUserBtnClick && (
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  label='Enable User'
                  onClick={() =>
                    handleFunctions.onEnableUserBtnClick(selectedRows, refetch)
                  }
                  sx={{ ml: 3 }}
                  startIcon={<PersonAddAlt1Icon />}
                />
              )}
              {handleFunctions && handleFunctions.onDisableUserBtnClick && (
                <Button
                  type='button'
                  variant='outlined'
                  color='error'
                  label='Disable User'
                  onClick={() =>
                    handleFunctions.onDisableUserBtnClick(selectedRows, refetch)
                  }
                  sx={{ ml: 3 }}
                  startIcon={<PersonRemoveAlt1Icon />}
                />
              )}
              {handleFunctions && handleFunctions.onApproveUserBtnClick && (
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  label='Approve User'
                  onClick={() =>
                    handleFunctions.onApproveUserBtnClick(selectedRows, refetch)
                  }
                  sx={{ ml: 3 }}
                  startIcon={<PersonAddAlt1Icon />}
                />
              )}
              {handleFunctions && handleFunctions.handleAddTaskBacklog && (
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  label='Add to Task Backlog'
                  onClick={() =>
                    handleFunctions.handleAddTaskBacklog(
                      selectedRows,
                      refetch,
                      setSelectedRows
                    )
                  }
                  sx={{ ml: 3 }}
                />
              )}

              {(handleFunctions && handleFunctions.disableImapAccounts) ||
              (actions && actions.disableImapAccounts) ? (
                <Button
                  type='button'
                  variant='outlined'
                  color='error'
                  label='Disconnect'
                  onClick={() => setDisableModalOpen(true)}
                  sx={{ ml: 3 }}
                  startIcon={<LinkOffIcon />}
                />
              ) : null}

              {handleFunctions && handleFunctions.handleAddTaskBug && (
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  label='Add to Task Bug'
                  onClick={() =>
                    handleFunctions.handleAddTaskBug(
                      selectedRows,
                      refetch,
                      setSelectedRows
                    )
                  }
                  sx={{ ml: 3 }}
                />
              )}
            </Box>
          </Box>
        ) : null}

        <EditableTable
          nodeAPI={nodeAPI}
          columnKey={columnKey}
          columnOptions={columnOptions}
          autoHeight={autoHeight}
          rows={listData.results}
          actions={handleFunctions ? handleFunctions : actions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={page - 1}
          setCurrentPage={setPage}
          totalItems={listData.totalCount}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          deleteBtnLabel={deleteBtnLabel}
          loading={isFetching}
          sortColumn={sortColumn}
          onSortColumnChange={(newSortColumn) => {
            if (Array.isArray(newSortColumn) && newSortColumn.length) {
              setSortColumn(newSortColumn);
            }
          }}
          rowActions={rowActions}
          hideActions={hideActions}
          clients={clients}
          includeClient={includeClient}
          setClientTerm={setClientTerm}
          fetchingClients={fetchingClients}
          roles={roles}
          setRoleTerm={setRoleTerm}
          fetchingRoles={fetchingRoles}
          users={users}
          setUserTerm={setUserTerm}
          fetchingUsers={fetchingUsers}
          microservice={microservice}
          model={model}
          user={user}
          getDetailPanelContent={getDetailPanelContent}
          visibility={showVisibility}
          refetch={refetch}
          showDeleteBtn={showDeleteBtn}
          getRowClassName={
            getRowClassName
              ? getRowClassName
              : (params) => {
                  return params?.row?._color
                    ? `super-app-theme--${params?.row?._color}`
                    : '';
                }
          }
          dynamicActions={dynamicActions}
          editMode={editMode}
          labels={labels}
          prefix={prefix}
          {...rest}
        />
      </Box>

      {!!modal?.open ? (
        <DeletePromptModal
          open={!!modal?.open}
          onCancelClick={modal.onCancelClick}
          onDeleteClick={modal.onDeleteClick}
          editMode={editMode}
          labels={labels}
          prefix='0cv'
          prefix2='0kp'
        />
      ) : null}

      {(handleFunctions && handleFunctions.disableImapAccounts) ||
      (actions && actions.disableImapAccounts) ? (
        <DisconnectPromptModal
          open={disableModalOpen}
          onCancelClick={() => {
            setSelectedRows([]);
            setDisableModalOpen(false);
          }}
          onDisableClick={() =>
            (
              (handleFunctions && handleFunctions.disableImapAccounts) ||
              (actions && actions.disableImapAccounts)
            )(
              selectedRows,
              refetch,
              setSelectedRows,
              disableModalOpen,
              setDisableModalOpen,
              urls
            )
          }
          editMode={editMode}
          labels={labels}
          prefix='0yl'
          prefix2='0wy'
        />
      ) : null}
    </Box>
  );
}
