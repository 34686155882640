import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Typography, IconButton } from '@mui/material';
import ShapeAction from '@components/BPA-V2/ShapeActions/ShapeAction';

export default function ShapeActionTypeCard({
  type,
  shape,
  workflowId,
  actionsList,
  initialShapeActions,
  workflowName,
}) {
  const [shapeActions, setShapeActions] = React.useState(
    initialShapeActions ?? []
  );

  React.useEffect(() => {
    setShapeActions(initialShapeActions);
  }, [initialShapeActions]);

  return (
    <Box
      sx={{
        py: 1,
        px: 1.5,
        mb: 2,
        background: '#DFDFDF',
        borderRadius: '4px',
        minHeight: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: '500' }}>{type}</Typography>

        <IconButton
          color='primary'
          component='span'
          onClick={() => {
            const stage = shape?.details?.stage;
            const name = `${shape?.stage_full_order ?? ''}${stage?.order} ${
              stage?.name
            } (${shapeActions?.length + 1})`;

            setShapeActions((state) => [
              ...state,
              {
                id: uuidv4(),
                name,
                when: type,
                shape: shape?.id,
                action: null,
                created: false,
              },
            ]);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <Divider sx={{ py: 0.5 }} />

      {shapeActions.map((shapeAction) => (
        <ShapeAction
          key={shapeAction?.id}
          shape={shape}
          workflowId={workflowId}
          actionsList={actionsList}
          shapeAction={shapeAction}
          setShapeActions={setShapeActions}
          workflowName={workflowName}
        />
      ))}
    </Box>
  );
}
