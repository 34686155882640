import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import RelationShips from '@pages/Admin/RelationShips';
import Clients from '@pages/Admin/Clients';
import ClientsDetail from '@pages/Admin/Clients/detail';

export default function () {
  return (
    <>
      <Route
        path="/relation-ships"
        element={<WithLayout comp={RelationShips} />}
      />
      <Route path="/admin-clients" element={<WithLayout comp={Clients} />} />
      <Route
        path="/admin-clients/:id"
        element={<WithLayout comp={ClientsDetail} />}
      />
    </>
  );
}
