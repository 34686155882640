export const interviewNoteRowsMap = (c) => ({
  id: c.id,
  rating: c.rating,
  interview_name: c.interview_name,
  current_salary: c.current_salary,
  expected_salary: c.expected_salary,
  current_location: c.current_location,
  relevant_skills: c.relevant_skills,
  initial_skills: c.initial_skills,
  initial_impression: c.initial_impression,
  motivations: c.motivations,
  reason_for_leaving_current_job: c.reason_for_leaving_current_job,
  recommendations: c.recommendations,
  created_at: c.created_at,
});

export const assessmentRowsMap = (c) => ({
  id: c.id,
  rating: c.rating,
  notes: c.notes,
  vacancy: c.vacancy_name,
  attachment: c.attachment,
  created_at: c.created_at,
  created_by: c?.details?.created_by?.email
});

export const keywordRowsMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: c.created_at,
});

export const recruitmentPipelineRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});

export const vacancyStatusRowsMap = (r) => ({
  id: r.id,
  order: r.order,
  name: r.name,
  rotting_days: r.rotting_days,
  description: r.description,
  created_at: r.created_at,
});

export const courseHandleRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: c.created_at,
});

export const skillLevelHandleRowMap = (c) => ({
  id: c.id,
  level: c.level,
  description: c.description,
  created_at: c.created_at,
});

export const rolesHandleRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  min_salary_pm: c.min_salary_pm,
  max_salary_pm: c.max_salary_pm,
  created_at: c.created_at,
});

export const employeesHandleRowMap = (c) => ({
  id: c.id,
  person:
    c.person && c.details && c.details.person
      ? { id: c.person, label: c.details.person.first_name }
      : c.person,
  cv: c.cv,
  work_email: c.work_email,
  work_phone: c.work_phone,
  next_of_kin_email: c.next_of_kin_email,
  next_of_kin_phone: c.next_of_kin_phone,
  charge_rate: c.charge_rate,
  date_joined: c.date_joined,
  date_left: c.date_left,
  created_at: c.created_at,
  status:
    c.status && c.details && c.details.status
      ? { id: c.status, label: c.details.status.name }
      : c.status,
});

export const vacancyRowsMap = (r) => ({
  id: r.id,
  position_title: r.position_title,
  recruit_for:
    r.recruit_for && r.details && r.details.recruit_for
      ? { id: r.recruit_for, label: r.details.recruit_for.name }
      : r.recruit_for,
  location:
    r.location && r.details && r.details.location
      ? { id: r.location, label: r.details.location.name }
      : r.location,
  recruitment_pipeline:
    r.recruitment_pipeline && r.details && r.details.recruitment_pipeline
      ? {
        id: r.recruitment_pipeline,
        label: r.details.recruitment_pipeline.name,
      }
      : null,
  candidate_count: r.candidate_count,
  workflow: r?.details?.workflow
    ? {
      id: r?.workflow,
      label: r.details.workflow?.name,
    }
    : null,
  min_pay: r.min_pay,
  max_pay: r.max_pay,
  currency: r.currency,
  status: r.status,
  created_at: r.created_at,
});

export const departmentHandleRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  department_manager:
    c.department_manager && c.details && c.details.department_manager
      ? { id: c.department_manager, label: c.details.department_manager.work_email }
      : c.department_manager,
  parent_department:
    c.details && c.details.parent_department
      ? { id: c.parent_department, label: c.details.parent_department.name }
      : null,
  created_at: c.created_at,
});

export const departmenEmployeeHandleRowMap = (c) => ({
  id: c.id,
  employee:
    c.employee && c.details && c.details.employee
      ? { id: c.employee, label: c.details.employee.work_email }
      : c.employee,
  created_at: c.created_at,
});

export const skillHandleRowMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: c.created_at,
});

export const rolehistoryHandleRowMap = (c) => ({
  id: c.id,
  start_date: c.start_date,
  end_date: c.end_date,
  employee:
    c.employee && c.details && c.details.employee
      ? { id: c.employee, label: c.details.employee.work_email }
      : c.employee,
  supervisor_emp:
    c.supervisor_emp && c.details && c.details.supervisor_emp
      ? { id: c.supervisor_emp, label: c.details.supervisor_emp.work_email }
      : c.supervisor_emp,
  created_at: c.created_at,
});

export const employeeDepartmentHandleRowMap = (c) => ({
  id: c.id,
  start_date: c.start_date,
  end_date: c.end_date,
  department:
    c.department && c.details && c.details.department
      ? { id: c.department, label: c.details.department.name }
      : c.department,
  created_at: c.created_at,
});

export const employeeSkillHandleRowMap = (c) => ({
  id: c.id,
  date_acquired: c.date_acquired,
  employee:
    c.employee && c.details && c.details.employee
      ? { id: c.employee, label: c.details.employee.work_email }
      : c.employee,
  skill_level:
    c.skill_level && c.details && c.details.skill_level
      ? { id: c.skill_level, label: c.details.skill_level.level }
      : c.skill_level,
  created_at: c.created_at,
});

export const candidateRowsMap = (c) => ({
  id: c.id,
  first_name: c.first_name,
  last_name: c.last_name,
  currently_employed: c.currently_employed,
  current_employer: c.current_employer,
  dob: c.dob,
  person: c.person,
  personal_mobile: c.personal_mobile,
  personal_email: c.personal_email,
  address1: c.address1,
  address2: c.address2,
  cv: c.cv,
  visa_required: c.visa_required,
  years_of_experience: c.years_of_experience,
  cover_letter: c.cover_letter,
  city: c.city,
  country: c.country,
  status:
    c.status && c.details && c.details.status
      ? { id: c.status, label: c.details.status.name }
      : null,
  created_at: c.created_at,
  _tags: c._tags,
});

export const referencesRowMap = (c) => ({
  id: c.id,
  first_name: c.first_name,
  last_name: c.last_name,
  phone: c.phone,
  email: c.email,
  type: c.type,
  reference_result: c.reference_result,
  checked_by:
    c.checked_by && c.details && c.details.checked_by
      ? { id: c.checked_by, label: c.details.checked_by.work_email }
      : c.checked_by,
});

export const salaryHistoryRowsMap = (c) => ({
  id: c.id,
  salary_pm: c.salary_pm,
  start_date: c.start_date,
  end_date: c.end_date,
  created_at: c.created_at,
});

export const trainingHistoryRowsMap = (c) => ({
  id: c.id,
  date_taken: c.date_taken,
  result: c.result,
  created_at: c.created_at,
});

export const holidayEntitlementRowsMap = (c) => ({
  id: c.id,
  start_date: c.start_date,
  max_holidays_pa: c.max_holidays_pa,
  created_at: c.created_at,
});

export const daysOffRowsMap = (c) => ({
  id: c.id,
  days_requested: c.days_requested,
  status: c.status,
  type:
    c.type && c.details && c.details.type
      ? { id: c.type, label: c.details.type.name }
      : null,
  notes: c.notes,
  approved_by:
    c.approved_by && c.details && c.details.approved_by
      ? { id: c.approved_by, label: c.details.approved_by.work_email }
      : null,
  days_off_from: c.days_off_from,
  created_at: c.created_at,
});

export const performanceReviewRowsMap = (c) => ({
  id: c.id,
  date_of_review: c.date_of_review,
  comments_by_reviewer: c.comments_by_reviewer,
  comments_by_employee: c.comments_by_employee,
  review_form: c.review_form,
  reviewed_by:
    c.reviewed_by && c.details && c.details.reviewed_by
      ? { id: c.reviewed_by, label: c.details.reviewed_by.work_email }
      : c.reviewed_by,
  created_at: c.created_at,
});

export const recruitmentPipelineStagesRowsMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  order: c.order,
  rotting_days: c.rotting_days,
  email_template: c?.details?.email_template
    ? { id: c.email_template, label: c.details.email_template?.name }
    : null,
  created_at: c.created_at,
});

export const jobLevelRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});

export const dayOffTypeRowsMap = (r) => ({
  id: r.id,
  name: r.name,
  description: r.description,
  created_at: r.created_at,
});


export const employeeStatusRowsMap = (c) => ({
  id: c.id,
  name: c.name,
  description: c.description,
  created_at: c.created_at,
});