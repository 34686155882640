import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { systemUrls } from '@config/routes';
import { clientsRowsMap } from '@config/handleRows/system';
import { microservices } from '@config/constants';
import PublicUserErrorScreen from '@components/CMS-V2/PublicUserErrorScreen';
import { selectProfile } from '@redux/profileSlice';
import { useSelector } from 'react-redux';

const { clientsUrls } = systemUrls;

export default function ShapeTransitionLog({ labels, editMode }) {
  const user = useSelector(selectProfile);

  if (
    Array.isArray(user?.details?.roles_names) &&
    !(
      user?.details?.roles_names.includes('Superusers') ||
      user?.details?.is_superuser
    )
  ) {
    return <PublicUserErrorScreen />;
  }

  return (
    <Box>
      <ListView
        label='Clients'
        prefix='Fn1'
        labels={labels}
        editMode={editMode}
        microservice={microservices.System.name}
        model={microservices.System.models.client}
        urls={clientsUrls}
        columnKey='clientSystem'
        handleRowsMap={clientsRowsMap}
        customSortColumnName='created_at'
        showBulkDeleteButton={false}
        hideActions
      />
    </Box>
  );
}
