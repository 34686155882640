import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import Microservice from '@pages/Compute/Microservice';
import MicroserviceDetail from '@pages/Compute/Microservice/detail';
import ModelDetail from '@pages/Compute/Model/detail';
import FieldDetail from '@pages/Compute/Field/detail';
import EnumDetail from '@pages/Compute/Enum/detail';
import BlockGroup from '@pages/Compute/BlockGroup';
import BlockGroupDetail from '@pages/Compute/BlockGroup/detail';

export default function () {
  return (
    <>
      <Route
        path='/microservice'
        element={<WithLayout comp={Microservice} />}
      />

      <Route
        path='/microservice/:id'
        element={<WithLayout comp={MicroserviceDetail} />}
      />

      <Route path='/models/:id' element={<WithLayout comp={ModelDetail} />} />

      <Route path='/fields/:id' element={<WithLayout comp={FieldDetail} />} />

      <Route path='/enums/:id' element={<WithLayout comp={EnumDetail} />} />

      <Route path='/block-groups' element={<WithLayout comp={BlockGroup} />} />
      <Route
        path='/block-groups/:id'
        element={<WithLayout comp={BlockGroupDetail} />}
      />
    </>
  );
}
