import React from 'react';
import { Box, Stack, Divider, Typography, Avatar, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { deepPurple } from '@mui/material/colors';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import ChatAddMembersPopup from '@components/Chat/ChatAddMembersPopup/ChatAddMembersPopup';
import ChatRemoveMemberPopup from '@components/Chat/ChatRemoveMemberPopup/ChatRemoveMemberPopup';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPersonNameAndInitials } from '@config/functions/chatFunctions';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import EditIcon from '@mui/icons-material/Edit';
import ChatMessageEditPopup from '@components/Chat/ChatEditGroupNamePopup/ChatEditGroupNamePopup';
import ChatDeleteGroupPopup from '@components/Chat/ChatDeleteGroupPopup/ChatDeleteGroupPopup';
import ChatSharedMediaAccordion from '@components/Chat/ChatSharedMediaAccordion/ChatSharedMediaAccordion';

const INITIAL_REMOVE_STATE = {
  open: false,
  memberId: null,
};

export default function GroupChatSettings({ activeRoom, setOpen }) {
  const user = useSelector(selectProfile);
  const [editGroupNameOpen, setEditGroupNameOpen] = React.useState(false);
  const [addMembersOpen, setAddMembersOpen] = React.useState(false);
  const [deleteGroupOpen, setDeleteGroupOpen] = React.useState(false);
  const [removeMember, setRemoveMember] = React.useState(INITIAL_REMOVE_STATE);

  const isCreatedByUser = user?.details?.id === activeRoom?.createdBy;
  const userPersonId = user?.details?.details?.person?.id;

  const existingMemberIds = React.useMemo(() => {
    if (!Array.isArray(activeRoom.roomMembers)) {
      return [];
    }

    return activeRoom.roomMembers.map(({ memberId }) => memberId);
  }, [activeRoom]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: '99',
        height: '100%',
        background: '#fff',
        width: { sm: '490px' },
        borderLeft: '1px solid rgba(11, 20, 26, .13)',
      }}
    >
      <Box
        sx={{
          background: '#F0F2F5',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 16px',
        }}
      >
        <Stack direction='row' alignItems='center'>
          <IconButton
            aria-label='back'
            sx={{ mr: 0.5 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontWeight: '600' }}>Settings</Typography>
        </Stack>
      </Box>

      <Stack
        sx={{
          p: 3,
          height: 'calc(100% - 60px)',
          overflowY: 'auto',
          overflowX: 'auto',
        }}
      >
        <Stack alignItems='center' spacing={1.5}>
          <Avatar
            sx={{
              width: '100px',
              height: '100px',
              bgcolor: deepPurple[300],
              fontSize: '18px',
            }}
          >
            <GroupIcon sx={{ fontSize: '54px' }} />
          </Avatar>

          <Stack direction='row' spacing={1}>
            <Typography sx={{ fontWeight: '600', fontSize: '22px' }}>
              {activeRoom?.name}
            </Typography>

            {isCreatedByUser ? (
              <IconButton
                aria-label='edit-group-name'
                size='small'
                onClick={() => setEditGroupNameOpen(true)}
              >
                <EditIcon fontSize='inherit' />
              </IconButton>
            ) : null}
          </Stack>
        </Stack>

        {editGroupNameOpen ? (
          <ChatMessageEditPopup
            open={editGroupNameOpen}
            setOpen={setEditGroupNameOpen}
            initialName={activeRoom?.name}
            roomId={activeRoom?.id}
          />
        ) : null}

        <Divider sx={{ my: 2 }} />

        <ChatSharedMediaAccordion />

        <Divider sx={{ my: 2 }} />

        <Stack spacing={3}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
              {activeRoom?.roomMembers?.length} Members
            </Typography>

            {isCreatedByUser ? (
              <Button
                variant='outlined'
                size='small'
                startIcon={<AddIcon />}
                onClick={() => setAddMembersOpen(true)}
              >
                Add members
              </Button>
            ) : null}
          </Stack>

          <Stack
            spacing={2}
            sx={{ maxHeight: '336px', overflowY: 'auto', overflowX: 'auto' }}
          >
            {Array.isArray(activeRoom?.roomMembers) &&
              activeRoom?.roomMembers.map((member) => {
                const { initials, fullName } = getPersonNameAndInitials(
                  member?.details?.person
                );

                return (
                  <Stack
                    key={member?.id}
                    direction='row'
                    justifyContent='space-between'
                  >
                    <Stack direction='row' alignItems='center' spacing={1.5}>
                      <Avatar sx={{ fontSize: '15px' }}>{initials}</Avatar>
                      <Typography sx={{ fontWeight: '600' }}>
                        {fullName}
                      </Typography>
                    </Stack>

                    {isCreatedByUser && member?.memberId !== userPersonId ? (
                      <IconButton
                        aria-label='delete'
                        onClick={() =>
                          setRemoveMember({ open: true, memberId: member?.id })
                        }
                        size='small'
                      >
                        <DeleteIcon fontSize='inherit' />
                      </IconButton>
                    ) : null}
                  </Stack>
                );
              })}
          </Stack>

          <Divider sx={{ my: 2 }} />

          {isCreatedByUser ? (
            <Button
              variant='text'
              color='error'
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteGroupOpen(true)}
            >
              Delete Group
            </Button>
          ) : null}
        </Stack>
      </Stack>

      {deleteGroupOpen ? (
        <ChatDeleteGroupPopup
          open={deleteGroupOpen}
          roomId={activeRoom?.id}
          onCancelClick={() => setDeleteGroupOpen(false)}
        />
      ) : null}

      {addMembersOpen ? (
        <ChatAddMembersPopup
          open={addMembersOpen}
          setOpen={setAddMembersOpen}
          activeRoom={activeRoom}
          existingMemberIds={existingMemberIds}
        />
      ) : null}

      {removeMember?.open ? (
        <ChatRemoveMemberPopup
          open={removeMember?.open}
          memberId={removeMember?.memberId}
          onCancelClick={() => setRemoveMember(INITIAL_REMOVE_STATE)}
        />
      ) : null}
    </Box>
  );
}
