import React from 'react';
import { Box } from '@mui/material';
import useChatSocket from '@hooks/useChatSocket';
import MainChat from '@components/Chat/MainChat/MainChat';
import { useDispatch, useSelector } from 'react-redux';
import {
  RoomStatus,
  selectChat,
  setActiveRoomState,
  setReplyTo,
  setRooms,
  setActiveRoom,
} from '@redux/chatSlice';
import {
  ensureSocketConnected,
  joinChatRoom,
  requestNotificationPermission,
} from '@config/functions/chatFunctions';
import { selectProfile } from '@redux/profileSlice';
import useRQuery from '@hooks/useRQuery';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { chatUrls } from '@config/routes';
import ErrorScreen from '@components/ErrorScreen';

const { roomsByPersonUrls } = chatUrls;

export default function CompanyChat() {
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const userPersonId = user?.details?.details?.person?.id;

  const {
    activeRoom,
    replyTo,
    activeRoomState,
    userMemberId,
    // prettier xxx
  } = useSelector(selectChat);

  const { data, isError, isLoading } = useRQuery({
    key: ['company-chat-rooms', userPersonId, user?.token, user?.actAs],
    url: roomsByPersonUrls.detail(`${userPersonId}/CompanyChat`),
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { socket } = useChatSocket({ accessToken: user?.token, userPersonId });

  React.useEffect(() => {
    // Return early if there's an error, loading state, or if the dispatch has already occurred
    if (isError || isLoading) return;

    // Ensure data is an array before dispatching
    if (Array.isArray(data)) {
      dispatch(setRooms(data));
    }
  }, [isError, isLoading, data, dispatch]);

  React.useEffect(() => {
    const checkAndRequestPermission = async () => {
      await requestNotificationPermission();
    };

    checkAndRequestPermission();
  }, []);

  React.useEffect(() => {
    dispatch(setActiveRoom(null));
  }, []);

  const onMessageItemClick = async (item) => {
    const isRoomActiveOrLoading = (roomId) => {
      const isRoomLoading = activeRoomState?.status === RoomStatus?.Loading;
      const isActiveRoomCurrent = activeRoom?.id === roomId;
      const isLoadingRoomCurrent = activeRoomState?.roomId === roomId;
      return isActiveRoomCurrent || (isRoomLoading && isLoadingRoomCurrent);
    };

    try {
      if (isRoomActiveOrLoading(item?.id)) {
        return;
      }
      dispatch(
        setActiveRoomState({
          status: RoomStatus.Loading,
          roomId: item?.id,
        })
      );
      joinChatRoom(socket, item?.id);
    } catch (error) {
      // TODO: handle error properly
      console.error('Failed to create or join chat room:', error);
      dispatch(setActiveRoomState({ status: RoomStatus.Error }));
    }
  };

  const onSendMessage = ({ message, attachmentId }) => {
    ensureSocketConnected(socket);

    socket.emit('sendMessage', {
      roomId: activeRoom?.id,
      authorId: userMemberId,
      content: message,
      replyToId: replyTo?.id,
      attachmentId,
    });

    if (replyTo) {
      dispatch(setReplyTo(null));
    }
  };

  const onDeleteMessage = (messageId) => {
    ensureSocketConnected(socket);

    socket.emit('deleteMessage', {
      roomId: activeRoom?.id,
      messageId: messageId,
    });
  };

  const onEditMessage = (newMessage, messageId) => {
    ensureSocketConnected(socket);

    socket.emit('editMessage', {
      roomId: activeRoom?.id,
      messageId,
      content: newMessage,
    });
  };

  const onMessageRead = (messageId) => {
    // ensureSocketConnected(socket);
    // socket.emit('markMessageAsRead', {
    //   roomId: activeRoom?.id,
    //   messageId,
    //   readById: userMemberId,
    //   userPersonId,
    // });
  };

  if (isError) {
    return <ErrorScreen error="Failed to fetch rooms." />;
  }

  return (
    <Box>
      <MainChat
        loadingRooms={!!(isLoading || !Array.isArray(data))}
        socket={socket}
        userPersonId={userPersonId}
        onMessageItemClick={onMessageItemClick}
        onSendMessage={onSendMessage}
        onDeleteMessage={onDeleteMessage}
        onEditMessage={onEditMessage}
        onMessageRead={onMessageRead}
      />
    </Box>
  );
}
