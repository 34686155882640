import React from 'react';
import LeftDrawerBase from '@components/core/LeftDrawerBase';
import DrawerItem from '@components/core/DrawerItem';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { selectProfile } from '@redux/profileSlice';
import getLinks from '@config/drawerLinks';
import { useSelector } from 'react-redux';
import RenderUiPrefix from '@components/core/RenderUiPrefix';
import { encryptionPassKey } from '@config/constants';
import useRQuery from '@hooks/useRQuery';
import {
  wikiUrls,
  communicationUrls,
  accountUrls,
  calendarUrls,
} from '@config/routes';
import { Box, Divider } from '@mui/material';

const { booksUrls, chaptersUrls } = wikiUrls;
const { imapAccountsUrls } = communicationUrls;
const { microservicesUrls } = accountUrls;
const { calendarAccountsUrls } = calendarUrls;

export default function LeftDrawer({
  onDrawerToggle,
  mobileOpen,
  labels,
  editMode,
  language,
  laptopOpen,
}) {
  const user = useSelector(selectProfile);
  const linksConfig = {
    roles: Array.isArray(user?.details?.roles_names)
      ? user.details.roles_names
      : [],
  };
  const [links, setLinks] = React.useState(getLinks(linksConfig).live);
  const [encrptnToken, setEncrptnToken] = React.useState('');

  const {
    data: imapAcctsList,
    isLoading: loadingImapAccts,
    isFetching: fetchingImapAccts,
  } = useRQuery({
    key: [`drawer-imap-accts-${user.token}`, user.token, user?.actAs],
    url: imapAccountsUrls.list('?page_size=25'),
    config: getAuthHeader(
      user.token,
      user?.actAs,
      user.encryptionToken || encrptnToken
    ),
    options: {
      enabled:
        user.details && user.details.encryption_public_key
          ? !!(user.encryptionToken || encrptnToken)
          : true,
    },
  });

  const { data: microservicesList } = useRQuery({
    key: ['drawer-microservices-accts', user.token, user?.actAs],
    url: microservicesUrls.list('?page_size=35'),
    config: getAuthHeader(user.token, user?.actAs),
    options: { enabled: !!user.token },
  });

  const {
    data: booksList,
    isLoading: loadingBooks,
    isFetching: fetchingBooks,
  } = useRQuery({
    key: ['left-drawer-book', user.token, user?.actAs],
    url: booksUrls.list('?page_size=25&published=true'),
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: chaptersList } = useRQuery({
    key: ['chapters-leftDrawer', user.token, user?.actAs],
    url: chaptersUrls.list('?page_size=500&ordering=order'),
    config: getAuthHeader(user.token, user?.actAs),
  });

  const { data: calendarAccountsList } = useRQuery({
    key: ['drawer-calendar-accounts', user.token, user?.actAs],
    url: calendarAccountsUrls.list('?page_size=35&primary=true'),
    config: getAuthHeader(user.token, user?.actAs),
  });

  React.useEffect(() => {
    if (!calendarAccountsList || !Array.isArray(calendarAccountsList.results)) {
      return;
    }

    const calendarAccounts = calendarAccountsList.results.map(
      (calendarAccount) => ({
        text: calendarAccount.email,
        dropdown: [
          // {
          //   text: 'All Sub Calendars',
          //   href: `/all-calendars/${calendarAccount.id}`,
          // },
          ...calendarAccount.calendars.map((calendar) => ({
            text: calendar.name,
            href: `/calendar/${calendar.id}`,
          })),
        ],
      })
    );

    setLinks((state) =>
      state.map((link) => {
        if (link.text === 'Calendar') {
          const unifiedAccts = link.dropdown.map((r) => {
            if (r.text === 'Calendars') {
              return {
                ...r,
                dropdown: [
                  {
                    text: 'All Calendars',
                    href: '/all-calendars',
                  },
                  ...calendarAccounts,
                ],
              };
            }
            return r;
          });

          const dropdowns = [...unifiedAccts];

          const isRoleAdmin =
            Array.isArray(user.details.roles_names) &&
            user.details.roles_names.includes('Company Administrator');

          if (isRoleAdmin) {
            dropdowns.push({
              text: 'Configure Calendar',
              href: '/configure-calendar',
            });
          }

          return {
            ...link,
            dropdown: dropdowns,
            mutated: true,
          };
        }
        return link;
      })
    );
  }, [calendarAccountsList]);

  React.useEffect(() => {
    if (loadingImapAccts || fetchingImapAccts || !imapAcctsList) return;

    const connectedAccts = [];
    const disconnectedAccts = [];

    imapAcctsList.results.forEach((acct) => {
      const acctObj = {
        text: acct.display_name || acct.username,
        dropdown: [
          {
            text: 'Inbox',
            href: `/inbox-emails/${acct.id}`,
          },
          {
            text: 'Sent',
            href: `/sent-emails/${acct.id}`,
          },
          {
            text: 'Signature',
            href: `/signature/${acct.id}`,
          },
        ],
      };

      if (acct.connected) {
        connectedAccts.push(acctObj);
      } else {
        disconnectedAccts.push(acctObj);
      }
    });

    setLinks((state) =>
      state.map((link) => {
        if (link.text === 'Communication') {
          const updatedDropdown = link.dropdown.map((r) => {
            if (r.text === 'Emails') {
              const newDropdown = r.dropdown.find(
                (r) => r.text === 'Disconnected Accounts'
              );
              newDropdown.dropdown = [...disconnectedAccts];

              return {
                ...r,
                dropdown: [
                  {
                    text: 'All Emails',
                    href: '/all-emails',
                  },
                  ...connectedAccts,
                  newDropdown,
                ],
              };
            }

            return r;
          });

          return {
            ...link,
            dropdown: [...updatedDropdown],
            mutated: true,
          };
        }

        return link;
      })
    );
  }, [imapAcctsList, fetchingImapAccts, loadingImapAccts]);

  React.useEffect(() => {
    const token = localStorage.getItem(encryptionPassKey);
    if (token) setEncrptnToken(token);
  }, []);

  React.useEffect(() => {
    if (!microservicesList || !microservicesList.results) return;

    const newLinks = links.map((link) => {
      if (link.text === 'System') {
        let newDropdown = link.dropdown;

        newDropdown = newDropdown.map((r) => {
          if (r.text === 'ERDs') {
            r.dropdown = microservicesList.results.map((b) => ({
              text: b.name,
              href: `/erds/${b.id}`,
            }));
          }
          return {
            ...r,
            newDropdown,
            mutated: true,
          };
        });
        return {
          ...link,
          newDropdown,
        };
      }
      return link;
    });

    const link = links.find((r) => r.text === 'System');
    if (link && !link.mutated) {
      setLinks(newLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microservicesList]);

  React.useEffect(() => {
    if (
      !booksList ||
      !booksList.results ||
      !chaptersList ||
      !chaptersList.results ||
      fetchingBooks ||
      loadingBooks
    ) {
      return;
    }

    const newLinks = links.map((link) => {
      if (link.text !== 'Wiki') {
        return link;
      }

      const newDropdown = link.dropdown.map((r) => {
        if (r.text !== 'Books') {
          return r;
        }

        return {
          ...r,
          dropdown: booksList.results.map((b) => ({
            text: b.name,
            dropdown: chaptersList.results
              .filter((chapter) => chapter.book === b.id)
              .map((c) => ({
                key: c.name,
                text: c.name,
                href: `/wiki/${c.id}`,
              })),
          })),
        };
      });

      return {
        ...link,
        dropdown: newDropdown,
      };
    });

    setLinks(newLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booksList, chaptersList, fetchingBooks, loadingBooks]);

  const renderSubmenu = (link, padLeft = 6) => {
    if (
      !link.dropdown ||
      !Array.isArray(link.dropdown) ||
      !link.dropdown.length
    ) {
      return null;
    }

    return link.dropdown.map((subLink, index) => {
      if (
        subLink.text === 'Vacancy Page' &&
        (user?.details?.client_details?.name !== 'Pullstream' ||
          (user?.actAs?.id && user?.actAs?.name !== 'Pullstream'))
      ) {
        return null;
      }

      if (
        subLink.href === '/templates' &&
        Array.isArray(user?.details?.roles_names) &&
        !user?.details?.roles_names.includes('Template Admins')
      ) {
        return null;
      }

      if (
        subLink.text === 'Subdomains' &&
        Array.isArray(user?.details?.roles_names) &&
        !(
          user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Company Administrator') ||
          user?.details?.is_superuser ||
          user?.details?.roles_names.includes('Template Admins')
        )
      ) {
        return null;
      }

      if (
        subLink.text === 'Page Templates' &&
        Array.isArray(user?.details?.roles_names) &&
        !(
          user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Company Administrator') ||
          user?.details?.is_superuser ||
          user?.details?.roles_names.includes('Template Admins')
        )
      ) {
        return null;
      }

      if (
        subLink.href === '/users' &&
        Array.isArray(user?.details?.roles_names) &&
        !(
          user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Company Administrator') ||
          user?.details?.is_superuser
        )
      ) {
        return null;
      }

      if (
        subLink.text === 'Logs' &&
        Array.isArray(user?.details?.roles_names) &&
        !(
          user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Can See Object Logs') ||
          user?.details?.is_superuser
        )
      ) {
        return null;
      }

      if (
        subLink.text === 'System Logs' &&
        Array.isArray(user?.details?.roles_names) &&
        !(
          user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Can See System Logs') ||
          user?.details?.is_superuser
        )
      ) {
        return null;
      }

      if (
        subLink.text === 'Visitor Logs' &&
        Array.isArray(user?.details?.roles_names) &&
        !(
          user?.details?.roles_names.includes('Superusers') ||
          user?.details?.roles_names.includes('Marketing') ||
          user?.details?.is_superuser
        )
      ) {
        return null;
      }

      return (
        <DrawerItem
          {...subLink}
          padLeft={padLeft}
          key={`${index}-${subLink.href}`}
          expandable={subLink.dropdown && subLink.dropdown.length}
          dropdown={renderSubmenu(subLink, padLeft + 2)}
        />
      );
    });
  };

  const renderLinks = (link, index) => {
    let itemProps = {
      key: `${index}-${link.href}`,
      icon: link.icon,
      href: link.href,
      expandable: link.dropdown && link.dropdown.length,
    };

    if (
      link.text === 'System' &&
      Array.isArray(user?.details?.roles_names) &&
      !(
        user?.details?.roles_names.includes('Superusers') ||
        user?.details?.is_superuser
      )
    ) {
      return null;
    }

    if (
      link.text === 'Accounts' &&
      Array.isArray(user?.details?.roles_names) &&
      !(
        user?.details?.roles_names.includes('Superusers') ||
        user?.details?.is_superuser ||
        user?.details?.roles_names.includes('Company Administrator')
      )
    ) {
      return null;
    }

    if (link.prefix) {
      return (
        <RenderUiPrefix
          key={`${index}-${link.href}`}
          editMode={editMode}
          labels={labels}
          language={language}
          code={link.prefix}
        >
          {(text) => (
            <DrawerItem
              {...itemProps}
              text={text}
              dropdown={renderSubmenu(link)}
            />
          )}
        </RenderUiPrefix>
      );
    }

    return (
      <DrawerItem
        {...itemProps}
        text={link.text}
        dropdown={renderSubmenu(link)}
      />
    );
  };

  return (
    <LeftDrawerBase
      onDrawerToggle={onDrawerToggle}
      mobileOpen={mobileOpen}
      laptopOpen={laptopOpen}
    >
      {links.map(renderLinks)}
      <Box sx={{ py: 1 }}>
        <Divider />
      </Box>
      {getLinks(linksConfig).unstable.map(renderLinks)}
    </LeftDrawerBase>
  );
}
