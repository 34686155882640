import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shapeCells: {},
  emailTemplatePopupConfig: {},
};

export const bpaSlice = createSlice({
  name: 'bpaState',
  initialState,
  reducers: {
    setShapeCells: (state, action) => {
      const { id, value } = action.payload;
      // Update the value if id exists or append a new id with its value
      state.shapeCells[id] = value;
    },
    setEmailTemplatePopupConfig: (state, action) => {
      const { id, value } = action.payload;
      // Update the value if id exists or append a new id with its value
      state.emailTemplatePopupConfig[id] = value;
    },
  },
});

export const { setShapeCells, setEmailTemplatePopupConfig } = bpaSlice.actions;

export const selectBPaState = (state) => state.bpaState;

export default bpaSlice.reducer;
