import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import {
  TextField,
  AutocompleteFieldV2,
  DateTimePickerField,
} from '@components/Inputs';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { hrUrls } from '@config/routes';
import * as Yup from 'yup';
import { createRecord } from '@config/functions/requests';
import useToast from '@hooks/useToast';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';

const { daysOffUrls, dayOffTypesUrls } = hrUrls;

export default function DaysOffPopup({ open, setOpen, setDrawerEl }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const handleClose = () => {
    setOpen(false);
    setDrawerEl(null);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => null}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle sx={{ pointerEvents: 'none' }}>
          Request Days Off
        </DialogTitle>
      </Box>

      <DialogContent>
        <Formik
          initialValues={{
            notes: '',
            days_requested: 0.5,
            type: '',
            days_off_from: null,
            employee: user?.details?.details?.employee?.id ?? '',
            approved_by:
              user?.details?.details?.employee?.department_heads ?? null,
          }}
          validationSchema={Yup.object({
            notes: Yup.string(),
            days_requested: Yup.number()
              .positive('Must be > 0')
              .required('This field is required.')
              .test(
                'is-whole-or-half',
                'Value must be a whole number or a half increment (e.g., 0.5, 1.5).',
                (value) => value % 1 === 0 || value % 1 === 0.5
              ),
            type: Yup.mixed(),
            employee: Yup.mixed(),
            days_off_from: Yup.date()
              .typeError('Please select a date.')
              .required('This field is required.'),
            approved_by: Yup.mixed(),
          })}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setError, setFieldError }
          ) => {
            try {
              setSubmitting(true);

              const { type, ...rest } = values;

              if (!type?.id) {
                setFieldError('type', 'This field is required.');
                return;
              }

              const employee = user?.details?.details?.employee;
              const supervisor = employee?.department_heads;

              if (!employee?.id || !supervisor?.id) {
                notify('Employee Department not setup correctly.', {
                  type: 'ERROR',
                });
                return;
              }

              await createRecord({
                values: {
                  ...rest,
                  type: type?.id,
                  employee: employee?.id,
                  approved_by: supervisor?.id,
                },
                url: daysOffUrls.list(),
                token: user.token,
                actAs: user?.actAs,
              });

              resetForm();
              setOpen(false);
              setDrawerEl(null);
              notify('Operation completed', {
                type: 'SUCCESS',
              });
            } catch (err) {
              const apiError = err?.response?.data;
              console.log('apiError', apiError ?? err.message);
              if (apiError?.days_off_from) {
                setFieldError('days_requested', apiError.days_off_from);
                return;
              }
              handleCreateFormErrors({ err, setError, notify, values });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form noValidate autoComplete="off">
              <Grid sx={{ paddingBottom: '10px' }} container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ width: '50%' }}>
                    <AutocompleteFieldV2
                      name="type"
                      label="Type"
                      requestKey="day-off-type"
                      fetchUrl={dayOffTypesUrls.list}
                      urlParams={`&ordering=created_at`}
                      renderRow={(row) => ({
                        id: row?.id,
                        label: `${row?.name}`,
                      })}
                      required
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <DateTimePickerField
                    showTime
                    label="First Day of Leave"
                    name="days_off_from"
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Number of Days"
                    type="number"
                    name="days_requested"
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField label="Notes" name="notes" multiline rows={3} />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
