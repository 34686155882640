import React from 'react';
import { Box } from '@mui/material';
import ListView from '@components/ListView';
import { systemUrls } from '@config/routes';
import { relationShipsRowsMap } from '@config/handleRows/system';
import { RelationShipsForm } from '@config/forms/system';
import { microservices, pullstreamClientId } from '@config/constants';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { createRecord } from '@config/functions/requests';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import useToast from '@hooks/useToast';

const { relationShipsUrls } = systemUrls;

export default function RelationShips({ labels, editMode }) {
  const [notify] = useToast();
  const user = useSelector(selectProfile);

  const pullstreamCompanyAdministrator =
    user?.details?.roles_names.includes('Company Administrator') &&
    user?.details?.client === pullstreamClientId &&
    (!user?.actAs?.id || user?.actAs?.id === pullstreamClientId);

  const relationshipColOptions = React.useMemo(
    () => ({
      pullstreamCompanyAdministrator,
    }),
    [pullstreamCompanyAdministrator]
  );

  return (
    <Box>
      <ListView
        label='RelationShips'
        prefix='yt4'
        labels={labels}
        editMode={editMode}
        columnKey='relationshipSystem'
        columnOptions={relationshipColOptions}
        microservice={microservices.System.name}
        model={microservices.System.models.relationShip}
        addNewForm={
          pullstreamCompanyAdministrator
            ? {
                getFields: () => RelationShipsForm({}),
                handleSubmit: async (
                  values,
                  setSubmitting,
                  resetForm,
                  setAddNewForm,
                  refetch,
                  setError
                ) => {
                  try {
                    const { ...rest } = values;

                    const response = await createRecord({
                      values: {
                        ...rest,
                      },
                      url: relationShipsUrls.list(),
                      token: user.token,
                      actAs: user?.actAs,
                    });

                    resetForm();
                    setAddNewForm(false);
                    refetch();
                    return response?.data?.id;
                  } catch (err) {
                    handleCreateFormErrors({ err, setError, notify, values });
                  } finally {
                    setSubmitting(false);
                  }
                },
              }
            : null
        }
        urls={relationShipsUrls}
        handleRowsMap={relationShipsRowsMap}
        customSortColumnName='created_at'
        showBulkDeleteButton={pullstreamCompanyAdministrator}
        hideActions={!pullstreamCompanyAdministrator}
      />
    </Box>
  );
}
