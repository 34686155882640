import { computeHost } from '@config/msHosts';
import { getModelUrls } from '@config/functions/routesFunctions';

const models = {
  microservices: 'microservices',
  models: 'models',
  modelFields: 'model-fields',
  import: 'import',
  export: 'export',
  blockGroups: 'block-groups',
  blocks: 'blocks',
  instance: 'instances',
  generateMicroservice: 'compute-microservice',
  enums: 'enum-defns',
  enumValues: 'enum-values',
  instanceLogs: 'instance-logs',
  generateMicroserviceErd: 'generate-microservice-erd',
};

const generateMicroserviceErdUrls = getModelUrls(
  computeHost,
  models.generateMicroserviceErd,
  false,
  'v1'
);

const instanceLogsUrls = getModelUrls(
  computeHost,
  models.instanceLogs,
  false,
  'v1'
);

const enumsUrls = getModelUrls(computeHost, models.enums, false, 'v1');

const enumValuesUrls = getModelUrls(
  computeHost,
  models.enumValues,
  false,
  'v1'
);

const instanceUrls = getModelUrls(computeHost, models.instance, false, 'v1');

const generateMicroserviceUrls = getModelUrls(
  computeHost,
  models.generateMicroservice,
  false,
  'v1'
);

const microservicesUrls = getModelUrls(
  computeHost,
  models.microservices,
  false,
  'v1'
);
const modelsUrls = getModelUrls(computeHost, models.models, false, 'v1');
const modelFieldsUrls = getModelUrls(
  computeHost,
  models.modelFields,
  false,
  'v1'
);
const importUrls = getModelUrls(computeHost, models.import, false, 'v1');
const exportUrls = getModelUrls(computeHost, models.export, false, 'v1');
const blockGroupsUrls = getModelUrls(
  computeHost,
  models.blockGroups,
  false,
  'v1'
);
const blocksUrls = getModelUrls(computeHost, models.blocks, false, 'v1');

const urls = {
  microservicesUrls,
  modelsUrls,
  modelFieldsUrls,
  importUrls,
  exportUrls,
  blockGroupsUrls,
  blocksUrls,
  generateMicroserviceUrls,
  instanceUrls,
  enumsUrls,
  enumValuesUrls,
  instanceLogsUrls,
  generateMicroserviceErdUrls,
};

export default urls;
