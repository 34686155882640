import React from 'react';
import { Box } from '@mui/material';
import ShapeActionTypeCard from './ShapeActionTypeCard';

export default function ShapeActionTypeList({
  shape,
  shapeActions,
  actionsList,
  workflowId,
  workflowName,
}) {
  return (
    <Box>
      {Object.keys(shapeActions).map((key) => (
        <ShapeActionTypeCard
          key={key}
          type={key}
          shape={shape}
          workflowId={workflowId}
          actionsList={actionsList}
          initialShapeActions={shapeActions[key]}
          workflowName={workflowName}
        />
      ))}
    </Box>
  );
}
