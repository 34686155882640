import * as React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { accountUrls, systemUrls } from '@config/routes';
import { getAuthHeader } from '@config/functions/helperFunctions';
import { setActAs } from '@redux/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import { selectOperations, setEditMode } from '@redux/operationsSlice';
import { getUserStorageKey, encryptionPassKey } from '@config/constants';
import CachedIcon from '@mui/icons-material/Cached';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DaysOffPopup from '@components/DaysOffPopup';
import AutocompleteFieldV2 from '@ui/Inputs/AutocompleteFieldV2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const { tokenLogoutUrls } = accountUrls;
const { clientRelationsUrls } = systemUrls;
const ITEM_HEIGHT = 48;

export default function MenuAppBar({
  text = 'Pullstream Admin',
  onDrawerToggle,
  username,
  language,
  setLanguage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(selectProfile);
  const operations = useSelector(selectOperations);
  const [drawerEl, setDrawerEl] = React.useState(null);
  const open = Boolean(drawerEl);
  const [daysOff, setDaysOff] = React.useState(false);
  const theme = useTheme();
  const notOnPhone = useMediaQuery(theme.breakpoints.up('sm'));

  const handleMenu = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleDrawer = ({ currentTarget }) => setDrawerEl(currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleProfile = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    navigate('/profile');
  };

  const handleLogout = async (e) => {
    try {
      e.preventDefault();
      await axios.get(
        tokenLogoutUrls.list(),
        getAuthHeader(user.token, user?.actAs)
      );
    } catch (err) {
      console.log('logout error', err.response || err.message);
    } finally {
      localStorage.removeItem(encryptionPassKey);
      localStorage.removeItem(getUserStorageKey());
      setAnchorEl(null);
      console.log('obi-logout-redirect');
      navigate('/auth/login');
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: user?.colors?.primary || '#2336f1',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          sx={{ mr: 2 }}
        >
          <MenuIcon key="MenuIcon" />
        </IconButton>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link
            to="/"
            style={{
              display: 'block',
              marginRight: '12px',
              color: 'white',
              textDecoration: 'none',
              fontSize: '1.25rem',
              fontWeight: '500',
            }}
          >
            {text}
          </Link>
          <Box>
            <IconButton
              color="inherit"
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              size="small"
              onClick={handleDrawer}
            >
              <OpenInNewIcon fontSize="inherit" />
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={drawerEl}
              open={open}
              onClose={() => setDrawerEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                  boxShadow: 'none',
                  border: '1px solid #ddd',
                },
              }}
            >
              <MenuItem size="small">
                <Link style={{ textDecoration: 'none' }} to="/bugs?create=true">
                  <Typography sx={{ color: '#121212' }}>Report Bug</Typography>
                </Link>
              </MenuItem>
              <MenuItem size="small" onClick={() => setDaysOff(true)}>
                Request Days Off
              </MenuItem>
            </Menu>
            <DaysOffPopup
              open={daysOff}
              setOpen={setDaysOff}
              setDrawerEl={setDrawerEl}
            />
          </Box>
        </Box>

        <div
          style={{
            marginLeft: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {operations.action === 'failed' && notOnPhone ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              <WarningAmberIcon fontSize="small" sx={{ color: '#e2e2e2' }} />
              <Typography sx={{ ml: 1, color: '#e2e2e2' }}>
                Failed to save
              </Typography>
            </Box>
          ) : null}

          {operations.action === 'busy' && notOnPhone ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              <CachedIcon fontSize="small" sx={{ color: '#e2e2e2' }} />
              <Typography sx={{ ml: 1, color: '#e2e2e2' }}>
                Saving...
              </Typography>
            </Box>
          ) : null}

          {(operations.action === 'idle' || operations.action === 'done') &&
          notOnPhone ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              <CloudDoneOutlinedIcon
                fontSize="small"
                sx={{ color: '#e2e2e2' }}
              />
              {operations.action === 'done' ? (
                <Typography sx={{ ml: 1, color: '#e2e2e2' }}>Saved</Typography>
              ) : null}
            </Box>
          ) : null}

          {(user?.details?.roles_names.includes('Superusers') ||
            user?.details?.is_superuser) &&
          notOnPhone ? (
            <FormGroup sx={{ minWidth: '125px' }}>
              <FormControlLabel
                control={
                  <Switch
                    color="info"
                    checked={operations.editMode}
                    onChange={() => {
                      dispatch(setEditMode(!operations.editMode));
                    }}
                  />
                }
                label="Edit Mode"
              />
            </FormGroup>
          ) : null}

          {notOnPhone ? (
            <FormControl sx={{ minWidth: '124px' }} size="small">
              <Select
                sx={{ background: '#fff' }}
                value={language || 'English'}
                labelId="language-label"
                onChange={(e) => setLanguage(e.target.value)}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="German">German</MenuItem>
              </Select>
            </FormControl>
          ) : null}

          {notOnPhone ? (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 12px',
              }}
            >
              <label style={{ width: '140px', paddingRight: '12px' }}>
                Acting As:
              </label>
              <AutocompleteFieldV2
                name="Act"
                label="Act As"
                requestKey="act-as-clients"
                fetchUrl={clientRelationsUrls.list}
                urlParams={`&parent_client=${user?.details?.originalClient}`}
                renderRow={(row) => ({
                  id: row?.child_client,
                  label: row?.details?.child_client?.name,
                  client: row?.details?.child_client,
                })}
                customOnChange={(v) => {
                  dispatch(setActAs({ id: v?.id, ...v?.client } ?? {}));
                }}
              />
            </Box>
          ) : null}

          <IconButton
            key="IconButton"
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle key="AccountCircle" />
            <Typography
              component="div"
              key="body1"
              variant="body1"
              sx={{ ml: 1 }}
              noWrap
            >
              {username}
            </Typography>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem key="Profile" onClick={handleProfile}>
              Profile
            </MenuItem>
            <MenuItem key="Logout" onClick={handleLogout}>
              Log Out
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
