import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@config/theme';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';
import { selectProfile } from '@redux/profileSlice';
import WithLayout from '@components/core/WithLayout';
import NotFound from '@components/core/NotFound';
import Dashboard from '@pages/dashboard';
import authRoutes from '@pages/Auth/routes';
import bpaRoutes from '@pages/BPA-V2/routes';
import pmRoutes from '@pages/PM/routes';
import crmRoutes from '@pages/CRM/routes';
import hrRoutes from '@pages/HR/routes';
import inventoryRoutes from '@pages/Inventory/routes';
import ecommerceRoutes from '@pages/E-Commerce/routes';
import communicationRoutes from '@pages/Communication/routes';
import driveRoutes from '@pages/Drive/routes';
import formRoutes from '@pages/Forms/routes';
import eDocRoutes from '@pages/eDoc/routes';
import lmsRoutes from '@pages/LMS/routes';
import cmsRoutes from '@pages/CMS/routes';
import adminRoutes from '@pages/Admin/routes';
import systemRoutes from '@pages/System/routes';
import computeRoutes from '@pages/Compute/routes';
import marketingRoutes from '@pages/Marketing/routes';
import accountRoutes from '@pages/Account/routes';
import wikiRoutes from '@pages/Wiki/routes';
import webChatRoutes from '@pages/WebChat/routes';
import sopsRoutes from '@pages/SOPs/routes';
import listsRoutes from '@pages/Lists/routes';
import calendarRoutes from '@pages/Calendar/routes';
import kanBanRoutes from '@pages/KanBan/routes';
import LogRoutes from '@pages/Logs/routes';
import PrivacyPolicy from '@pages/privacy-policy';
import TermsAndConditions from '@pages/terms-and-conditions';
import cmsV2Routes from '@pages/CMS-V2/routes';
import chatRoutes from '@pages/Chat/routes';
import useRemoveElementByText from '@hooks/useRemoveElementByText';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

export default function App() {
  const user = useSelector(selectProfile);

  useRemoveElementByText('MUI X: Missing license key');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='/' element={<WithLayout comp={Dashboard} />} />
          <Route
            path='/terms-and-conditions'
            element={<TermsAndConditions />}
          />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          {bpaRoutes()}
          {computeRoutes()}
          {authRoutes()}
          {pmRoutes()}
          {crmRoutes()}
          {hrRoutes()}
          {inventoryRoutes()}
          {ecommerceRoutes()}
          {communicationRoutes()}
          {driveRoutes()}
          {formRoutes()}
          {eDocRoutes()}
          {lmsRoutes()}
          {cmsRoutes()}
          {adminRoutes()}
          {systemRoutes()}
          {marketingRoutes()}
          {accountRoutes()}
          {wikiRoutes()}
          {webChatRoutes()}
          {sopsRoutes()}
          {listsRoutes()}
          {calendarRoutes()}
          {kanBanRoutes()}
          {LogRoutes()}
          {cmsV2Routes()}
          {chatRoutes()}
          <Route
            path='*'
            element={user.token ? <WithLayout comp={NotFound} /> : <NotFound />}
          />
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
