import React from 'react';
import { Route } from 'react-router-dom';
import WithLayout from '@components/core/WithLayout';
import CompanyChat from '@pages/Chat/CompanyChat';

export default function () {
  return (
    <>
      <Route path='/company-chat' element={<WithLayout comp={CompanyChat} />} />
    </>
  );
}
