import { Grid, MenuItem } from '@mui/material';
import {
  TextField,
  SelectField,
  CheckBoxField,
  AutocompleteFieldV2,
  DateTimePickerField,
} from '@components/Inputs';
import {
  formQuestionTypeOptions,
  formBranchingConditionTypes,
  formBranchingConcatenators,
  formDisplayMode,
  formCategoryOptions,
  formAccessLevels,
} from '@config/constants';
import { useQueryClient } from 'react-query';
import {
  formsUrls,
  systemUrls,
  communicationUrls,
  crmUrls,
} from '@config/routes';
import QuestionOptionGroupDropdown from '@dropdown-forms/form/QuestionOptionGroupDropdown';
import GroupDropdown from '@dropdown-forms/form/GroupDropdown';
import QuestionDropdown from '@dropdown-forms/form/QuestionDropdown';
import QuestionOptionDropdown from '@dropdown-forms/form/QuestionOptionDropdown';
import PersonDropdown from '@dropdown-forms/crm/PersonDropdown';

const {
  questionsUrls,
  questionOptionsUrls,
  questionOptionGroupsUrls,
  groupsUrls,
  questionGroupsUrls,
} = formsUrls;
const { templatesUrls } = systemUrls;
const { mailTemplatesUrls } = communicationUrls;
const { personsUrls } = crmUrls;

export const FormsForm = ({ microservice, model }) => (
  <>
    <Grid item xs={4}>
      <TextField label='Name' name='name' multiline required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Title' name='title' multiline required />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item sm={4}>
      <AutocompleteFieldV2
        name='template'
        label='Template To Copy From'
        requestKey='forms-templates'
        fetchUrl={templatesUrls.list}
        urlParams={`&ordering=created_at&microservice_name=${microservice}&model_name=${model}`}
        renderRow={(row) => ({
          id: row?.id,
          label: row?.name,
        })}
      />
    </Grid>

    <Grid item xs={4}>
      <TextField label='Closing message' name='closingMessage' multiline />
    </Grid>

    <Grid item md={4}>
      <SelectField label='Display mode' name='displayMode'>
        {formDisplayMode.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>

    <Grid item xs={2}>
      <SelectField label='Access level' name='accessLevel'>
        {formAccessLevels.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>

    <Grid item md={4}>
      <SelectField label='Category' name='category'>
        {formCategoryOptions.map((r) => (
          <MenuItem value={r.value} key={r.value}>
            {r.label}
          </MenuItem>
        ))}
      </SelectField>
    </Grid>

    <Grid item sm={1.5}>
      <CheckBoxField label='Publish' name='isPublished' />
    </Grid>

    <Grid item xs={4.5}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
    {/* <Grid item sm={3}>
      <CheckBoxField label='Allow Multiple Submissions' name='allowMultipleSubmissions' />
    </Grid>
    <Grid item sm={3.5}>
      <CheckBoxField label='Display All Questions at Once' name='displayAllQuestions' />
    </Grid> */}
  </>
);

export const QuestionsForm = ({ setFieldValue, values, formId }) => {
  return (
    <>
      <Grid item sm={1}>
        <TextField label='Order' name='order' />
      </Grid>

      <Grid item md={1.5}>
        <SelectField label='Type' name='type'>
          {formQuestionTypeOptions.map((r) => (
            <MenuItem value={r.value} key={r.value}>
              {r.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>

      <Grid item xs={4}>
        <TextField
          label='Question'
          name='question'
          minRows={2}
          multiline
          required
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          label='Description'
          name='description'
          multiline
          minRows={2}
        />
      </Grid>

      <Grid item sm={2}>
        <CheckBoxField label='Required?' name='isRequired' />
      </Grid>

      <Grid item sm={3}>
        <AutocompleteFieldV2
          name='group'
          label='Group'
          requestKey='question-group'
          fetchUrl={groupsUrls.list}
          orderingCol='order'
          urlParams={formId ? `&formId=${formId}` : ''}
          renderRow={(row) => ({
            id: row?.id,
            label: `${row.order}. ${row.name}`,
          })}
          required
          getData={(data) => {
            if (data.length) {
              console.log('data', data[0]);
              setFieldValue('group', data[0]);
            }
          }}
        />
      </Grid>

      {values.type === 'MultipleChoice' ? (
        <Grid item sm={2}>
          <CheckBoxField label='Add other field' name='includeOthersField' />
        </Grid>
      ) : null}

      <Grid item xs={3}>
        <TextField label='Tags' name='tags' multiline />
      </Grid>

      {values.type === 'Likert' ? (
        <>
          <Grid item xs={2.4}>
            <TextField
              label='Likert label 1'
              name='likertLabel1'
              multiline
              required
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              label='Likert label 2'
              name='likertLabel2'
              multiline
              required
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              label='Likert label 3'
              name='likertLabel3'
              multiline
              required
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              label='Likert label 4'
              name='likertLabel4'
              multiline
              required
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              label='Likert label 5'
              name='likertLabel5'
              multiline
              required
            />
          </Grid>
        </>
      ) : null}
    </>
  );
};

export const QuestionOptionGroupsForm = () => (
  <>
    <Grid item xs={2} sm={4} md={2}>
      <TextField label='Name' name='name' required multiline />
    </Grid>

    <Grid item xs={2} sm={6}>
      <TextField label='Description' name='description' multiline />
    </Grid>

    <Grid item xs={3}>
      <TextField label='Tags' name='tags' multiline />
    </Grid>
  </>
);

export const QuestionOptionsForm = ({
  questionId,
  setFieldValue,
  questionName,
  type,
}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item sm={1}>
        <TextField label='Order' name='order' />
      </Grid>

      <Grid item xs={5}>
        <TextField label='Option' name='option' multiline required />
      </Grid>

      {type === 'Likert' ? (
        <Grid item xs={4}>
          <QuestionOptionGroupDropdown
            question={{ id: questionId, label: questionName }}
            refetch={({ id, name }) => {
              setFieldValue('questionOptionGroupId', { id, label: name });
              queryClient.invalidateQueries(['question-option-group']);
            }}
          >
            <AutocompleteFieldV2
              name='questionOptionGroupId'
              label='Option Group'
              requestKey='question-option-groups'
              fetchUrl={questionOptionGroupsUrls.list}
              urlParams={questionId ? `&questionId=${questionId}` : ''}
            />
          </QuestionOptionGroupDropdown>
        </Grid>
      ) : null}

      <Grid item sm={2}>
        <CheckBoxField label='Is Correct?' name='isCorrect' />
      </Grid>
    </>
  );
};

export const GroupForm = ({
  hideParentField,
  formId,
  formName,
  setFieldValue,
}) => {
  const queryClient = useQueryClient();
  return (
    <>
      <Grid item xs={1}>
        <TextField label='Order' name='order' required />
      </Grid>

      <Grid item xs={2}>
        <TextField label='Name' name='name' multiline required />
      </Grid>

      {hideParentField ? null : (
        <Grid item xs={3}>
          <GroupDropdown
            title='Parent Group'
            form={{ id: formId, label: formName }}
            refetch={({ id, name }) => {
              setFieldValue('parentGroup', { id, label: name });
              queryClient.invalidateQueries(['parentGroup-form']);
            }}
          >
            <AutocompleteFieldV2
              name='parentGroup'
              label='Parent Group'
              requestKey='question-parent-groups'
              fetchUrl={groupsUrls.list}
              orderingCol='order'
              urlParams={formId ? `&formId=${formId}` : ''}
              renderRow={(row) => ({
                id: row?.id,
                label: `${row.order}. ${row.name}`,
              })}
              getData={(data) => {
                if (data.length) {
                  console.log('data', data[0]);
                  setFieldValue('parentGroup', data[0]);
                }
              }}
            />
          </GroupDropdown>
        </Grid>
      )}

      <Grid item xs={6}>
        <TextField
          label='Description'
          name='description'
          multiline
          minRows={2}
        />
      </Grid>
    </>
  );
};

export const BranchingConditionForm = ({
  formId,
  values,
  formName,
  setFieldValue,
}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item sm={1}>
        <TextField label='Order' name='order' />
      </Grid>

      <Grid item xs={3}>
        <GroupDropdown
          title='Current Group'
          form={{ id: formId, label: formName }}
          refetch={({ id, name }) => {
            setFieldValue('currentGroup', { id, label: name });
            queryClient.invalidateQueries(['currentGroup-form']);
          }}
        >
          <AutocompleteFieldV2
            name='currentGroup'
            label='Current Group'
            requestKey='currentGroup-groups'
            fetchUrl={groupsUrls.list}
            urlParams={formId ? `&formId=${formId}` : ''}
            orderingCol='order'
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.order}. ${row?.name}`,
            })}
            required
          />
        </GroupDropdown>
      </Grid>

      <Grid item xs={3}>
        <GroupDropdown
          title='Source Group'
          form={{ id: formId, label: formName }}
          refetch={({ id, name }) => {
            setFieldValue('sourceGroup', { id, label: name });
            queryClient.invalidateQueries(['sourceGroup-form']);
          }}
        >
          <AutocompleteFieldV2
            name='sourceGroup'
            label='Source Group'
            requestKey='sourceGroup-groups'
            fetchUrl={groupsUrls.list}
            urlParams={formId ? `&formId=${formId}` : ''}
            orderingCol='order'
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.order}. ${row?.name}`,
            })}
            required
          />
        </GroupDropdown>
      </Grid>

      {values?.sourceGroup?.id ? (
        <Grid item xs={4}>
          <QuestionDropdown
            form={{ id: formId, label: formName }}
            group={values?.sourceGroup}
            refetch={({ id, question }) => {
              setFieldValue('question', { id, label: question });
              queryClient.invalidateQueries(['branch-questions-form']);
            }}
          >
            <AutocompleteFieldV2
              name='question'
              label='Question'
              requestKey='form-branch-questions'
              fetchUrl={questionGroupsUrls.list}
              urlParams={`&groupId=${values.sourceGroup.id}&formId=${formId}`}
              orderingCol='order'
              renderRow={(row) => ({
                id: row?.questionId,
                label: `${row?.question?.type} - ${row?.question?.question}`,
                type: row?.question?.type,
              })}
              required
            />
          </QuestionDropdown>
        </Grid>
      ) : null}

      {values?.question?.id && values?.question?.type === 'Likert' ? (
        <Grid item xs={3}>
          <QuestionOptionGroupDropdown
            question={values?.question}
            refetch={({ id, name }) => {
              setFieldValue('questionOptionGroup', { id, label: name });
              queryClient.invalidateQueries(['question-option-group']);
            }}
          >
            <AutocompleteFieldV2
              name='questionOptionGroup'
              label='Option Group'
              requestKey='question-option-groups'
              fetchUrl={questionOptionGroupsUrls.list}
              urlParams={`&questionId=${values?.question?.id}`}
            />
          </QuestionOptionGroupDropdown>
        </Grid>
      ) : null}

      {values?.question?.id ? (
        <Grid item xs={1.5}>
          <SelectField label='Operator' name='type' required>
            {formBranchingConditionTypes.map((r) => (
              <MenuItem value={r.value} key={r.value}>
                {r.label}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      ) : null}

      {values?.question?.id &&
      (values?.question?.type === 'Likert' ||
        values?.question?.type === 'Rating') ? (
        <Grid item xs={3}>
          <TextField label='Value' name='staticValue' required />
        </Grid>
      ) : null}

      {values?.question?.id && values?.question?.type === 'Date' ? (
        <Grid item xs={3}>
          <DateTimePickerField label='Value' name='staticValue' required />
        </Grid>
      ) : null}

      {values?.question?.id &&
      (values?.question?.type === 'MultipleChoice' ||
        values?.question?.type === 'MultipleSelect') ? (
        <>
          <Grid item xs={3}>
            <QuestionOptionDropdown
              question={values?.question}
              sourceGroup={values?.sourceGroup}
              form={{ id: formId, label: formName }}
              refetch={({ id, option }) => {
                setFieldValue('questionOption', { id, label: option });
                queryClient.invalidateQueries(['question-option']);
              }}
            >
              <AutocompleteFieldV2
                name='questionOption'
                label='Question Option'
                requestKey='form-branch-question-options'
                fetchUrl={questionOptionsUrls.list}
                orderingCol='order'
                urlParams={`&questionId=${values.question.id}`}
                renderRow={(row) => ({
                  id: row?.id,
                  label: `${row?.order}. ${row?.option}`,
                })}
                required
              />
            </QuestionOptionDropdown>
          </Grid>
        </>
      ) : null}

      <Grid item xs={3}>
        <GroupDropdown
          title='Target Group'
          form={{ id: formId, label: formName }}
          refetch={({ id, name }) => {
            setFieldValue('sourceGroup', { id, label: name });
            queryClient.invalidateQueries(['targetGroup-form']);
          }}
        >
          <AutocompleteFieldV2
            name='targetGroup'
            label='Target Group'
            requestKey='targetGroup-groups'
            fetchUrl={groupsUrls.list}
            urlParams={formId ? `&formId=${formId}` : ''}
            orderingCol='order'
            renderRow={(row) => ({
              id: row?.id,
              label: `${row?.order}. ${row?.name}`,
            })}
            required
          />
        </GroupDropdown>
      </Grid>

      <Grid item xs={1.5}>
        <SelectField label='Concatenator' name='concatenator' required>
          {formBranchingConcatenators.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
    </>
  );
};

export const QuestionOptionGroupScoreForm = () => {
  return (
    <>
      <Grid item sm={3}>
        <TextField label='Min score %' name='minScore' />
      </Grid>

      <Grid item sm={3}>
        <TextField label='Max score %' name='maxScore' />
      </Grid>
    </>
  );
};

export const QuestionGroupForm = ({
  setFieldValue,
  formId,
  hideGroup,
  formName,
}) => {
  const queryClient = useQueryClient();

  return (
    <>
      {hideGroup ? (
        <Grid item xs={8}>
          <QuestionDropdown
            form={{ id: formId, label: formName }}
            refetch={({ id, question, type }) => {
              setFieldValue('questionId', {
                id,
                label: `${type} - ${question}`,
              });
              queryClient.invalidateQueries(['group-questions-form']);
            }}
          >
            <AutocompleteFieldV2
              name='questionId'
              label='Question'
              requestKey='form-group-questions'
              fetchUrl={questionsUrls.list}
              orderingCol='order'
              urlParams={formId ? `&formId=${formId}` : ''}
              renderRow={(row) => ({
                id: row?.id,
                label: `${row?.type} - ${row?.question}`,
                type: row?.type,
              })}
              required
            />
          </QuestionDropdown>
        </Grid>
      ) : (
        <Grid item xs={8}>
          <GroupDropdown
            form={{ id: formId, label: formName }}
            refetch={({ id, name }) => {
              setFieldValue('groupId', { id, label: name });
              queryClient.invalidateQueries(['groupId-form']);
            }}
          >
            <AutocompleteFieldV2
              name='groupId'
              label='Group'
              requestKey='question-groups'
              fetchUrl={groupsUrls.list}
              orderingCol='order'
              urlParams={formId ? `&formId=${formId}` : ''}
              renderRow={(row) => ({
                id: row?.id,
                label: `${row.order}. ${row.name}`,
              })}
              required
            />
          </GroupDropdown>
        </Grid>
      )}
    </>
  );
};

export const RecipientForm = ({ setFieldValue, values } = {}) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Grid item xs={3}>
        <PersonDropdown
          refetch={({ id, first_name, last_name, email }) => {
            if (id && first_name && last_name && email) {
              setFieldValue('personId', {
                id: id,
                label: email,
                email: email,
                firstName: first_name,
                lastName: last_name,
              });
            }
            queryClient.invalidateQueries(['recipients-persons']);
          }}
        >
          <AutocompleteFieldV2
            name='personId'
            label='Person'
            requestKey='recipients-persons'
            fetchUrl={personsUrls.list}
            renderRow={(row) => ({
              id: row?.id,
              label: row?.email,
              email: row?.email,
              first_name: row?.first_name,
              last_name: row?.last_name,
            })}
            onChange={(value) => {
              setFieldValue('firstName', value?.first_name ?? '');
              setFieldValue('lastName', value?.last_name ?? '');
              setFieldValue('email', value?.email ?? '');
            }}
            required
          />
        </PersonDropdown>
      </Grid>
      <Grid item xs={1.5}>
        <CheckBoxField label='Send email' name='sendEmail' />
      </Grid>
      {values.sendEmail ? (
        <Grid item xs={4}>
          <AutocompleteFieldV2
            name='emailTemplateId'
            label='Email Template'
            requestKey='form-recipient-email-templates'
            fetchUrl={mailTemplatesUrls.list}
            required
          />
        </Grid>
      ) : null}

      <Grid item xs={2}>
        <CheckBoxField label='Add expiry date' name='addExpiryDate' />
      </Grid>
      {values.addExpiryDate ? (
        <Grid item sm={1.5}>
          <DateTimePickerField
            label='Expiry date'
            showTime
            disablePast
            name='expiryDate'
            showTimezone
          />
        </Grid>
      ) : null}

      <Grid item xs={4}>
        <TextField label='Tags' name='tags' multiline />
      </Grid>

      {/* <Grid item sx={{ display: 'flex', alignItems: 'center' }} sm={2.5}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setShowFieldMapping({ open: true, data: documentFieldDefns });
          }}
          startIcon={<CreateOutlinedIcon fontSize="small" />}
        >
          Map fields
        </Button>

        <MapFieldsPopup
          open={!!showFieldMapping?.open}
          documentFields={showFieldMapping?.data}
          handleSave={(values) => {
            console.log('values', values);
            setFieldValue('documentFieldDefnValues', values);
          }}
          setOpen={(open) => setShowFieldMapping({ open, data: {} })}
        />
      </Grid> */}
    </>
  );
};
